import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams, HttpErrorResponse
} from '@angular/common/http';

import { IServiceModel, IServiceListData } from './service.interface';
import { AppInjector } from '../app-injector.service';
import { AuthToken } from '../auth/auth-token';
import { RedirectService } from '../core/redirect.service';


@Injectable()
export class BaseCrudService<T extends IServiceModel> {

  protected apiHost = environment.apiHost;
  protected endpointUrl: string;

  protected http: HttpClient;
  protected redirectService: RedirectService;

  protected httpConfig = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  constructor(protected http2?: HttpClient) {
    const injector = AppInjector.getInjector();
    this.http = injector.get(HttpClient);
    this.redirectService = injector.get(RedirectService);
  }

  async list(apiUrl = this.endpointUrl): Promise<T[]> {
    try {
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      const results = await this.http.get(apiUrl, httpConfig).toPromise();
      return results as T[];
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  listRaw(apiUrl = this.endpointUrl): Promise<any> {
    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig)
      .toPromise()
      .catch(this.handleError);
  }

  async listMeta(params = {}, apiUrl = this.endpointUrl): Promise<IServiceListData<T>> {
    try {
      if (params['page'] === undefined) {
        params['page'] = 1;
      }

      // Add search parameter for filtering the list.
      let searchParams = new HttpParams();
      for (let p in params) {
        searchParams = searchParams.set(p, params[p].toString());
      }
      this.httpConfig['params'] = searchParams;
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);

      const response = await this.http.get(apiUrl, httpConfig).toPromise();
      const { results, previous, next, count } = response as any;
      return { results, previous, next, count: parseInt(count, 10) };
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  autocomplete(searchTerm: string): Promise<IServiceListData<T>> {
    const apiUrl = `${this.endpointUrl}/autocomplete/`;
    const params = {'search': searchTerm};
    return this.listMeta(params, apiUrl);
  }

  async create(record: T): Promise<T> {
    try {
      const apiUrl = `${this.endpointUrl}/create/`;
      const data = JSON.stringify(record);

      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      const response = await this.http.post(apiUrl, data, httpConfig).toPromise();
      return response as T;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  // @TODO: Look into T[] in vs T return type...
  async createBulk(records: T[]): Promise<T> {
    try {
      const apiUrl = `${this.endpointUrl}/create/`;
      const data = JSON.stringify(records);

      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      const response = await this.http.post(apiUrl, data, httpConfig).toPromise();
      return response as T;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  async read(id: string, apiUrl = ''): Promise<T> {
    try {
      if (!apiUrl) {
        apiUrl = `${this.endpointUrl}/${id}/`;
      }
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      const response = await this.http.get(apiUrl, httpConfig).toPromise();
      return response as T;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  async update(record: T, apiUrl = ''): Promise<T> {
    try {
      if (!apiUrl) {
        apiUrl = `${this.endpointUrl}/${record.id}/`;
      }
      const data = JSON.stringify(record);

      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      const response = await this.http.put(apiUrl, data, httpConfig).toPromise();
      return response as T;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  async delete(id: string): Promise<Object> {
    try {
      const apiUrl = `${this.endpointUrl}/${id}/`;
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      return this.http.delete(apiUrl, httpConfig).toPromise();
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  handleError(error: HttpErrorResponse) {
    // @TODO: Improve logging.
    console.error('Service error: ', error);

    // Intentionally letting components handle 404 errors, so
    // that 404 in one part of a view doesn't crash the whole thing.

    // Unauthenticated users.
    if (error.status === 401) {
      localStorage.clear();
      this.redirectService.redirectUnauthenticated();
    }

    // Re-raise to let calling components handle other errors.
    throw error;
  }
}
