import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthToken } from '../auth/auth-token';


@Injectable()
export class RegistrationService {

  apiHost = environment.apiHost;
  apiUrlRegister = `${this.apiHost}/api/auth/register/`;
  apiUrlRegisterConfirm = `${this.apiHost}/api/auth/register/confirm/`;

  protected httpConfig = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': '*/*',
    }),
  };

  constructor(protected http: HttpClient) { }

  register(email: string) {
    const apiUrl = this.apiUrlRegister;
    const payload = { email };

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.post(apiUrl, payload, httpConfig)
      .toPromise()
      .catch(this.handleError);
  }

  registerConfirm(uid: string, token: string,
               new_password1: string, new_password2: string) {
    const apiUrl = this.apiUrlRegisterConfirm;
    const payload = { uid, token, new_password1, new_password2 };

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.post(apiUrl, payload, httpConfig)
      .toPromise()
      .then(response => {
        return response['data'];
      }).catch(this.handleError);
  }

  handleError(error: any): Promise<any> {
    // @TODO: Improve logging.
    console.log('RegistrationService error: ', error);
    return Promise.reject(error);
  }
}
