import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';

import { BaseListComponent } from '../../base/base-list.component';
import { HoldingBook } from '../holding-book';
import { HoldingBookService } from '../holding-book.service';
import { CampusService } from '../../campus/campus.service';
import { UserService } from '../../user/user.service';


@Component({
  selector: 'app-holding-book-list',
  templateUrl: './holding-book-list.component.html',
  styleUrls: ['./holding-book-list.component.css']
})
export class HoldingBookListComponent extends BaseListComponent<HoldingBook> implements OnInit {
  baseUrl = 'holding/book/';
  viewTitle = 'Holding Book - AIMHub';

  totalCampusBooks: number;
  totalCampusHoldings: number;
  holdingSummary: Object;

  newPublicHolding: HoldingBook;
  newPublicCount = 0;

  filter = {
    'public': true,
    'private': true,
  }

  showAdmin = false;

  constructor(
    protected dataService: HoldingBookService,
    protected campusService: CampusService,
    protected userService: UserService,
  ) {
    super(dataService);
  }

  ngOnInit(): void {
    this.initAdminLevel();
    this.initHoldingSummary();
    this.initCampusStats();
    super.ngOnInit();
  }

  private initAdminLevel(): void {
    this.userService.getRole()
    .then(role => {
      if (role.indexOf('Administrator') >= 0) {
        this.showAdmin = true;
      }
    });
  }

  private async initHoldingSummary(): Promise<void> {
    try {
      const response = await this.dataService.getSummary();
      this.holdingSummary = response as Object;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  private initCampusStats(): void {
    this.campusService.getCampusStatistics()
      .then(response => {
        this.totalCampusBooks = response['total_campus_books'];
        this.totalCampusHoldings = response['total_campus_holdings'];
      })
      .catch(this.handleError);
  }

  public hasPrivateHoldings(): boolean {
    if (!this.holdingSummary) {
      return false;
    } else if (!this.holdingSummary['private_count']) {
      return false;
    } else {
      return true;
    }
  }

  public updateFilter(filter: string, value = null): void {
    const filterValues = Object.keys(this.filter);
    if (filterValues.indexOf(filter) >= 0) {
      if (value == null) {
        this.filter[filter] = !this.filter[filter];
      } else {
        this.filter[filter] = value;
      }
    }
    this.refreshApiParams();
    this.list();
  }

  refreshApiParams(): any {
    let enabled_filters = [];
    for (let key in this.filter) {
      if (this.filter[key]) {
        enabled_filters.push(key);
      }
    }
    this.apiQueryParams['show'] = enabled_filters.join('+');
  }

  public makePublic(record: HoldingBook): void {
    this.dataService.makePublic(record)
      .then(response => {
        record.is_public = true;
        this.newPublicHolding = record;
        this.initHoldingSummary();
      })
      .catch(this.handleError);
  }

  public onMakeAllPublicSuccess(updateCount: number): void {
    this.newPublicCount = updateCount;
    setTimeout(() => this.newPublicCount = 0, this.notifyTimeout);
    this.list();
    this.initHoldingSummary();
  }

  parseQueryParamsExtra(params: Params): void {
    let key = 'campus';
    if (params.hasOwnProperty(key)) {
      this.apiQueryParams[key] = params[key];
    }
  }
}
