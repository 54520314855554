<h2>Status of Exchange Requests</h2>

<div id="list-control" class="row">
  <a id="new-exchange-request-status" class="btn btn-primary"
    routerLink="/exchange/request/status/create"
    >
    New Status
    <span class="glpyhicon glyphicon-plus"></span>
  </a>
</div>

<div class="panel">
  <div class="row">

    <table id="table-exchange-request-status-list" class="table table-hover">
      <thead>
          <tr>
            <th>Name</th>
          </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of results">
          <td>
            <a id="link-exchange-request-status-{{ result.id }}"
                  [routerLink]="['/exchange/request/status/detail', result.id]">
              {{ result.name }}
            </a>
          </td>
          <td>
            <button class="delete" (click)="delete(result);
                    $event.stopPropagation()">x</button>
          </td>
        </tr>
      </tbody>
    </table>

    <nav aria-label="Pagination control">
      <ul class="pagination">
        <li *ngIf="previous">
          <a [routerLink]="['/exchange/request/status']" [queryParams]="{ page: previousPage }"
                  (click)="gotoPage(previousPage)" aria-label="Previous Page">
            <span aria-hidden="true">&laquo; Previous Page</span>
          </a>
        </li>
        <li *ngIf="next">
          <a [routerLink]="['/exchange/request/status']" [queryParams]="{ page: nextPage }"
                  (click)="gotoPage(nextPage)" aria-label="Next Page">
            <span aria-hidden="true">Next Page &raquo;</span>
          </a>
        </li>
      </ul>
    </nav>

  </div>
</div>
