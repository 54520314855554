
export class Campus {

  constructor(
    public id = '',
    public name = '',
    public url = '',
    public address1 = '',
    public address2 = '',
    public city = '',
    public state = '',
    public postal_code = '',
    public primary_contact = '',
    public campus_category = '',
    public campus_system = '',
    public primary_contact_str = '',
    public campus_system_str = '',
    public user_list = [],
    public created_date = '',
    public modified_date = '',
  ) { }

}
