import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { HoldingBook } from '../../holding-book/holding-book';
import { AcademicTerm } from '../../academic-term/academic-term';


@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Input() record: any;
  @Input() holding: HoldingBook;
  @Input() academicTerms: AcademicTerm[];
  @Input() buttonText = 'Request';
  @Input() buttonStyle = 'btn-default';

  @Output() onSubmitClickedEmitter = new EventEmitter<any>();
  @Output() onCloseClickedEmitter = new EventEmitter<HoldingBook>();
  @ViewChild('requestCreateModal') public requestCreateModal: ModalDirective;
  @ViewChild('selectedTerm') public selectedTerm: string;

  savedRecord: HoldingBook;
  selectedTermId: string;
  certified = false;

  constructor() { }

  ngOnInit() {
  }

  onSubmitClicked() {
    const data = {
      'holding': this.holding,
      'termId': this.selectedTermId,
    };
    this.onSubmitClickedEmitter.emit(data);

    this.requestCreateModal.hide();
    this.certified = false;
  }

  onCloseClicked() {
    this.requestCreateModal.hide();
    this.certified = false;
    if (this.savedRecord === undefined) {
      this.onCloseClickedEmitter.emit(undefined);
    } else {
      this.onCloseClickedEmitter.emit(this.savedRecord);
    }
  }
}
