import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseDetailComponent } from '../../base/base-detail.component';
import { UserService } from '../../user/user.service';
import { HoldingBook } from '../../holding-book/holding-book';
import { HoldingBookService } from '../../holding-book/holding-book.service';
import { Book } from '../../book/book';
import { BookService } from '../../book/book.service';
import { ExchangeRequest } from '../exchange-request';
import { ExchangeRequestLog } from '../../exchange-request-log/exchange-request-log';
import { ExchangeRequestLogService } from '../../exchange-request-log/exchange-request-log.service';
import { ExchangeRequestService } from '../exchange-request.service';


@Component({
  selector: 'app-exchange-request-detail',
  templateUrl: './exchange-request-detail.component.html',
  styleUrls: ['./exchange-request-detail.component.css']
})
export class ExchangeRequestDetailComponent extends BaseDetailComponent<ExchangeRequest> implements OnInit {

  viewTitle = 'Request - AIMHub';
  failedNotification = '';

  user: any;
  holding: HoldingBook;
  book: Book;

  nextLogPage = 1;
  logList: ExchangeRequestLog[] = [];

  constructor(
    protected dataService: ExchangeRequestService,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected holdingBookService: HoldingBookService,
    protected bookService: BookService,
    protected logService: ExchangeRequestLogService,
  ) {
    super(dataService, route);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getUser();
  }

  async fetch(id): Promise<ExchangeRequest> {
    try {
      this.getLogs(id);

      // Locally scoped. Dependent sequence.
      const record = await this.dataService.read(id);
      const holding = await this.dataService.getHolding(record);
      const book = await this.bookService.read(holding.book);

      // All HTTP requests successful. Assign to object scope.
      this.record = record;
      this.holding = holding;
      this.book = book;
      return record;

    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  async getUser(): Promise<any> {
    this.user = await this.userService.getProfile();
  }

  async getLogs(recordId?: string): Promise<any> {
    try {
      // Only perform paging if there is another page.
      if (!this.nextLogPage) {
        return Promise.resolve();
      }

      recordId = recordId ? recordId : this.record.id;
      if (!recordId) {
        throw('No recordId passed or assigned to object!');
      }

      // Add next page to the list.
      const logPage = await this.logService.listRaw(recordId, this.nextLogPage)
      this.logList = this.logList.concat(logPage['results']);

      // Check if there is another page.
      this.nextLogPage = logPage['next'] ? this.nextLogPage + 1 : 0;

      // No further logs.
      return Promise.resolve();

    } catch (e) {
      this.handleError(e);
    }
  }

  logScroll() {
    this.getLogs();
  }

  /** When refreshing logs, always start on page 1, with a clear list. **/
  resetLogs() {
    this.logList = [];
    this.nextLogPage = 1;
    this.getLogs();
  }

  async updateStatus(status_str: string): Promise<any> {
    try {
      this.record = await this.dataService.updateStatus(this.record, status_str);
      this.getLogs();
    } catch (e) {
      this.handleError(e);
    }
  }

  logSubmitClicked(log: any) {
    this.resetLogs();
  }

  fileSubmitClicked(file: any) {
    this.resetLogs();
  }

  fileDeleteClicked(file: any) {
    this.resetLogs();
  }
}
