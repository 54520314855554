import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { FileFormatListComponent } from './file-format-list/file-format-list.component';


const routes: Routes = [
  {
    path: 'file-format',
    component: FileFormatListComponent,
    canActivate: [AuthGuardService],
    title: 'File Format List' // Title for the file format list page
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FileFormatRoutingModule {}
