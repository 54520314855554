import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { ExchangeRequestStatusService } from './exchange-request-status.service';
import { ExchangeRequestStatusRoutingModule } from './exchange-request-status-routing.module';
import { ExchangeRequestStatusListComponent } from './exchange-request-status-list/exchange-request-status-list.component';
import { ExchangeRequestStatusFormComponent } from './exchange-request-status-form/exchange-request-status-form.component';
import {
  ExchangeRequestStatusFormCreateComponent
} from './exchange-request-status-form-create/exchange-request-status-form-create.component';
import {
  ExchangeRequestStatusFormUpdateComponent
} from './exchange-request-status-form-update/exchange-request-status-form-update.component';
import { ExchangeRequestStatusCreateComponent } from './exchange-request-status-create/exchange-request-status-create.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ExchangeRequestStatusRoutingModule,
  ],
  exports: [
    ExchangeRequestStatusListComponent,
    ExchangeRequestStatusFormCreateComponent,
    ExchangeRequestStatusFormUpdateComponent,
  ],
  declarations: [
    ExchangeRequestStatusListComponent,
    ExchangeRequestStatusFormComponent,
    ExchangeRequestStatusFormCreateComponent,
    ExchangeRequestStatusFormUpdateComponent,
    ExchangeRequestStatusCreateComponent,
  ],
  providers: [
    ExchangeRequestStatusService,
  ],
})
export class ExchangeRequestStatusModule { }
