import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-form-buttons',
  template: `
  <div class="button-row">
    <button *ngIf="!readOnlyForm"
        mat-raised-button (click)="onSubmitClickedEmitter.emit()"
        type="submit" color="primary"
        [disabled]="submitting || formComposite.invalid"
        >
      Save<mat-icon>check</mat-icon>
    </button>

    <button mat-raised-button ngxClipboard *ngIf="false"
        type="button"
        [cbContent]="clipboardText"
        >
      Copy<mat-icon>content_copy</mat-icon>
    </button>

    <button *ngIf="!readOnlyForm"
        mat-raised-button (click)="onResetClickedEmitter.emit()"
        type="button"
        [disabled]="submitting"
        >
      Reset<mat-icon>undo</mat-icon>
    </button>

    <button *ngIf="showClose"
        mat-raised-button (click)="onCloseClickedEmitter.emit()"
        type="button"
        [disabled]="submitting"
        >
      Close<mat-icon>close</mat-icon>
    </button>

    <button *ngIf="showBack"
        mat-raised-button (click)="onBackClickedEmitter.emit()"
        type="button"
        [disabled]="submitting"
        >
      Back<mat-icon>back</mat-icon>
    </button>
  </div>
  `,
})
export class FormButtonsComponent {

  @Input() formComposite: FormGroup;
  @Input() submitting = false;
  @Input() readOnlyForm = false;
  @Input() clipboardText = '';

  @Input() showBack = false;
  @Input() showClose = false;

  @Output() onSubmitClickedEmitter = new EventEmitter();
  @Output() onCopyClickedEmitter = new EventEmitter();
  @Output() onResetClickedEmitter = new EventEmitter();
  @Output() onCloseClickedEmitter = new EventEmitter();
  @Output() onBackClickedEmitter = new EventEmitter();

  constructor() { }
}
