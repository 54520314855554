import { Component, Input } from '@angular/core';

import { User } from './user';


@Component({
  selector: 'app-user-card',
  template: `
    <div id="user-{{ record.id }}">

      <app-card-field [uniqueId]="record.id"
          [labelText]="'Name'" [fieldName]="'Name'"
          >
        {{ record.full_name }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id"
          [labelText]="'Email'" [fieldName]="'Email'"
          >
        {{ record.email }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id"
          [labelText]="'Campus'" [fieldName]="'Campus'"
          >
        {{ record.campus_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id"
          [labelText]="'Last Login'" [fieldName]="'Last Login'"
          >
        <span *ngIf='record.last_login'>
          {{ record.last_login | date: 'medium' }}
        </span>
        <span *ngIf='!record.last_login'>
          Never
        </span>
      </app-card-field>

      <app-card-field [uniqueId]="record.id"
          [labelText]="'Active'" [fieldName]="'Active'"
          >
        {{ record.is_active ? 'Yes' : 'No' }}
      </app-card-field>

    </div>
    `,
})
export class UserCardComponent {

  @Input() record: User;

  constructor() { }
}
