import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseFieldsComponent } from '../../base/base-fields.component';

import { User } from '../user';


/**
 * A set of form fields to manipulate a user object.
 * @example
 * <form [formGroup]="formComposite" (ngSubmit)="onSubmit()">
 *   <div formGroupName="user">
 *     <app-user-fields [group]="formComposite.controls.user">
 *     </app-user-fields>
 *   </div>
 *   <button type="submit">Submit</button>
 * </form>
 */
@Component({
  selector: 'app-user-fields',
  templateUrl: './user-fields.component.html',
  styleUrls: ['./user-fields.component.css']
})
export class UserFieldsComponent extends BaseFieldsComponent {

  @Input() currentUser: User;

  isSystemAdmin(): boolean {
    if (this.currentUser !== undefined) {
      if (this.currentUser.role === 'System Administrator') {
        return true;
      }
    }
    return false;
  }
}


/**
 * Static function that returns a user field formgroup.
 *
 * @param {User} user - user object to initialize fields with.
 * @returns {FormGroup} intialized set of user form fields.
 */
export function initUserFormGroup(user: User): FormGroup {
  // Build the form.
  const fb = new FormBuilder();
  const form = fb.group({
    // User information
    'id': [user.id, [] ],
    'full_name': [user.full_name, [ Validators.required ] ],
    'email': [user.email, [ Validators.required, Validators.email ] ],
    'phone': [user.phone, [ ] ],
    'position': [user.position, [ Validators.required ] ],

    // @TODO: convert to autocomplete select.
    'role': [user.role, [ ] ],
    'campus': [user.campus, [ Validators.required ] ],
    'campus_str': [user.campus_str, [ ] ],
  });

  return form;
}
