<button type="button" class="btn {{ buttonStyle }}"
        (click)="fileDeleteModal.show()">
  {{ buttonText }}
</button>


<div bsModal #fileDeleteModal="bs-modal" class="modal fade"
     [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog"
     aria-labelledby="fileDeleteModalLabel" aria-hidden="true">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title pull-left" id="fileDeleteModalLabel">
          Delete Confirmation
        </h3>
        <button type="button" class="close pull-right"
                (click)="onCloseClicked()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">
          <p class="col-sm-10">Please confirm that you want to delete the following file:<p>

          <div id="file-item" class="col-sm-6">
            <p *ngIf="record"> <strong>File:</strong> {{ record.media_file | fileName }} </p>
            <p *ngIf="record"> <strong>Size:</strong> {{ record.size | fileSize }} </p>
          </div>
        </div>

        <div class="row">
          <form  id="confirm-delete" class="form-group col-sm-6">
            <button type="submit" class="btn btn-danger"
              (click)="onSubmitClicked()"
              >
              Delete File
            </button>
            <button type="button" class="btn btn-default"
              (click)="onCloseClicked()"
              >
              Cancel
            </button>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>

