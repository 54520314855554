import { Pipe, PipeTransform } from '@angular/core';

declare var ISBN: any;

/*
 * Display an ISBN as ISBN10.
 * Will return the value given, if not a valid ISBN.
 * Usage:
 *   value | asIsbn10
 * Example:
 *   {{ 9781494932633 | asIsbn10 }}
 *   formats to: 1494932636
*/
@Pipe({name: 'asIsbn10'})
export class AsIsbn10Pipe implements PipeTransform {
  transform(value: string): string {
    const isbn = ISBN.parse(value);
    if (isbn !== null) {
      if (isbn.isIsbn10() || isbn.isIsbn13()) {
        return isbn.asIsbn10();
      }
    }
    return value;
  }
}

/*
 * Display an ISBN as ISBN13.
 * Will return the value given, if not a valid ISBN.
 * Usage:
 *   value | asIsbn13
 * Example:
 *   {{ 1494932636 | asIsbn13 }}
 *   formats to: 9781494932633
*/
@Pipe({name: 'asIsbn13'})
export class AsIsbn13Pipe implements PipeTransform {
  transform(value: string): string {
    const isbn = ISBN.parse(value);
    if (isbn.isIsbn10() || isbn.isIsbn13()) {
      return isbn.asIsbn13();
    }
    return value;
  }
}

/*
 * Display an ISBN with hyphenation.
 * Will return the value given, if not a valid ISBN.
 * Usage:
 *   value | isbnHyphens
 * Example:
 *   {{ 9781494932633 | isbnHyphens }}
 *   formats to: 978-4-87311-336-4
*/
@Pipe({name: 'isbnHyphens'})
export class IsbnHyphensPipe implements PipeTransform {
  transform(value: string): string {
    const isbn = ISBN.parse(value);
    if (isbn.isIsbn10() || isbn.isIsbn13()) {
      return ISBN.hyphenate(value);
    }
    return value;
  }
}

/*
 * Display an ISBN with no hyphenation.
 * Will return the value given, if not a valid ISBN.
 * Usage:
 *   value | isbnNoHyphens
 * Example:
 *   {{ 9781494932633 | isbnNoHyphens }}
 *   formats to: 978-4-87311-336-4
*/
@Pipe({name: 'isbnNoHyphens'})
export class IsbnNoHyphensPipe implements PipeTransform {
  transform(value: string): string {
    const isbn = ISBN.parse(value);
    if (isbn.isIsbn10()) {
      return isbn.asIsbn10();
    }
    if (isbn.isIsbn13()) {
      return isbn.asIsbn13();
    }
    return value;
  }
}
