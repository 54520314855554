<mat-card class="register-card">

  <mat-card-title> Registration Confirmation </mat-card-title>

  <mat-card-content>

    <div id="success-notification" class="alert alert-success" *ngIf="success">
      Account has been successfully registered. <br>
      You will now be redirected to <a id="login-link" routerLink="/">sign in</a>.
    </div>
    <div id="failed-notification" class="alert alert-danger"
         *ngIf="registerConfirmForm.hasError('backend')">
      {{ registerConfirmForm.getError('backend') }}
    </div>

    <app-password-policy> </app-password-policy>

    <form [formGroup]="registerConfirmForm" *ngIf="active" (ngSubmit)="onSubmit()">

      <mat-form-field class="register-field">
        <input matInput placeholder="New Password"
            type="password"
            formControlName="new_password1"
            required
            >
        <mat-error *ngIf="registerConfirmForm.controls.new_password1.hasError('backend')">
          {{ registerConfirmForm.controls['new_password1'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="registerConfirmForm.controls.new_password1.hasError('required')">
          New password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="register-field">
        <input matInput placeholder="Confirm New Password"
            type="password"
            formControlName="new_password2"
            required
            >
        <mat-error *ngIf="registerConfirmForm.controls.new_password2.hasError('backend')">
          {{ registerConfirmForm.controls['new_password2'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="registerConfirmForm.controls.new_password2.hasError('required')">
          Password confirmation is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit"
          [disabled]="!registerConfirmForm.valid || submitting"
          >
        Confirm Registration
      </button>

    </form>

  </mat-card-content>

</mat-card>
