<main>

<h1 hidden="true">Welcome to AIMHub</h1>

<!-- Left column -->
<div class="col-md-3">

  <mat-card class="left-column-card">

    <mat-card-title> Contents </mat-card-title>

    <mat-card-content>
      <ul class="list-group">
        <li class="list-group-item"><a href="#introduction" (click)="updateSection('introduction')">AIMHub 2024 Re-Launch.</a></li>
        <li class="list-group-item"><a href="#2024relaunch" (click)="updateSection('introduction')">About AIMHub</a></li>
        <li class="list-group-item"><a href="#features" (click)="updateSection('features')">Features</a></li>
        <li class="list-group-item"><a href="#project-status" (click)="updateSection('project-status')">Project Status</a></li>
        <li class="list-group-item"><a
            href="#accessible-instructional-materials" (click)="updateSection('instructional-materials')">Accessible Instructional Materials</a></li>
        <li class="list-group-item"><a href="#cost-savings" (click)="updateSection('cost-savings')">Cost Savings</a></li>
        <li class="list-group-item"><a href="#reduced-liability" (click)="updateSection('reduced-liability')">Reduced Liability</a></li>
        <li class="list-group-item"><a href="#existing-systems" (click)="updateSection('existing-systems')">Existing Systems</a></li>
      </ul>
    </mat-card-content>

  </mat-card>


  <mat-card class="left-column-card">

    <mat-card-title> Sponsors </mat-card-title>

    <mat-card-content>
      <p>
        <a
          href="https://ati.calstate.edu/"
          alt="The CSU Accessible Technology Initiative">
          CSU Accessible Technology Initiative
        </a>
      </p>
      <p>
        <a
          href="https://cccaccessibility.org/"
          alt="The CCC Accessibility Center">
          CCC Accessibility Center
        </a>
      </p>
    </mat-card-content>

  </mat-card>

</div>
<!-- End left column -->

<!-- Middle column -->
<div class="col-md-6">

    <mat-card *ngIf="activeSection === 'introduction'">
      <mat-card-title id="introduction">About AIMHub</mat-card-title>
      <mat-card-content>
        <p>
          The AIMHub project is a system for disability staff at higher
          education campuses, to exchange accessible instructional materials,
          reducing duplicated and costly work. All exchanged materials must be
          directly associated with providing accommodations for a student with a
          verified print disability.
        </p>
        <p>
          AIMHub is a collaboration between the California State
          University and California Community Colleges, to develop a unified
          web-based system that will assist students with disabilities, and the
          campus staff who serve them. AIMHub improves the
          educational experience for students with disabilities, by
          eliminating redundant efforts, integrating the best aspects of
          similar legacy systems, and delivering long desired functionality to
          the three California public higher education systems’
          accessible media service providers.
        </p>
      </mat-card-content>
    </mat-card>

  <mat-card *ngIf="activeSection === 'introduction'">
    <mat-card-title id="2024relaunch">AIMHub 2024 Relaunch</mat-card-title>
    <mat-card-content>
      <p>
        The CSU Accessible Technology Innitiative will begin updating and refreshing AIMHub for 2024.
        Site functionality has degraded since 2019 and a variety of bugs have been identified.
      </p>
      <p>
        <b>2024 AIMHub Refresh Immediate Priorities</b>
      </p>
      <ul>
        <li>Bring Front-End and Back-End frameworks to most up to date releases</li>
        <li>Repair new user account creation system</li>
        <li>Repair e-mail notifications for new holding requests and request fulfillment</li>
        <li>Repair password reset feature</li>
      </ul>
      <p>
        <b>2024 AIMHub Refresh Long Term Goals</b>
      </p>
      <ul>
        <li>Review and improve site accessibility</li>
        <li>Update front-end design to modern standards</li>
        <li>Review and update security as necessary</li>
      </ul>
      <p>If you have ideas, suggestions, or bug reports, please e-mail support@aimhub.org</p>
    </mat-card-content>
  </mat-card>


    <mat-card *ngIf="activeSection === 'features'">
      <mat-card-title id="features">Features</mat-card-title>
      <mat-card-content>
        <p>
          AIMHub takes advantage of the wealth of technological improvements made
          over the past decade. The system emphasizes accessible interfaces and
          secure design.
        </p>
      </mat-card-content>
    </mat-card>


    <mat-card *ngIf="activeSection === 'project-status'">
      <mat-card-title id="project-status">Project Status</mat-card-title>
      <mat-card-content>
        <p>
          AIMHub was launched in August 2017 for California State University CAM
          users, and in June 2018 for California Community College AMX Database
          users. The CSU CAM and CCC AMX Database projects have been retired and
          replaced by AIMHub. The AIMHub team is reviewing current functionality
          and identifying potential user interface enhancements.
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="activeSection === 'instructional-materials'">
      <mat-card-title id="accessible-instructional-materials">Accessible Instructional Materials</mat-card-title>
      <mat-card-content>
      <p>Converting instructional materials into accessible formats is a
      legally mandated service for students with disabilities, required under both
      federal and state law. This allows students with print disabilities, such
      as dyslexia and visual impairment, to access instructional materials, like
      textbooks and captioned video.</p>

      <p>Consequences of not delivering usable accessible instructional materials
      (AIM) to students on time are severe, including unequal access to education,
      reduced independence, failed courses, loss of financial aid, and lawsuits.
      The majority of accessible instructional material services have difficulty
      keeping up with the demand and providing accurate materials in a timely way.</p>

      </mat-card-content>
    </mat-card>


    <mat-card *ngIf="activeSection === 'cost-savings'">
      <mat-card-title id="cost-savings">Cost Savings</mat-card-title>
      <mat-card-content>
      <p>This project directly meets the goal of California Higher Education systems
      working together to share common services and cut costs. Once the
      systems are fully merges into AIMHub, there will be a
      cost savings from:
      </p>
      <ul>
        <li>Reduced duplication of effort.</li>
        <li>A larger pool of materials to search from.</li>
        <li>Increased delivery speed, due to quicker media acquisition between campuses.</li>
        <li>Smoother tracking of conversion work, reducing inefficient processes.</li>
      </ul>

      </mat-card-content>
    </mat-card>


    <mat-card *ngIf="activeSection === 'reduced-liability'">
      <mat-card-title id="reduced-liability">Reduced Liability</mat-card-title>
      <mat-card-content>
      <p>Timely delivery also yields risk mitigation and compliance benefits. By
      using AIMHub, campuses are able to fulfill student accommodations in a
      timelier manner, limiting the grounds for legal action. Further, campuses
      have an easier time complying with relevant legislation, such as
      <a href="http://leginfo.legislature.ca.gov/faces/billNavClient.xhtml?bill_id=199920000AB422"
          alt="CA Assembly Bill 422">
        AB 422</a> and
      <a href="http://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=200920100AB386"
          alt="CA Assembly Bill 386">
        AB 386</a> (section 67302 of the Education Code).</p>

      </mat-card-content>
    </mat-card>


    <mat-card *ngIf="activeSection === 'legacy-systems'">
      <mat-card-title id="legacy-systems">Legacy Systems</mat-card-title>
      <mat-card-content>
        <p>
          Campuses often must reinvent organizational, tracking, and management
          systems for their accessible material services. This takes valuable time
          away from actually formatting those materials, and delivering them to
          students.
        </p>

        <p>
          Both the CSU and the CCC developed web applications to facilitate the
          exchange of converted accessible instructional material (AIM) files between
          campuses:
        </p>
        <ul>
          <li>The CSU <a href="https://cam.calstate.edu"> Center for Accessible Media (CAM)</a> (retired)</li>
          <li>The CCC <a href="https://amxdb.net"> Alternate Media Exchange Database (AMXDB)</a> (retired)</li>
        </ul>

        <p>
          While both of these systems improved disability services, they focused
          on manually exchanging files between campuses, were redundant, and
          used web technology that is over a decade old. The CSU and CCC
          recognized the mutual benefits of developing a unified, modern system, with
          improved functionality, and retiring their legacy systems.
        </p>

      </mat-card-content>
    </mat-card>

    <mat-card class="announcement-card">
      <mat-card-title id="account-support">Support</mat-card-title>
      <mat-card-content>
        <p>
          To get an account, talk to your campus administrator for AIMHub.
          This is usually the person responsible for accessible media on your
          campus.
        </p>
        <p>
          If you are the person responsible for accessible media on your
          campus, and are within the CSU or CCC higher education systems,
          contact AIMHub support to get signed up:
          <a href="mailto:support@aimhub.org">support@aimhub.org</a>.
        </p>
        <p>
          If you are having trouble accessing your account, try refreshing your
          browser, updating your browser, or contact AIMHub support:
          <a href="mailto:support@aimhub.org">support@aimhub.org</a>.
        </p>
      </mat-card-content>
    </mat-card>

</div>
<!-- End middle col -->


<div class="col-md-3">
  <app-login-form></app-login-form>
  <app-register-form></app-register-form>
</div>

</main>
