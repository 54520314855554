<div id="link-book-list" class="dropdown" role="presentation">
  <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"
              aria-expanded="false">Books<span class="caret"></span></a>
  <ul class="dropdown-menu" role="menu">
    <li role="presentation" class="dropdown-header">Books</li>
    <li><a id="link-book-list" routerLink="/book">List of Books</a></li>
    <li><a id="link-book-create" routerLink="/book/create">Add a Book Manually</a></li>
    <li role="presentation" class="divider"></li>
    <li role="presentation" class="dropdown-header">Publishers</li>
    <li><a id="link-publisher-list" routerLink="/publisher">List of Publishers</a></li>
    <li><a id="link-publisher-create" routerLink="/publisher/create">Add a Publisher</a></li>
  </ul>
</div>
