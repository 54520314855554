import { Component } from '@angular/core';


@Component({
  selector: 'app-exchange-request-list',
  templateUrl: './exchange-request-in-list.component.html',
  styleUrls: ['./exchange-request-in-list.component.css']
})
export class ExchangeRequestInListComponent {
  baseUrl = '/exchange/request/in/';
  apiUrl = 'api/exchange/requests/in/';
  viewTitle = 'Requests In - AIMHub';
}
