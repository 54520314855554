import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthToken } from '../auth/auth-token';
import { BaseCrudService } from '../base/base-crud.service';
import { HoldingBook } from '../holding-book/holding-book';

import { ExchangeRequest } from './exchange-request';


@Injectable()
export class ExchangeRequestService extends BaseCrudService<ExchangeRequest> {

  endpointUrl = `${this.apiHost}/api/exchange/requests`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  async request(record: any): Promise<ExchangeRequest> {
    try {
      const apiUrl = `${this.endpointUrl}/create/`;
      const data = JSON.stringify(record);
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      const response = await this.http.post(apiUrl, data, httpConfig).toPromise();
      return response as ExchangeRequest;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  async getHolding(record: ExchangeRequest): Promise<HoldingBook> {
    try {
      const apiUrl = `${this.endpointUrl}/${record.id}/holding/`;
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      const response = await this.http.get(apiUrl, httpConfig).toPromise();
      return response as HoldingBook;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  async updateStatus(record: ExchangeRequest, status_str: string): Promise<ExchangeRequest> {
    try {
      const apiUrl = `${this.endpointUrl}/${record.id}/status/`;
      const data = JSON.stringify({'status': status_str});
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      const response = await this.http.patch(apiUrl, data, httpConfig).toPromise();
      return response as ExchangeRequest;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  count(): Promise<any> {
    const apiUrl = `${this.endpointUrl}/count/`;

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig)
      .toPromise()
      .catch(this.handleError);
  }
}
