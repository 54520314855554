import { Component, OnInit, Input } from '@angular/core';

// Required for 3rd party JS import to TypeScript
// Also installed via npm, and included in angular-cli.json#scripts
//
// http://stackoverflow.com/questions/39742257/importing-a-js-library-with-no-export-in-angular-2-0-0-with-ancular-cli-and-webp
declare var ISBN: any;

import { Book } from './book';

@Component({
  selector: 'app-book-card',
  template: `
    <div id="book-{{ record.isbn }}">

      <app-card-field [uniqueId]="record.id" [labelText]="'Title'" [fieldName]="'Title'">
        {{ record.title }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Edition'" [fieldName]="'Edition'">
      {{ record.edition }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Author(s)'" [fieldName]="'Authors'">
        {{ record.authors }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'ISBN-13'" [fieldName]="'Isbn13'">
        {{ record.isbn }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'ISBN-10'" [fieldName]="'Isbn10'">
        {{ record.isbn | asIsbn10 }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Publisher'" [fieldName]="'Publisher'">
        {{ record.publisher_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Copyright'" [fieldName]="'Copyright'">
        {{ record.copyright_year }}
      </app-card-field>

    </div>

    <ul id="book-web-links-{{ record.isbn }}" class="list-inline">
      <li class="list-group-item">
        <a target="_blank" rel="noopener noreferrer"
          href="https://www.worldcat.org/isbn/{{ record.isbn }}">
          WorldCat
        </a>
      </li>
      <li class="list-group-item">
        <a target="_blank" rel="noopener noreferrer"
          href="https://www.google.com/search?q={{ record.isbn }}">
          Google
        </a>
      </li>
    </ul>
  `
})
export class BookCardComponent implements OnInit {

  @Input() record: Book;
  isbn10 = 'Invalid';

  constructor() { }

  ngOnInit() {
    this.parseIsbn10();
  }

  parseIsbn10() {
    const isbn = ISBN.parse(this.record.isbn);

    if (isbn !== null) {
      if (isbn && isbn.isValid) {
        this.isbn10 = isbn.isbn10;
      }
    }
  }

}
