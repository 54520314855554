<main>

<h1>Requests for My Holdings</h1>

<app-exchange-request-base-list
  [baseUrl]="baseUrl"
  [apiUrl]="apiUrl"
  [viewTitle]="viewTitle"
  [campus_table_heading]="'Requester'"
  [campus_table_field]="'requesting_campus_str'">
</app-exchange-request-base-list>

</main>
