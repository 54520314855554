import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav-admin',
  templateUrl: './nav-admin.component.html',
  styleUrls: ['./nav-admin.component.css']
})
export class NavAdminComponent implements OnInit {

  @Input() userRole = '';
  showFullAdmin = false;

  constructor() { }

  ngOnInit() {
    this.setFullAdminView();
  }

  setFullAdminView(): void {
    if (this.userRole.indexOf('System Administrator') >= 0) {
      this.showFullAdmin = true;
    }
  }
}
