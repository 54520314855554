<main>

<h1>Campuses</h1>

<app-list-alert [objectName]="'campus'"
  [itemCreated]="itemCreated"
  [itemUpdated]="itemUpdated"
  [itemDeleted]="itemDeleted"
  >
</app-list-alert>


<form id="list-controls">
  <app-list-filter (onFilterEmitter)="onFilter($event)">
  </app-list-filter>

  <app-campus-dialog buttonColor="primary"
    (onSubmitSuccessEmitter)="onItemCreate($event)"
    >
  </app-campus-dialog>
</form>


<div class="panel">
  <div class="row">

    <table id="table-book-list" class="table table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>System</th>
          <th>Date Created</th>
          <th>Date Modified</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of results">

          <td> {{ result.name }} </td>
          <td> {{ result.campus_system_str }} </td>
          <td> {{ result.created_date | date: 'medium' }} </td>
          <td> {{ result.modified_date | date: 'medium' }} </td>

          <td>
            <a id="campus-detail-{{ result.id }}" mat-raised-button
                color="primary"
                [routerLink]="['/campus/detail', result.id]"
                >
              <span>
                Details<mat-icon>description</mat-icon>
              </span>
            </a>

            <app-campus-dialog buttonColor="primary"
              (onSubmitSuccessEmitter)="onItemUpdate($event)"
              [recordId]="result.id"
              >
            </app-campus-dialog>

            <app-campus-delete-dialog
              (onDeleteSuccessEmitter)="onItemDelete($event)"
              [record]="result"
              >
            </app-campus-delete-dialog>
          </td>
        </tr>
      </tbody>
    </table>

    <app-list-paginator [apiUrl]="'/campus'"
      [nextUrl]="next" [previousUrl]="previous"
      [currentPage]="currentPage"
      (onPagingEmitter)="gotoPage($event)"
      >
    </app-list-paginator>

  </div>
</div>

</main>
