import { Component, OnInit } from '@angular/core';
import { CampusService } from '../../campus/campus.service';


@Component({
  selector: 'app-nav-holding',
  templateUrl: './nav-holding.component.html',
  styleUrls: ['./nav-holding.component.css']
})
export class NavHoldingComponent implements OnInit {

  totalCampusBooks: number;
  totalCampusHoldings: number;

  constructor(protected campusService: CampusService) { }

  ngOnInit() {
    this.campusService.getCampusStatistics()
      .then(response => {
        this.totalCampusBooks = response['total_campus_books'];
        this.totalCampusHoldings = response['total_campus_holdings'];
      })
  }

}
