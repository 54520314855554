
export class HoldingBook {

  constructor(
    public id = '',
    public campus = '',
    public book = '',
    public file_format = '',
    public source = '',
    public campus_str = '',
    public book_str = '',
    public file_format_str = '',
    public source_str = '',
    public notes = '',
    public created_date = '',
    public modified_date = '',
    public is_public = true,
  ) { }

}
