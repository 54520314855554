import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { BookModule } from '../book/book.module';
import { ExchangeRequestModule } from '../exchange-request/exchange-request.module';

import { SearchRoutingModule } from './search-routing.module';
import { SearchService } from './search.service';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchResultListComponent } from './search-result-list/search-result-list.component';
import { SearchResultItemComponent } from './search-result-item/search-result-item.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BookModule,
    ExchangeRequestModule,
    SearchRoutingModule,
  ],
  exports: [
    SearchBarComponent,
    SearchResultListComponent,
    SearchResultItemComponent,
  ],
  declarations: [
    SearchBarComponent,
    SearchResultListComponent,
    SearchResultItemComponent,
  ],
  providers: [
    SearchService,
  ],
})
export class SearchModule { }
