import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

// These are failing
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ClipboardModule } from 'ngx-clipboard';

import { StateAutocompleteFieldComponent } from './state-autocomplete-field.component';
import { CardFieldComponent } from './card-field.component';
import { AnnouncementComponent } from './announcement.component';
import { FormButtonsComponent } from './form-buttons.component';
import { FormAlertComponent } from './form-alert.component';
import { ListAlertComponent } from './list-alert.component';
import { ListFilterComponent } from './list-filter.component';
import { ListPaginatorComponent } from './list-paginator.component';
import { DialogHeaderComponent } from './dialog-header.component';

import { ValidateOnBlurDirective } from './directive/validate-onblur';

import { TrimWhitespace } from './pipe/trim-whitespace.pipe';
import { FileNamePipe } from './pipe/filename.pipe';
import { FileSizePipe } from './pipe/filesize.pipe';
import { AdminUserFilterPipe } from './pipe/admin-user-filter.pipe';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    // These are failing
    InfiniteScrollModule,
    ClipboardModule,
    ProgressbarModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    // ProgressbarModule,
    // InfiniteScrollModule,
    // ClipboardModule,
    // ModalModule,
    // TypeaheadModule,
    // AlertModule,
    // BsDropdownModule,

    StateAutocompleteFieldComponent,
    CardFieldComponent,
    AnnouncementComponent,
    FormButtonsComponent,
    FormAlertComponent,
    ListAlertComponent,
    ListFilterComponent,
    ListPaginatorComponent,
    DialogHeaderComponent,
    ValidateOnBlurDirective,
    TrimWhitespace,
    FileNamePipe,
    FileSizePipe,
    AdminUserFilterPipe,
  ],
  declarations: [
    StateAutocompleteFieldComponent,
    CardFieldComponent,
    AnnouncementComponent,
    FormButtonsComponent,
    FormAlertComponent,
    ListAlertComponent,
    ListFilterComponent,
    ListPaginatorComponent,
    DialogHeaderComponent,
    ValidateOnBlurDirective,
    TrimWhitespace,
    FileNamePipe,
    FileSizePipe,
    AdminUserFilterPipe,
  ],
})
export class SharedModule { }
