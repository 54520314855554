<span [formGroup]="formGroup">

  <div class="form-column">

    <mat-form-field>
      <input matInput placeholder="Source Name"
                      formControlName="name" required
                      [readonly]="isReadOnly('name')">

      <mat-error *ngIf="formGroup.controls.name.hasError('backend')">
        {{ formGroup.controls['name'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.name.hasError('required')">
        Source name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

  </div>

</span>

