import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SessionService } from '../session.service';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  defaultUrl = 'home';
  email: string;
  password: string;

  loginForm: FormGroup;
  submitting = false;
  active = true;

  success = false;
  failed = false;
  successUrl: string;
  successNotification: string;
  failedNotification: string;

  formErrors = {
    'email': '',
    'password': '',
  };

  validationMessages = {
    'email': {
      'required':      'Email is required.',
      'invalidEmail':  'Email address is invalid',
    },
    'password': {
      'required':      'Password is required.',
    },
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sessionService: SessionService,
  ) { }

  ngOnInit() {
    this.resetFormModel();
    this.buildForm();
  }

  buildForm(): void {
    this.loginForm = this.fb.group({
      'email': [this.email, [ Validators.required, Validators.email ] ],
      'password': [this.password, [ Validators.required ] ],
    });

    this.loginForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  onSubmit() {
    this.submitting = true;

    if (this.loginForm.valid) {

      // Server side
      const { email, password } = this.loginForm.value;
      this.sessionService.login(email, password)
        .then(() => this.onSuccess())
        .catch(() => this.submitFailed());

    // Client side validation error
    } else {
      const recordForm = this.getRecordFields();
      this.markAllAsTouched(recordForm);
      this.submitFailed();
    }

    this.submitting = false;
  }

  onSuccess() {
    // Verify post-auth state with session service first.
    if (this.sessionService.isValidSession()) {
      this.success = true;
      this.failed = false;
      this.successRedirect();
    }
  }

  successRedirect() {
    const savedUrl = this.sessionService.redirectUrl;
    const redirectUrl = savedUrl ? savedUrl : this.defaultUrl;
    this.router.navigateByUrl(redirectUrl);
  }

  submitFailed(message = 'Invalid email and password combination') {
    this.failedNotification = message;
    this.success = false;
    this.failed = true;

    this.resetForm();
  }

  onValueChanged(data?: any) {
    if (!this.loginForm) { return; }
    this.refreshFieldErrors();
  }

  resetForm() {
    this.buildForm();

    this.active = false;
    setTimeout(() => this.active = true, 0);
  }

  getRecordFields(): FormGroup {
    return <FormGroup>this.loginForm;
  }

  /**
   * Mark all form field controls as 'touched.'
   * This is a workaround to display <mat-error> messages on submit.
   */
  markAllAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsTouched();
    });
  }

  resetFormModel(): void {
    this.email = '';
    this.password = '';
  }

  refreshFieldErrors() {
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.resetFieldErrors(field);
        this.collectFieldErrors(field);
      }
    }
  }

  resetFieldErrors(field: string) {
    this.formErrors[field] = '';
  }

  collectFieldErrors(field: string) {
    const form = this.loginForm;
    const control = form.get(field);

    if (control && control.dirty && !control.valid) {
      const messages = this.validationMessages[field];

      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  applyServerFormErrors(err: any) {
    for (const key in err) {
      // General form errors.
      if (key === 'detail' || key === 'non_field_errors') {
        const errMsg = {backend: err[key]};
        this.loginForm.setErrors(errMsg);
      // Form field errors.
      } else if (key in this.loginForm.controls) {
        const errMsg = {backend: err[key][0]};
        this.loginForm.controls[key].setErrors(errMsg);
      }
    }
  }
}
