import { Component, Output, EventEmitter } from '@angular/core';
import { Router, NavigationStart, DefaultUrlSerializer } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { filter } from 'rxjs/operators';

import { SEARCH_PARAM } from '../search.service';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent {

  @Output() searchClicked = new EventEmitter<string>();

  searchQuery = '';
  searchResultUrl = '/search';

  constructor(private router: Router) {
    this.watchRouterUpdate(router);
  }

  onSubmit() {
    this.searchClicked.emit(this.searchQuery);
    if (this.searchQuery !== '') {
      this.redirectSearchResult();
    }
  }

  redirectSearchResult() {
    const searchUrl = this.constructSearchUrl();
    this.router.navigateByUrl(searchUrl);
  }

  constructSearchUrl() {
    const searchParams = new HttpParams().set(SEARCH_PARAM, this.searchQuery);
    return `${this.searchResultUrl}?${searchParams.toString()}`;
  }

  watchRouterUpdate(router: Router) {
    router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const url = event.url;
        const u = new DefaultUrlSerializer().parse(url);

        if (this.isNewSearch(url)) {
          // Update search input if query entered directly into browser bar.
          this.searchQuery = u.queryParams[SEARCH_PARAM];
        }
      });
  }

  isNewSearch(url: string) {
    const re = /^\/search\/?\?/i;
    return re.test(url);
  }
}
