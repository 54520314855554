import { HttpHeaders } from '@angular/common/http';


export const AUTH_TOKEN_KEY = 'apiToken';


export class AuthToken {

  /**
   * Store the authentication token in the client.
   * @param {string} token - The authentication token.
   */
  static setToken(token: string) {
    localStorage[AUTH_TOKEN_KEY] = token;
  }

  /**
   * Retreive the authentication token stored in the client.
   * @return {string | undefined} The authentication token or undefined.
   */
  static getToken(): string | undefined {
    return localStorage[AUTH_TOKEN_KEY];
  }

  /**
   * Append the authentication token to existing HTTP headers.
   * The object passed in might be modified as a result.
   * @param {HttpHeaders} headers - An existing HTTP headers object.
   * @return {HttpHeaders} A header object with the authentication token.
   */
  static setAuthHeader(headers: HttpHeaders) {
    let newHeaders: HttpHeaders;
    const token = AuthToken.getToken();
    if (token) {
      newHeaders = headers.append('Authorization', `Token ${token}`);
    }
    return newHeaders;
  }

  /**
   * Destroy the authentication token stored in the client.
   */
  static destroyToken(): void {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }

  /**
   * Apply the authentication token to a HTTP configuration object, as a header.
   * @param {Object} httpConfig - An object containing HTTP request settings.
   * @return {Object} An HTTP request settings object with the authentication token.
   */
  static setHttpConfigAuthHeader(httpConfig?: Object) {
    let newHttpConfig = Object.assign({}, httpConfig);
    newHttpConfig['headers'] = AuthToken.setAuthHeader(newHttpConfig['headers']);
    return newHttpConfig;
  }

  constructor() { }
}
