<div *ngIf="parentFormGroup" [formGroup]="parentFormGroup">

  <mat-form-field class="autocomplete-form-container">
    <input matInput type="text" placeholder="Publisher"
          [matAutocomplete]="recordAuto"
          [readonly]="readOnly"
          [required]="required"
          formControlName="publisher"
          >

    <mat-autocomplete #recordAuto="matAutocomplete" [displayWith]="displayValue">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option.name }}
      </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="parentFormGroup.controls.publisher.hasError('backend')">
      <span *ngIf="myControl.errors['backend'].toString().indexOf('UUID') > -1">
        Publisher does not exist.
      </span>
      <span *ngIf="myControl.errors['backend'].toString().indexOf('UUID') === -1">
        {{ parentFormGroup.controls['publisher'].errors['backend'] }}
      </span>
    </mat-error>
    <mat-error *ngIf="parentFormGroup.controls.publisher.hasError('required')">
        Publisher is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <app-publisher-dialog [iconOnly]="true"
    (onSubmitSuccessEmitter)="onNewRecord($event)"
    >
  </app-publisher-dialog>

</div>

<div *ngIf="!parentFormGroup">
  <mat-form-field>

    <input matInput type="text" placeholder="Publisher"
          [matAutocomplete]="recordAuto" [readonly]="readOnly"
          [formControl]="myControl"
          >

    <mat-autocomplete #recordAuto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
          {{ option.name }}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>
</div>
