import {OnInit, OnChanges, SimpleChanges, Output, EventEmitter, Injectable} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { IServiceModel } from './service.interface';
import { BaseCrudService } from './base-crud.service';

@Injectable()  // <-- Add this decorator
export class BaseCrudFormComponent<T extends IServiceModel> implements OnInit, OnChanges {

  // Override
  protected formErrors = {};
  protected validationMessages = {};
  protected recordUrlPrefix: string;

  // Inherited Outputs
  // https://github.com/angular/angular/issues/5415#issuecomment-253509453
  @Output() onSubmitClickedEmitter = new EventEmitter<T>();
  @Output() onCloseClickedEmitter = new EventEmitter<T>();

  // Inputs
  record: T;
  serviceCall: string;

  savedRecord: T;
  recordForm: FormGroup;
  submitting = false;
  active = true;
  success = false;
  failed = false;
  recordUrl: string;


  // Override
  protected initFormModel(): void {}
  protected getFormModel(): T { return this.record; }
  protected buildFormGroup(): FormGroup { return new FormBuilder().group({}); }

  constructor(protected dataService: BaseCrudService<T>) {}

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildForm();
  }

  buildForm(): void {
    if (this.record === undefined) { this.initFormModel(); }
    if (this.serviceCall === 'create') { this.initFormModel(); }

    this.recordForm = this.buildFormGroup();
    this.registerValueChange();
  }

  closeModal() {
    this.onCloseClickedEmitter.emit(this.record);
    this.success = false;
    this.failed = false;
  }

  create() {
    this.preCreate();
    const formModel = this.getFormModel();
    formModel.id = '';
    this.dataService.create(formModel)
      .then(record => this.submitSuccess(record))
      .then(() => this.postCreate())
      .catch( err => this.handleServerErrors(err));
  }

  update(apiUrl = '') {
    this.preUpdate();
    this.dataService.update(this.getFormModel(), apiUrl)
      .then(record => this.submitSuccess(record))
      .then(() => this.postUpdate())
      .catch( err => this.handleServerErrors(err));
  }

  preCreate() { }

  postCreate() { }

  preUpdate() { }

  postUpdate() { }

  onSubmit() {
    this.submitting = true;

    // Client side validation
    if (!this.recordForm.valid) {
      this.submitFailed();
    } else {
      this.submitForm();
    }

    this.submitting = false;
  }

  submitForm() {
    if (this.serviceCall === 'create') {
      this.create();
    } else if (this.serviceCall === 'update') {
      this.update();
    } else {
      throw new Error('serviceCall must be "create" or "update"');
    }
  }

  submitSuccess(record) {
    this.record = record;
    this.savedRecord = record;
    this.buildForm();

    this.onSubmitClickedEmitter.emit(record);
    this.recordUrl = this.recordUrlPrefix + record.id;
    this.success = true;
    this.failed = false;
  }

  submitFailed() {
    this.success = false;
    this.failed = true;
  }

  resetForm() {
    this.success = false;
    this.failed = false;
    this.buildForm();

    this.active = false;
    setTimeout(() => this.active = true, 0);
  }

  registerValueChange() {
    this.recordForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  onValueChanged(data?: any) {
    if (!this.recordForm) { return; }
    this.refreshFieldErrors();
  }

  refreshFieldErrors() {
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.resetFieldErrors(field);
        this.collectFieldErrors(field);
      }
    }
  }

  resetFieldErrors(field: string) {
    this.formErrors[field] = '';
  }

  collectFieldErrors(field: string) {
    const form = this.recordForm;
    const control = form.get(field);

    if (control && control.dirty && !control.valid) {
      const messages = this.validationMessages[field];

      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  handleServerErrors(err: any) {
    if (err.status === 400) { this.applyServerFormErrors(err); }
    if (err.status === 403) { this.applyServerFormErrors(err); }
    this.submitFailed();
  }

  applyServerFormErrors(err: any) {
    for (const key in err) {
      if (!Object.prototype.hasOwnProperty.call(this.formErrors, key)) { continue; };

      this.formErrors[key] = err[key];
    }
  }

  goBack(): void {
    window.history.back();
  }
}
