import {Component, Input} from '@angular/core';

import { BaseFormComponent } from '../../base/base-form.component';

import { CampusSystem } from '../campus-system';
import { CampusSystemService } from '../campus-system.service';
import { initCampusSystemFormGroup } from '../campus-system-fields/campus-system-fields.component';


/**
 * CampusSystem form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-campus-system-form',
  templateUrl: './campus-system-form.component.html',
})
export class CampusSystemFormComponent extends BaseFormComponent<CampusSystem> {
  @Input() injectedRecordId: string = '';  // Explicitly redeclare this as an input
  constructor(
    protected dataService: CampusSystemService,
  ) {
    super(CampusSystem, initCampusSystemFormGroup, dataService);
  }

  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
    // Get target form values.
    const {
      name = ''
    } = this.formComposite.value.record;

    // Format clipboard contents.
    this.clipboardText = [
      `Name: ${name}`,
    ].join('\n');
  }
}
