<mat-spinner *ngIf="!formComposite"></mat-spinner>

<form *ngIf="formComposite" [formGroup]="formComposite"
  class="column-form-container" novalidate
  >

  <app-form-alert
    [record]="record" objectName="user"
    [formComposite]="formComposite" [success]="success"
    >
  </app-form-alert>

  <p> Select which email notifications you would like to receive. </p>

  <div formGroupName="record" class="column-form-container">
    <app-user-preference-fields
        [formGroup]="formComposite.controls.record"
        (onTogglePreferenceEmitter)="togglePreference($event)"
        >
    </app-user-preference-fields>
  </div>

</form>
