import {Injectable, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AppInjector } from '../app-injector.service';
import { RedirectService } from '../core/redirect.service';
import { IServiceModel } from './service.interface';
import { BaseCrudService } from './base-crud.service';

@Injectable()  // <-- Add this decorator
export class BaseDetailComponent<T extends IServiceModel> implements OnInit {
  viewTitle = 'AIMHub';
  record: T;

  protected titleService: Title;
  protected location: Location;
  protected redirectService: RedirectService;

  constructor(
    protected dataService: BaseCrudService<T>,
    protected route: ActivatedRoute
  ) {
    const injector = AppInjector.getInjector();
    this.titleService = injector.get(Title);
    this.location = injector.get(Location);
    this.redirectService = injector.get(RedirectService);
  }

  ngOnInit() {
    this.parseParams();

    this.titleService.setTitle( this.viewTitle );
  }

  parseParams() {
    this.route.params.forEach((params: Params) => {
      const id = params['id'];
      // @TODO: take this out of loop and test.
      this.fetch(id);
    });
  }

  fetch(id): Promise<any> {
    return this.dataService.read(id)
      .then(record => this.record = record)
      .catch(this.handleError);
  }

  update(): void {
    this.dataService.update(this.record);
  }

  delete(): void {
    this.dataService.delete(this.record.id)
      .then(this.goBack);
  }

  goBack(): void {
    this.location.back();
  }

  handleError(error: any) {
    // @TODO: Improve logging.
    console.log('DetailComponent error');
    console.error(error);
    if (error.status === 404) {
      this.redirectService.redirectNotFound();
    }
  }
}
