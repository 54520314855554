<button mat-button [matMenuTriggerFor]="menuHoldings">
  My Holdings
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #menuHoldings="matMenu">

  <a mat-menu-item id="link-book-batch-isbn"
      routerLink="/holding/book/create">
    Add Holdings
  </a>
  <a mat-menu-item id="link-holding-book-list"
      routerLink="/holding/book" routerLinkActive="active">
    List Holdings
  </a>

</mat-menu>
