<span [formGroup]="formGroup">

  <div class="form-column">

    <mat-form-field>
      <input matInput placeholder="Year"
                      formControlName="year" required
                      [readonly]="isReadOnly('year')">

      <mat-error *ngIf="formGroup.controls.year.hasError('backend')">
        {{ formGroup.controls['year'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.year.hasError('required')">
        Term year is <strong>required</strong>
      </mat-error>

    </mat-form-field>


    <mat-form-field>

      <mat-select placeholder="Season"
          *ngIf="!isReadOnly('season')"
          formControlName="season"
          required
          >
        <mat-option *ngFor="let season of seasons" [value]="season.value">
          {{ season.viewValue }}
        </mat-option>
      </mat-select>
      <input matInput placeholder="Season"
          *ngIf="isReadOnly('season')"
          formControlName="season"
          >

      <mat-error *ngIf="formGroup.controls.season.hasError('required')">
        Term season is <strong>required</strong>
      </mat-error>

    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Session"
          [required]="!isSystemAdmin()"
          [readonly]="isReadOnly('session')"
          formControlName="session"
          >

      <mat-error *ngIf="formGroup.controls.session.hasError('backend')">
        {{ formGroup.controls['session'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.session.hasError('required')">
        Term session is <strong>required</strong>
      </mat-error>

    </mat-form-field>

    <span *ngIf="isSystemAdmin()">
      <app-campus-autocomplete-field
        [parentFormGroup]="formGroup"
        [parentFormControlName]="'campus'"
        [readOnly]="isReadOnly('campus')"
        >
      </app-campus-autocomplete-field>
    </span>

  </div>

</span>

