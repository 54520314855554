<div class="row">
  <h2 class="list-group-item-heading col-xs-11 pull-left">
    {{ record.title | slice:0:70 }}
    <span *ngIf="record.title.length > 70">...</span>
  </h2>
</div>

<div class="row">

  <div class="col-sm-4">
    <h3>Book Information</h3>
    <app-book-card [record]="record"> </app-book-card>
  </div>

  <div class="col-sm-8">
    <alert type="success" *ngIf="success"
        [dismissible]="true"
        [dismissOnTimeout]="notifyTimeout"
        >
        Requested {{ record.title | slice:0:70 }}
        <a id="link-request-{{ requestRecord.id }}"
          routerLink="/exchange/request/{{ requestRecord.id }}">
        View the request.
      </a>

    </alert>

    <table class="table table-striped">
      <thead>
        <tr>
          <th> Owning Campus </th>
          <th> File Format </th>
          <th> Source </th>
          <th> Actions </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let holding of record.holding_list" class="list-group">
          <td> {{ holding.campus_str }} </td>
          <td> {{ holding.file_format_str }} </td>
          <td> {{ holding.source_str }} </td>
          <td class="pull-right">
            <span *ngIf="!isOwnedByUserCampus(holding)">

              <app-request-confirm-dialog
                [record]="record"
                [holding]="holding"
                (onRequestSuccessEmitter)="onRequestSuccess($event)"
                >
              </app-request-confirm-dialog>
            </span>

            <span *ngIf="isOwnedByUserCampus(holding)">
              Your Holding
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
