import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseCrudService } from '../base/base-crud.service';
import { CampusSystem } from './campus-system';


@Injectable()
export class CampusSystemService extends BaseCrudService<CampusSystem> {

  endpointUrl = `${this.apiHost}/api/campus-systems`;

  constructor(protected http: HttpClient) {
    super(http);
  }
}
