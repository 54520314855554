<main>

<h1>My Holdings</h1>

<app-list-alert [objectName]="'holding'"
  [itemCreated]="itemCreated"
  [itemUpdated]="itemUpdated"
  [itemDeleted]="itemDeleted"
  >
</app-list-alert>

<alert type="success" *ngIf="newPublicHolding"
    [dismissible]="true" [dismissOnTimeout]="10000"
    (onClosed)="newPublicHolding = undefined"
    >
    <strong>Holding is now publically available to other campuses:</strong>
    {{ newPublicHolding.book_str | slice:0:70 }}...
    ({{ newPublicHolding.file_format_str }})
    ({{ newPublicHolding.source_str }})
</alert>

<alert type="success" *ngIf="newPublicCount > 0"
    [dismissible]="true" [dismissOnTimeout]="10000"
    (onClosed)="newPublicHolding = 0"
    >
    <strong>
      All holdings are now publically available to other campuses.
    </strong>
</alert>


<mat-card id="book-holding-summary">

  <p>
    Your campus has {{ totalCampusHoldings }} holdings for
    {{ totalCampusBooks }} books.

    <span *ngIf="hasPrivateHoldings()">
    There are {{ holdingSummary.public_count }} public holdings, and
    {{ holdingSummary.private_count }} private holdings.
    </span>
  </p>

  <form id="list-controls">

    <app-list-filter (onFilterEmitter)="onFilter($event)">
    </app-list-filter>

    <span *ngIf="hasPrivateHoldings()">
      <mat-slide-toggle
        id="filter-public"
        value="Public"
        [checked]="filter['public']"
        [disabled]="!filter['private']"
        color="primary"
        (change)="updateFilter('public')"
        >
        Public Holdings
      </mat-slide-toggle>

      <mat-slide-toggle
        id="filter-private"
        value="Private"
        [checked]="filter['private']"
        [disabled]="!filter['public']"
        color="primary"
        (change)="updateFilter('private')"
        >
        Private Holdings
      </mat-slide-toggle>

      <app-holding-book-all-public-dialog
        *ngIf="showAdmin"
        (onMakeAllPublicSuccessEmitter)="onMakeAllPublicSuccess($event)"
        >
      </app-holding-book-all-public-dialog>
    </span>

  </form>

</mat-card>


<div class="panel">
  <div class="row">

    <table id="table-holding-book-list" class="table table-hover">
      <thead>
        <tr>
          <th class="holding-table-book-col">Book</th>
          <th>File Format</th>
          <th>Source</th>
          <th>Updated</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of results">
          <td class="holding-table-book-col">{{ result.book_str }}</td>
          <td>{{ result.file_format_str }}</td>
          <td>{{ result.source_str }}</td>
          <td>{{ result.modified_date | date: 'mediumDate' }}</td>

          <td>
            <app-holding-book-delete-dialog
              (onDeleteSuccessEmitter)="onItemDelete($event)"
              [record]="result"
              >
            </app-holding-book-delete-dialog>

            <button *ngIf="!result.is_public" mat-raised-button color="primary"
              (click)="makePublic(result)"
              >
              Make Public
            </button>
          </td>
        </tr>
      </tbody>
    </table>


    <app-list-paginator [apiUrl]="'/holding/book'"
      [nextUrl]="next" [previousUrl]="previous"
      [currentPage]="currentPage"
      (onPagingEmitter)="gotoPage($event)"
      >
    </app-list-paginator>

  </div>
</div>

</main>
