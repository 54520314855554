import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../auth/session.service';


@Component({
  selector: 'app-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.css']
})
export class MainNavbarComponent implements OnInit {

  constructor(
    // Made public so sessionService is accessible from template.
    public sessionService: SessionService,
  ) { }

  ngOnInit() {
  }
}
