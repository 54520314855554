import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot }                     from '@angular/router';

import { RedirectService } from '../../core/redirect.service';
import { SessionService } from '../session.service';


@Injectable()
export class HomeRedirectGuardService  {

  constructor(
    private sessionService: SessionService,
    private redirectService: RedirectService,
  ) { }

  /** If user is authenticated, disallow, and redirect to home page.
   *  Allows browsing to 'aimhub.org' to automatically show the home page,
   *  or the front page, depending on authentication state.
   **/
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this.sessionService.isValidSession()) {
      return true;
    }

    this.redirectService.redirectHome();
    return false;
  }
}
