import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseCrudService } from '../base/base-crud.service';
import { ExchangeRequestStatus } from './exchange-request-status';


@Injectable()
export class ExchangeRequestStatusService extends BaseCrudService<ExchangeRequestStatus> {

  endpointUrl = `${this.apiHost}/api/exchange/requests/status`;

  constructor(protected http: HttpClient) {
    super(http);
  }
}
