import { Component, OnInit } from '@angular/core';
import { AppLogger } from './core/logger';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  protected logger = new AppLogger();

  constructor() { }

  ngOnInit() {
    this.logger.info('Application Loading');
  }

}
