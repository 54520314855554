import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UserService } from '../../user/user.service';
import { ExchangeRequestService } from '../../exchange-request/exchange-request.service';

import { ExchangeRequest } from '../../exchange-request/exchange-request';

import { Book } from '../../book/book';
import { HoldingBook } from '../../holding-book/holding-book';


@Component({
  selector: 'app-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: ['./search-result-item.component.css']
})
export class SearchResultItemComponent implements OnInit {

  @Input() record: Book;
  @Input() requestRecord: ExchangeRequest;

  @Output() onDoneClickedEmitter = new EventEmitter<Book>();

  user: any;
  success = false;
  newRequestId = '';

  constructor(
    protected requestService: ExchangeRequestService,
    protected userService: UserService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.userService.getProfile()
      .then(userProfile => this.user = userProfile);
  }

  isOwnedByUserCampus(holding: HoldingBook): boolean {
    if (!this.user) {
      return false;
    }

    // Can't request holding from own campus.
    if (holding.campus !== this.user.campus) {
      return false;
    }

    return true;
  }

  // Sent to parent
  doneClicked() {
    this.onDoneClickedEmitter.emit(this.record);
  }

  onRequestSuccess(record: ExchangeRequest) {
    this.success = true;
    this.requestRecord = record;
  }

  handleServerErrors(err: any) {
    if (err.status === 400) {
      console.log(`SearchItemComponent Error: ${err}`);
    }
  }
}
