import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Campus } from '../campus';

import { CampusProfileService } from '../campus-profile.service';
import { UserProfileService } from '../../user/user-profile.service';


/**
 * Campus profile view, bound to user's campus.
 */
@Component({
  selector: 'app-campus-profile',
  templateUrl: './campus-profile.component.html',
  styleUrls: ['./campus-profile.component.css']
})
export class CampusProfileComponent implements OnInit {

  viewTitle = 'My Campus - AIMHub';

  record: Campus;
  success = false;
  userRole = 'Campus User';

  readOnlyForm = true;
  recordReadOnly = [
    'name',
    'city',
    'state',
    'postal_code',
    'campus_system',
  ]

  constructor(
    protected titleService: Title,
    protected dataService: CampusProfileService,
    protected userProfileService: UserProfileService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle( this.viewTitle );

    this.dataService.read()
      .then(campusProfile => this.record = campusProfile)
      .then(() => this.userProfileService.read())
      .then(user => this.userRole = user.role)
      .then(() => this.setReadOnly());
  }

  setReadOnly(): void {
    this.readOnlyForm = true;
    if (this.userCanUpdate()) {
      this.readOnlyForm = false;
    }
  }

  userCanUpdate(): boolean {
    const allowedRoles = [
      'Campus Administrator',
      'System Administrator',
    ];

    return allowedRoles.indexOf(this.userRole) >= 0;
  }

}
