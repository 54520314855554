<div id="new-book-list" *ngIf="bookList.length > 0">
  <ol *ngFor="let book of bookList" class="list-group">

    <li class="row list-group-item">
      <app-batch-isbn-list-item
        [book]="book"
        [formatList]="formatList"
        [sourceList]="sourceList"
        (onDoneClickedEmitter)="onDoneClicked($event)"
        >
      </app-batch-isbn-list-item>
    </li>

  </ol>
</div>
