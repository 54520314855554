import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthToken } from '../auth/auth-token';
import { BaseCrudService } from '../base/base-crud.service';
import { Campus } from './campus';


@Injectable()
export class CampusService extends BaseCrudService<Campus> {

  endpointUrl = `${this.apiHost}/api/campuses`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  getCampusStatistics() {
    const apiUrl = this.endpointUrl + '/statistics/';

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig)
      .toPromise()
      // .then(response => response)
      .catch(this.handleError);
  }
}
