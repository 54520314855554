import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FileFormat } from '../../file-format/file-format'
import { HoldingBookSource } from '../../holding-book-source/holding-book-source'
import { HoldingBook } from '../../holding-book/holding-book';


@Component({
  selector: 'app-format-list',
  templateUrl: './format-list.component.html',
  styleUrls: ['./format-list.component.css']
})
export class FormatListComponent implements OnInit {

  @Input() holdingList: HoldingBook[];
  @Input() formatList: FileFormat[];
  @Input() sourceList: HoldingBookSource[];

  @Output() onDeleteClickedEmitter = new EventEmitter<HoldingBook>();
  @Output() onSourceChangedEmitter = new EventEmitter<HoldingBook>();

  constructor() { }

  ngOnInit() {
  }

  deleteClicked(holding: HoldingBook) {
    this.onDeleteClickedEmitter.emit(holding);
  }

  sourceChanged(holding: HoldingBook, $event) {
    holding.source = $event.value;
    this.onSourceChangedEmitter.emit(holding);
  }
}
