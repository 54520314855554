<main>

<h1>Holding Results</h1>


<div id="result-summary" class="panel panel-default">

  <div class="panel-body">

    <span *ngIf="results">
      Matched {{ count }} books.
    </span>

    <span *ngIf="count === 0">
      No holdings matched your search query.
    </span>

    <span *ngIf="failed">
      {{ failedMessage }}
    </span>

    <span *ngIf="searching">
      Searching...
      <mat-progress-bar mode="query" color="accent"></mat-progress-bar>
    </span>

  </div>

</div>

<div id="result-list" class="row" *ngIf="results">
  <ul *ngFor="let record of results">
    <li class="row list-group-item">
      <app-search-result-item
        [record]="record"
        >
      </app-search-result-item>
    </li>
  </ul>
</div>

    <app-list-paginator [apiUrl]="'/search'"
      [queryParams]="{ q: searchQuery }"
      [nextUrl]="next" [previousUrl]="previous"
      [currentPage]="currentPage"
      (onPagingEmitter)="gotoPage($event)"
      >
    </app-list-paginator>

</main>
