import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';

import { ExchangeRequestLog } from '../exchange-request-log';
import { ExchangeRequestLogService } from '../exchange-request-log.service';


@Component({
  selector: 'app-exchange-request-log-list',
  templateUrl: './exchange-request-log-list.component.html',
  styleUrls: ['./exchange-request-log-list.component.css']
})
export class ExchangeRequestLogListComponent {

  @Input() requestId: string;
  @Input() logList: ExchangeRequestLog[];
  @Output() onSubmitClickedEmitter = new EventEmitter<ExchangeRequestLog>();
  @Output() onScrollEmitter = new EventEmitter<number>();
  @ViewChild('newLogField') public newLogField = '';

  newLogMessage = '';

  constructor(
    protected dataService: ExchangeRequestLogService
  ) { }

  create() {
    const data = new ExchangeRequestLog('', this.requestId, this.newLogMessage);
    this.dataService.create(data)
      .then(response => {
        this.onSubmitClickedEmitter.emit(response);
      });
  }

  onSubmitClicked() {
    this.create();
    this.newLogMessage = '';
  }

  onScroll () {
    let page = this.logList.length / 25;
    this.onScrollEmitter.emit(page);
  }
}
