import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { ReportsService } from './reports.service';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportCampusSystemComponent } from './campus-system/report.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReportsRoutingModule,
  ],
  exports: [
    ReportCampusSystemComponent,
  ],
  declarations: [
    ReportCampusSystemComponent,
  ],
  providers: [
    ReportsService,
  ],
})
export class ReportsModule { }
