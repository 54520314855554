import { Component } from '@angular/core';


@Component({
  selector: 'app-exchange-request-out-list',
  templateUrl: './exchange-request-out-list.component.html',
  styleUrls: ['./exchange-request-out-list.component.css']
})
export class ExchangeRequestOutListComponent {
  baseUrl = '/exchange/request/out/';
  apiUrl = 'api/exchange/requests/out/';
  viewTitle = 'Requests Out - AIMHub';
}
