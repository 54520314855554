import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { FileFormatService } from './file-format.service';
import { FileFormatRoutingModule } from './file-format-routing.module';

import { FileFormatListComponent } from './file-format-list/file-format-list.component';
import { FileFormatCardComponent } from './file-format-card.component';
import { FileFormatFieldsComponent } from './file-format-fields/file-format-fields.component';
import { FileFormatFormComponent } from './file-format-form/file-format-form.component';
import {
  FileFormatDialogComponent, FileFormatDialogContentComponent
} from './file-format-dialog/file-format-dialog.component';
import {
  FileFormatDeleteDialogComponent, FileFormatDeleteDialogContentComponent
} from './file-format-delete-dialog/file-format-delete-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FileFormatRoutingModule,
  ],
  exports: [
    FileFormatListComponent,
    FileFormatCardComponent,
    FileFormatFieldsComponent,
    FileFormatDialogComponent,
    FileFormatDialogContentComponent,
  ],
  declarations: [
    FileFormatListComponent,
    FileFormatCardComponent,
    FileFormatFieldsComponent,
    FileFormatFormComponent,
    FileFormatDialogComponent,
    FileFormatDialogContentComponent,
    FileFormatDeleteDialogComponent,
    FileFormatDeleteDialogContentComponent,
  ],
  providers: [
    FileFormatService,
  ],
})
export class FileFormatModule { }
