import { Component, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-list-filter',
  template: `
    <mat-form-field>
      <input #filterText matInput placeholder="Enter filter criteria"
        (keyup.enter)="onFilterEmitter.emit(filterText.value)"
        >
    </mat-form-field>

    <button mat-raised-button
        id="filter-button" type="button"
        (click)="onFilterEmitter.emit(filterText.value)"
        >
      Filter <mat-icon>filter_list</mat-icon>
    </button>

    <button mat-raised-button
        id="reset-button" type="button"
        (click)="onFilterEmitter.emit(); filterText.value = ''"
        >
      Reset <mat-icon>undo</mat-icon>
    </button>
             `,
})
export class ListFilterComponent {

  @Output() onFilterEmitter = new EventEmitter<string>();

  constructor() { }
}
