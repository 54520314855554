import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { BatchIsbnComponent } from './batch-isbn.component';


const routes: Routes = [
  {
    path: 'holding/book/create',
    component: BatchIsbnComponent,
    canActivate: [AuthGuardService],
    title: 'Create Book Holding' // Add a title for the book creation page
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BatchIsbnRoutingModule {}
