import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { ExchangeRequestLogService } from './exchange-request-log.service';
import { ExchangeRequestLogRoutingModule } from './exchange-request-log-routing.module';
import { ExchangeRequestLogListComponent } from './exchange-request-log-list/exchange-request-log-list.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ExchangeRequestLogRoutingModule,
        InfiniteScrollModule,
    ],
  exports: [
    ExchangeRequestLogListComponent,
  ],
  declarations: [
    ExchangeRequestLogListComponent,
  ],
  providers: [
    ExchangeRequestLogService,
  ],
})
export class ExchangeRequestLogModule { }
