import { HttpErrorResponse } from '@angular/common/http';
import {Injectable, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AppInjector } from '../app-injector.service';
import { IServiceModel } from './service.interface';
import { BaseCrudService } from './base-crud.service';
import { RedirectService } from '../core/redirect.service';

@Injectable()  // <-- Add this decorator
export class BaseListComponent<T extends IServiceModel> implements OnInit {
  public viewTitle = 'AIMHub';

  // URLs
  public baseUrl: string;
  public apiUrl: string;

  // Page numbers
  public defaultPage = 1;
  public currentPage = 1;

  public apiQueryParams = { 'page': this.currentPage };

  // Page links
  public previous: string;
  public next: string;

  // Items
  public results: T[];
  public count: number;

  // Action status
  public notifyTimeout = 10000;
  public itemCreated = false;
  public itemUpdated = false;
  public itemDeleted = false;

  // Injected dependencies (in place of constructor arguments).
  protected titleService: Title;
  protected router: Router;
  protected route: ActivatedRoute;
  protected redirectService: RedirectService;

  public constructor(
    protected dataService: BaseCrudService<T>
  ) {
    const injector = AppInjector.getInjector();
    this.titleService = injector.get(Title);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.redirectService = injector.get(RedirectService);
  }

  /* Set the title, parse parameters, and list records.
   */
  public ngOnInit(): void {
    this.titleService.setTitle( this.viewTitle );
    this.parseQueryParams();
    this.list();
  }

  /* Get query parameters out of the URL.
   */
  protected parseQueryParams() {
    this.route.queryParams.forEach((params: Params) => {
      this.initPageQueryParam(params);
      this.initFilterQueryParam(params);
    });
  }

  /* Special handling for the 'page' query parameter.
   * Update the current page, if one is specified.
   */
  protected initPageQueryParam(params: Params): void {
    if (params.hasOwnProperty('page')) {
      const page = parseInt(params['page'], 10);
      this.updateCurrentPage(page);
    }
  }

  /* Special handling for the 'search' query parameter.
   */
  protected initFilterQueryParam(params: Params): void {
    if (params.hasOwnProperty('search')) {
      const filter = params['search'];
      this.onFilter(filter);
    }
  }

  /* Switch pages.
   */
  public gotoPage(page = 1): void {
    this.updateCurrentPage(page);
    this.list();
  }

  /* Refresh all page counts.
    */
  protected updateCurrentPage(page = 1) {
    this.apiQueryParams['page'] = page;
    this.currentPage = page;
  }

  /* Filter records by text string. Reset page to default.
   */
  onFilter(value = ''): void {
    this.apiQueryParams['search'] = value;
    this.apiQueryParams['page'] = this.defaultPage;
    this.list();
  }

  /* Show success notification when item is created.
   */
  onItemCreate(record: T): void {
    this.itemCreated = true;
    setTimeout(() => this.itemCreated = false, this.notifyTimeout);
    this.list();
  }

  /* Show success notification when item is updated.
   */
  onItemUpdate(record: T): void {
    this.itemUpdated = true;
    setTimeout(() => this.itemUpdated = false, this.notifyTimeout);
    this.list();
  }

  /* Show success notification when item is deleted.
   */
  onItemDelete(record: T): void {
    this.itemDeleted = true;
    setTimeout(() => this.itemDeleted = false, this.notifyTimeout);
    this.list();
  }

  /* Get a list of specified records from the API.
   */
  protected list(params = this.apiQueryParams, apiUrl?): Promise<any> {
    const page = params['page'];

    if (!page) {
      this.redirectService.redirectNotFound();
      return Promise.reject(new Error('Page not found'));
    }

    if (!apiUrl && this.apiUrl) {
      apiUrl = this.apiUrl;
    }

    return this.dataService.listMeta(params, apiUrl)
      // Copy the JSON properties to this component
      .then(listData => Object.assign(this, listData))
      .catch(err => this.handleError(err));
  }

  /* Delete a record.
   */
  protected delete(record: T): void {
    this.dataService
      .delete(record.id)
      .then(() => this.list())
      .catch(err => this.handleError(err));
  }

  /* Handle data service errors from the API.
   */
  protected handleError(error: HttpErrorResponse): void {
    // @TODO: Improve logging.
    console.error('BaseListComponent error: ', error);
    if (error.status === 404) {
      this.redirectService.redirectNotFound();
    }
  }
}
