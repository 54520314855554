<mat-spinner *ngIf="!formComposite"></mat-spinner>

<form *ngIf="formComposite" [formGroup]="formComposite"
  class="column-form-container" novalidate
  >

  <app-form-alert
    [record]="record" objectName="campus system"
    [formComposite]="formComposite" [success]="success"
    >
  </app-form-alert>


  <div formGroupName="record" class="column-form-container">
    <app-campus-system-fields
        [formGroup]="formComposite.controls.record"
        [readOnlyForm]="readOnlyForm"
        [readOnlyList]="recordReadOnly"
        >
    </app-campus-system-fields>
  </div>


  <app-form-buttons
    [formComposite]="formComposite"
    [submitting]="submitting"
    [readOnlyForm]="readOnlyForm"
    [showClose]="showClose" [showBack]="showBack"
    (onSubmitClickedEmitter)="onSubmit()"
    (onResetClickedEmitter)="onReset()"
    (onCloseClickedEmitter)="onClose()"
    >
  </app-form-buttons>

</form>
