import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ExchangeRequestFile } from '../exchange-request-file';
import { ExchangeRequestFileService } from '../exchange-request-file.service';


@Component({
  selector: 'app-exchange-request-file-list',
  templateUrl: './exchange-request-file-list.component.html',
  styleUrls: ['./exchange-request-file-list.component.css']
})
export class ExchangeRequestFileListComponent implements OnInit {

  @Input() requestId: string;
  @Input() showUpload = true;
  @Output() onUploadClickedEmitter = new EventEmitter<ExchangeRequestFile>();
  @Output() onDeleteClickedEmitter = new EventEmitter<ExchangeRequestFile>();

  fileList: ExchangeRequestFile[];
  deleting = false;

  fetching = true;
  failed = false;
  failedMessage = '';

  constructor(
    protected dataService: ExchangeRequestFileService,
  ) { }

  ngOnInit(): void {
    this.resetError();
    this.list();
  }

  onUploadClicked(file: any) {
    this.list();
    this.onUploadClickedEmitter.emit(file);
  }

  list() {
    this.dataService.listRaw(this.requestId)
      .then(response => this.fileList = response['results'])
      .catch(err => this.handleError(err))
      .then(() => this.fetching = false)
  }

  onDeleteClicked(file: ExchangeRequestFile) {
    this.deleting = true;
    this.dataService.delete_file(file.request, file.id)
      .then(response => {
        this.list();
        this.onDeleteClickedEmitter.emit(file);
      })
      .catch(this.handleError)
      .then(() => this.deleting = false);
  }

  resetError(): void {
    this.failed = false;
    this.failedMessage = '';
  }

  handleError(error: any): void {
    // @TODO: Improve logging.
    console.log('RequestFileList error: ', error);
    this.failed = true;
    this.failedMessage = error['detail'];
  }
}
