import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseCrudService } from '../base/base-crud.service';
import { HoldingBookSource } from './holding-book-source';


@Injectable()
export class HoldingBookSourceService extends BaseCrudService<HoldingBookSource> {

  endpointUrl = `${this.apiHost}/api/holdings/books/sources`;

  constructor(protected http: HttpClient) {
    super(http);
  }
}
