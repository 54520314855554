import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthToken } from '../auth/auth-token';
import { BaseCrudService } from '../base/base-crud.service';
import { Book } from './book';


@Injectable()
export class BookService extends BaseCrudService<Book> {

  endpointUrl = `${this.apiHost}/api/books`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  createBatchIsbn(isbnList: string[]): Promise<any> {
    const apiUrl = `${this.endpointUrl}/batch-isbn/`;
    const data = { 'isbn_list': isbnList };

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.post(apiUrl, data, httpConfig).toPromise();
  }

  getCampusAutofillCount(): Promise<any> {
    const apiUrl = `${this.endpointUrl}/autofill-count/`;

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig).toPromise();
  }
}
