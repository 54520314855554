<span [formGroup]="formGroup">

  <div class="form-column">

    <mat-form-field>
      <input matInput placeholder="Format Name"
                      formControlName="name" required
                      [readonly]="isReadOnly('name')">

      <mat-error *ngIf="formGroup.controls.name.hasError('backend')">
        {{ formGroup.controls['name'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.name.hasError('required')">
        Format name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="File Extension"
                      formControlName="extension" required
                      [readonly]="isReadOnly('extension')">

      <mat-error *ngIf="formGroup.controls.extension.hasError('backend')">
        {{ formGroup.controls['extension'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.extension.hasError('required')">
        File extension is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-slide-toggle class="format-toggle"
        formControlName="for_holdings"
        >
      Format can be used as holding type.
    </mat-slide-toggle>

  </div>

</span>

