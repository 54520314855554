import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { ExchangeRequestFile } from '../exchange-request-file';
import { ExchangeRequestFileService } from '../exchange-request-file.service';


@Component({
  selector: 'app-file-delete-modal',
  templateUrl: './file-delete-modal.component.html',
  styleUrls: ['./file-delete-modal.component.css']
})
export class FileDeleteModalComponent implements OnInit {

  @Input() record: ExchangeRequestFile;
  @Input() buttonText = 'Delete';
  @Input() buttonStyle = 'btn-danger';

  @Output() onSubmitClickedEmitter = new EventEmitter<ExchangeRequestFile>();
  @Output() onCloseClickedEmitter = new EventEmitter<ExchangeRequestFile>();
  @ViewChild('fileDeleteModal') public fileDeleteModal: ModalDirective;

  constructor(
    protected dataService: ExchangeRequestFileService,
  ) { }

  ngOnInit() {
  }

  onSubmitClicked() {
    this.fileDeleteModal.hide();
    this.onSubmitClickedEmitter.emit(this.record);
  }

  onCloseClicked() {
    this.fileDeleteModal.hide();
    this.onCloseClickedEmitter.emit();
  }
}
