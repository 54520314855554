<main>

<h1>File Formats</h1>

<app-list-alert [objectName]="'file-format'"
  [itemCreated]="itemCreated"
  [itemUpdated]="itemUpdated"
  [itemDeleted]="itemDeleted"
  >
</app-list-alert>


<form id="list-controls">
  <app-list-filter (onFilterEmitter)="onFilter($event)">
  </app-list-filter>

  <app-file-format-dialog buttonColor="primary"
    (onSubmitSuccessEmitter)="onItemCreate($event)"
    >
  </app-file-format-dialog>
</form>


<div class="panel">
  <div class="row">

    <table id="table-file-format-list" class="table table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Extension</th>
          <th>For Holdings</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of results">
          <td>{{ result.name }}</td>
          <td>{{ result.extension }}</td>
          <td>{{ result.for_holdings }}</td>

          <td>
            <app-file-format-dialog buttonColor="primary"
              (onSubmitSuccessEmitter)="onItemUpdate($event)"
              [recordId]="result.id"
              >
            </app-file-format-dialog>

            <app-file-format-delete-dialog *ngIf="userRole == 'System Administrator'"
              (onDeleteSuccessEmitter)="onItemDelete($event)"
              [record]="result"
              >
            </app-file-format-delete-dialog>
          </td>

        </tr>
      </tbody>
    </table>

    <app-list-paginator [apiUrl]="'/file-format'"
      [nextUrl]="next" [previousUrl]="previous"
      [currentPage]="currentPage"
      (onPagingEmitter)="gotoPage($event)"
      >
    </app-list-paginator>

  </div>
</div>

</main>
