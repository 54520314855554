import { Component, Input } from '@angular/core';

import { CampusSystem } from './campus-system';


@Component({
  selector: 'app-campus-system-card',
  template: `
    <div id="campus-system-{{ record.id }}">
      <app-card-field [uniqueId]="record.id" [labelText]="'Name'" [fieldName]="'Name'">
        {{ record.name }}
      </app-card-field>
    </div>
    `,
})
export class CampusSystemCardComponent {

  @Input() record: CampusSystem;

  constructor() { }
}
