import { Component, OnInit, Input } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {from, Observable} from 'rxjs';
import {startWith, mergeMap} from 'rxjs/operators';

import { isValidUUID } from '../../shared/validator/invalid-uuid';

import { Campus } from '../campus';
import { CampusService } from '../campus.service';


@Component({
  selector: 'app-campus-autocomplete-field',
  templateUrl: './campus-autocomplete-field.component.html',
  styleUrls: ['./campus-autocomplete-field.component.css']
})
export class CampusAutocompleteFieldComponent implements OnInit {
  // Inputs for binding to parent form's control.
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormControlName: string;

  // Inputs for self control.
  @Input() myControl: FormControl;

  // Other inputs.
  @Input() readOnly = false;
  @Input() required = false;

  // Filtered campus systems.
  filteredOptions: Observable<Campus[]>;

  public constructor(
    public dataService: CampusService,
  ) { }

  ngOnInit() {
    this._bindFormControl();
    this._patchFormgroupGetSet();
    this._applyOptionFilter();
  }

  public onNewRecord(record: Campus): void {
    this.myControl.setValue(record);
  }

  /* Initialize the FormControl object with a passed instance, or a new instance.
   */
  private _bindFormControl(): void {

    // Init with FormControl in parent FormGroup.
    if (this.parentFormControlName && this.parentFormGroup) {
      const name = this.parentFormControlName;
      this.myControl = <FormControl>this.parentFormGroup.controls[name];

    // Init with new FormControl.
    } else if (!this.myControl) {
      this.myControl = new FormControl();
    }
  }

  /* The MatAutocomplete class sets/gets whole objects.
   * This monkey patches the parent FormGroup, so that the campus
   * FormControl only returns the campus `id`, instead of the whole object.
   * @TODO: Find resolution to issues, giving an official way to return `id`.
   * - https://github.com/angular/material2/issues/8436
   * - https://github.com/angular/material2/issues/9293
   */
  private _patchFormgroupGetSet() {
    console.assert(
      this.myControl !== undefined,
      'FormControl is not bound. Call this._bindFormControl?'
    );
    const fg = this.myControl.parent;

    if (fg) {

      // Attach isValidUUID reference to the parent FormGroup.
      // Parent FormGroup tests if value is UUID. Calls API for record object if so.
      Object.defineProperty(fg, 'isValidUUID', { value: isValidUUID });

      // Attach dataService reference to the parent FormGroup.
      // Parent FormGroup calls API via dataService for record object.
      Object.defineProperty(fg, 'dataService', { value: this.dataService });

      // Override parent FormGroup's `value` get()/set() methods.
      Object.defineProperty(fg, 'value', {
        /* Override get() for FormGroup.value.
         * If the 'campus' FormControl value is an object, set by matAutocomplete,
         * then return the `id` property.
         */
        get() {
          let r = {};
          for (let c in this.controls) {
            r[c] = this.controls[c].value;
            // Campus control should be assigned the campus `id`.
            if (c === 'campus' && r[c]) {
              const pubId = r[c];
              r[c] = pubId.hasOwnProperty('id') ? r[c].id : r[c];
            }
          }
          return r;
        },
        /* Override set() for FormGroup.value.
         * If the passed Campus `campus` property is a UUID,
         * then call the API to get the record, and set it as the value.
         * @param {Object|undefined} - Book object passed to the FormGroup.
         */
        set(fgValue: Object | undefined) {
          if (!fgValue) {
            return;
          }

          if (fgValue.hasOwnProperty('campus') && fgValue['campus']) {
            if (isValidUUID(fgValue['campus'])) {
              const recordId = fgValue['campus'];
              this.dataService.read(recordId)
                .then(record => {
                  // Patch the campus ID value with the full object.
                  this.patchValue({campus: record});
                });
            }
          }
        }
      });
    }
  }

  /* Apply the autocomplete filter to the FormControl.
   * The filter will run each time the control's value changes.
   */
  private _applyOptionFilter(): void {
    try {
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        mergeMap((token: string) => this._getRecordListAsObservable(token)),
      );
    } catch (e) {
      if (e instanceof ReferenceError) {
        console.log('No Angular FormControl binding within CampusAutoCompleteFieldComponent.');
        console.log(e);
      }
    }
  }

  /* Obtain autocomplete results as an Observable.
   * @param {string} searchTerm - The partial autocomplete string to look for.
   * @return {Observable<any>} - An Observable of search results.
   */
  private _getRecordListAsObservable(searchTerm: string): Observable<any> {
    return from(
      this._getRecordsListAsPromise(searchTerm)
    );
  }

  /* Obtain autocomplete results as a Promise. Calls remote API endpoint.
   * @param {string} searchTerm - The partial autocomplete string to look for.
   * @return {Promise<Campus[]>} - The list of matching autocomplete results.
   */
  private _getRecordsListAsPromise(searchTerm: string): Promise<Campus[]> {

    /* If the autocomplete search term is not a string, or is a UUID,
     * then return an empty list in the Promise.
     */
    if (typeof searchTerm !== 'string' || isValidUUID(searchTerm)) {
      return Promise.resolve([]);
    }

    return this.dataService.autocomplete(searchTerm)
      .then(listData => listData.results);
  }

  /* Determine which property to display in the autocomplete input field.
   * Used as the parameter to `matAutocomplete.displayWith`.
   * @param {Campus} record - The campus object to get a
   *                                display value from.
   * @return {string} - The form field display value.
   */
  public displayValue(record?: Campus): string {
    if (record) {
      return record.name;
    } else if (!this.myControl) {
      return '';
    } else if (!this.myControl.value) {
      return '';
    } else if (!this.myControl.value.hasOwnProperty('name')) {
      return '';
    } else {
      return this.myControl.value['name'];
    }
  }
}
