<mat-card class="password-reset-card">

  <mat-card-title> Password Reset </mat-card-title>

  <mat-card-content>

    <div id="success-notification" class="alert alert-success" *ngIf="success">
      Password reset e-mail has been sent.
    </div>
    <div id="failed-notification" class="alert alert-danger"
         *ngIf="failed && failedNotification">
      {{ failedNotification }}
    </div>

    <form [formGroup]="passwordResetForm" *ngIf="active" (ngSubmit)="onSubmit()">

      <mat-form-field class="password-reset-field">
        <input matInput placeholder="Email"
            type="email"
            formControlName="email"
            required
            >
        <mat-error *ngIf="passwordResetForm.controls.email.hasError('backend')">
          {{ passwordResetForm.controls['email'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="passwordResetForm.controls.email.hasError('required')">
          Email address is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="passwordResetForm.controls.email.hasError('email')
                      && !passwordResetForm.controls.email.hasError('required')">
          Email address is <strong>invalid</strong>
        </mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit"
          [disabled]="!passwordResetForm.valid || submitting"
          >
        Send Reset Email
      </button>

    </form>

  </mat-card-content>

</mat-card>
