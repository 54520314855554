<main>

<h1> My Campus Profile </h1>

<mat-card>
  <mat-card-content *ngIf="record !== undefined">

    <app-campus-profile-form [injectedRecordId]="record.id"
      [recordReadOnly]="recordReadOnly"
      [readOnlyForm]="readOnlyForm"
      >
    </app-campus-profile-form>

  </mat-card-content>

  <mat-card-content *ngIf="record === undefined">
    Loading campus profile...
    <mat-spinner></mat-spinner>
  </mat-card-content>
</mat-card>

</main>
