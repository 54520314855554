<main>

<h1>Campus Systems</h1>

<app-list-alert [objectName]="'campus system'"
  [itemCreated]="itemCreated"
  [itemUpdated]="itemUpdated"
  [itemDeleted]="itemDeleted"
  >
</app-list-alert>


<form id="list-controls">
  <app-list-filter (onFilterEmitter)="onFilter($event)">
  </app-list-filter>

  <app-campus-system-dialog buttonColor="primary"
    (onSubmitSuccessEmitter)="onItemCreate($event)"
    >
  </app-campus-system-dialog>
</form>

<div class="panel">
  <div class="row">

    <table id="table-campus-system-list" class="table table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Joined</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of results">
          <td>
            <a id="link-campussystem-{{ result.id }}"
                [routerLink]="['/campus-system/detail/', result.id]">
              {{ result.name }}
            </a>
          </td>
          <td>{{ result.created_date | date: 'medium' }}</td>

          <td>
            <a id="campus-system-detail-{{ result.id }}" mat-raised-button
                color="primary"
                routerLink="/campus-system/detail/{{ result.id }}"
                >
              <span>
                  Details<mat-icon>description</mat-icon>
              </span>
            </a>

            <app-campus-system-dialog buttonColor="primary"
              (onSubmitSuccessEmitter)="onItemUpdate($event)"
              [recordId]="result.id"
              >
            </app-campus-system-dialog>

            <app-campus-system-delete-dialog
              (onDeleteSuccessEmitter)="onItemDelete($event)"
              [record]="result"
              >
            </app-campus-system-delete-dialog>
          </td>

        </tr>
      </tbody>
    </table>

    <app-list-paginator [apiUrl]="'/campus-system'"
      [nextUrl]="next" [previousUrl]="previous"
      [currentPage]="currentPage"
      (onPagingEmitter)="gotoPage($event)"
      >
    </app-list-paginator>

  </div>
</div>

</main>
