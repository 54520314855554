<p>Test Harness</p>

  <app-publisher-autocomplete-field>
  </app-publisher-autocomplete-field>

  <app-campus-system-autocomplete-field>
  </app-campus-system-autocomplete-field>

<form>
    <app-book-dialog>
  </app-book-dialog>
</form>
