<div>

  <h3>Not Found</h3>

  <div class="alert alert-danger">
    <p> Oops. Whatever you were looking for couldn't be found. </p>
  </div>

  <button id="back-btn" (click)="goBack()" class="btn btn-default">
    Go Back
  </button>

</div>
