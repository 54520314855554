import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseCrudService } from '../base/base-crud.service';
import { ExchangeRequestLog } from './exchange-request-log';


@Injectable()
export class ExchangeRequestLogService extends BaseCrudService<ExchangeRequestLog> {

  endpointUrl = `${this.apiHost}/api/exchange/requests/logs`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  list(requestId = '') {
    const endpoint = `${this.endpointUrl}/${requestId}`;
    return super.list(endpoint);
  }

  listRaw(requestId = '', page = 1) {
    const endpoint = `${this.endpointUrl}/${requestId}?page=${page}`;
    return super.listRaw(endpoint);
  }
}
