<button mat-button [matMenuTriggerFor]="menuAdmin">
  <mat-icon>supervisor_account</mat-icon>
  Administration
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #menuAdmin="matMenu">

  <span role="presentation" class="dropdown-header">Campus Administration</span>
  <a mat-menu-item id="link-superuser-user-list"
      routerLink="/user" routerLinkActive="active">
    Users
  </a>
  <a mat-menu-item id="link-superuser-academic-term-list"
      routerLink="/academic-term" routerLinkActive="active">
    Academic Terms
  </a>

  <!--  if request.user.is_superuser  -->
  <span *ngIf="showFullAdmin" role="presentation" class="divider"></span>

  <span *ngIf="showFullAdmin" role="presentation" class="dropdown-header">
    System Administration
  </span>

  <a mat-menu-item id="link-superuser-reports" *ngIf="showFullAdmin"
      routerLink="/reports/campus-system" routerLinkActive="active">
    Reports
  </a>

  <a mat-menu-item id="link-superuser-campus-list" *ngIf="showFullAdmin"
      routerLink="/campus" routerLinkActive="active">
    Campuses
  </a>

  <a mat-menu-item id="link-superuser-campus-system-list" *ngIf="showFullAdmin"
      routerLink="/campus-system" routerLinkActive="active">
    Campus Systems
  </a>

  <a mat-menu-item id="link-superuser-holding-book-source-list" *ngIf="showFullAdmin"
      routerLink="/holding/book/source" routerLinkActive="active">
    Sources of Book Holdings
  </a>

  <a mat-menu-item id="link-superuser-file-format-list" *ngIf="showFullAdmin"
      routerLink="/file-format" routerLinkActive="active">
    File Formats
  </a>

</mat-menu>
