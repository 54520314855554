  <nav id="nav-primary" class="navbar navbar-default">
    <div class="container-fluid">

      <mat-toolbar *ngIf="!sessionService.isValidSession()">
        <mat-toolbar-row>
          <app-nav-home></app-nav-home>
        </mat-toolbar-row>
      </mat-toolbar>

      <div *ngIf="sessionService.isValidSession()">
        <app-auth-navbar></app-auth-navbar>
      </div>

    </div><!--/.container-fluid -->
  </nav>
