/**
 * Global injector service
 *
 * 1) Reduces tight coupling between parent classes and their children.
 * 2) Prevents excessive code duplication, which obfuscates relevant code.
 *
 * Angular uses dependency injection (DI) to provide services to other classes,
 * such as components. These services are passed as constructor arguments.
 * Child classes need to import the same dependencies, pass them through a matching
 * constructor signature, and then pass them to a `super()` call. This is too much.
 *
 * A global injector service instatiates services required for the parent class
 * within it's constructor, alleviating the need for child classes to do any of
 * the above.
 *
 * Can still be compatible with the standard DI pattern by making the parent class
 * constructor parameters still present, but optional. If a child class doesn't pass
 * an argument, then instatiate it with the global injector service.
 *
 * - https://blogs.msdn.microsoft.com/premier_developer/2018/06/17/angular-how-to-simplify-components-with-typescript-inheritance/
 * - https://stackoverflow.com/questions/53185211/angular-7-component-inheritance-using-a-global-injector/53185632#53185632
 */
import { Injector } from '@angular/core';


export class AppInjector {

  private static injector: Injector;

  static setInjector(injector: Injector) {
    AppInjector.injector = injector;
  }

  static getInjector(): Injector {
    return AppInjector.injector;
  }
}
