import { Component, OnInit } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';
import { UserService } from '../../user/user.service';
import { HoldingBookSource } from '../holding-book-source';
import { HoldingBookSourceService } from '../holding-book-source.service';


@Component({
  selector: 'app-holding-book-source-list',
  templateUrl: './holding-book-source-list.component.html',
  styleUrls: ['./holding-book-source-list.component.css']
})
export class HoldingBookSourceListComponent extends BaseListComponent<HoldingBookSource> implements OnInit {
  baseUrl = 'holding-book-source/';
  viewTitle = 'Holding Book Sources - AIMHub';

  userRole: string;

  constructor(
    protected dataService: HoldingBookSourceService,
    protected userService: UserService
  ) {
    super(dataService);
  }

  ngOnInit(): void {
    this.initUserRole();
    super.ngOnInit();
  }

  initUserRole() {
    this.userService.getRole()
      .then(role => this.userRole = role)
      .catch(err => this.handleError(err));
  }
}
