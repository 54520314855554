import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { CampusSystemService } from './campus-system.service';
import { CampusSystemRoutingModule } from './campus-system-routing.module';

import { CampusSystemCardComponent } from './campus-system-card.component';
import { CampusSystemAutocompleteFieldComponent } from './campus-system-autocomplete-field/campus-system-autocomplete-field.component';
import { CampusSystemListComponent } from './campus-system-list/campus-system-list.component';
import { CampusSystemFieldsComponent } from './campus-system-fields/campus-system-fields.component';
import { CampusSystemFormComponent } from './campus-system-form/campus-system-form.component';
import { CampusSystemDetailComponent } from './campus-system-detail/campus-system-detail.component';
import {
  CampusSystemDialogComponent, CampusSystemDialogContentComponent
} from './campus-system-dialog/campus-system-dialog.component';
import {
  CampusSystemDeleteDialogComponent, CampusSystemDeleteDialogContentComponent
} from './campus-system-delete-dialog/campus-system-delete-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CampusSystemRoutingModule,
  ],
  exports: [
    CampusSystemAutocompleteFieldComponent,
  ],
  declarations: [
    CampusSystemCardComponent,
    CampusSystemAutocompleteFieldComponent,
    CampusSystemListComponent,
    CampusSystemFieldsComponent,
    CampusSystemFormComponent,
    CampusSystemDetailComponent,
    CampusSystemDialogComponent,
    CampusSystemDialogContentComponent,
    CampusSystemDeleteDialogComponent,
    CampusSystemDeleteDialogContentComponent,
  ],
  providers: [
    CampusSystemService,
  ],
})
export class CampusSystemModule { }
