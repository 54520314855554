import { Component, Input } from '@angular/core';

import { HoldingBook } from './holding-book';


@Component({
  selector: 'app-holding-book-card',
  template: `
    <div id="holding-book-{{ record.id }}">

      <app-card-field [uniqueId]="record.id" [labelText]="'Book'" [fieldName]="'Book'">
        {{ record.book_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Format'" [fieldName]="'Format'">
        {{ record.file_format_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Source'" [fieldName]="'Source'">
        {{ record.source_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Campus'" [fieldName]="'Campus'">
        {{ record.campus_str }}
      </app-card-field>

    </div>
    `,
})
export class HoldingBookCardComponent {

  @Input() record: HoldingBook;

  constructor() { }
}
