<main>

<h1> My Account </h1>

<mat-card class="col-sm-5 profile-card">
  <mat-card-title>User Details</mat-card-title>
  <mat-card-content *ngIf="record !== undefined">

    <app-user-profile-form
      [injectedRecordId]="record.id"
      [recordReadOnly]="recordReadOnly"
      [readOnlyForm]="readOnlyForm"
      >
    </app-user-profile-form>

  </mat-card-content>

  <mat-card-content *ngIf="record === undefined">
    Loading user profile...
    <mat-spinner></mat-spinner>
  </mat-card-content>
</mat-card>


<mat-card class="col-sm-5 profile-card">
  <mat-card-title>Notification Preferences</mat-card-title>
  <mat-card-content *ngIf="record !== undefined">

    <app-user-preference-form [injectedRecordId]="record.id">
    </app-user-preference-form>

  </mat-card-content>

  <mat-card-content *ngIf="record === undefined">
    Loading user profile...
    <mat-spinner></mat-spinner>
  </mat-card-content>
</mat-card>

</main>
