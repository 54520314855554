<p> Select formats to add holdings for. </p>

<mat-form-field>
  <mat-select
      #formatSelect
      placeholder="Holding Format(s)"
      [multiple]="true"
      size="14"
      [(ngModel)]="selectedFormats"
      >
    <mat-option *ngFor="let format of formatList" [value]="format">
      {{ format.name }} ({{ format.extension }})
    </mat-option>
  </mat-select>
</mat-form-field>

<button mat-raised-button
    id="btn-holding-format-create"
    (click)="addFormatClicked()"
    [disabled]="!(selectedFormats && selectedFormats.length > 0)"
    >
  Add Formats
  <span class="glyphicon glyphicon-plus"></span>
</button>
