import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseFieldsComponent } from '../../base/base-fields.component';
import { CampusSystem } from '../campus-system';


/**
 * A set of form fields to manipulate a campus-system object.
 */
@Component({
  selector: 'app-campus-system-fields',
  templateUrl: './campus-system-fields.component.html',
  styleUrls: ['./campus-system-fields.component.css']
})
export class CampusSystemFieldsComponent extends BaseFieldsComponent {
}


/**
 * Static function that returns a campusSystem field formgroup.
 *
 * @param {CampusSystem} campusSystem - campusSystem object to initialize fields with.
 * @returns {FormGroup} intialized set of campusSystem form fields.
 */
export function initCampusSystemFormGroup(campusSystem: CampusSystem): FormGroup {
  // Build the form.
  const fb = new FormBuilder();
  const form = fb.group({
    'id': [campusSystem.id, [] ],
    'name': [campusSystem.name, [ Validators.required ] ],
  });

  return form;
}
