<div *ngIf="fetching">
  Fetching file list from cloud storage...
  <mat-progress-bar mode="query" color="accent"></mat-progress-bar>
</div>


<alert *ngIf="failed && !fetching" type="danger">
  {{ failedMessage }}
</alert>

<div *ngIf="!failed && !fetching">
  <div *ngIf="showUpload">
    <app-file-upload-modal
      [requestId]="requestId"
      (onSubmitClickedEmitter)="onUploadClicked($event)"
      >
    </app-file-upload-modal>
  </div>

  <div *ngIf="!fileList || fileList.length < 1">
    There are no files associated with this request.
  </div>

  <ul class="list-group" *ngFor="let file of fileList">
    <li class="list-group-item row">

      <span class="col-sm-8">
        <a href="{{ file.media_file }}">{{ file.media_file | fileName }}</a>
      </span>

      <span class="col-sm-2">
        {{ file.size | fileSize }}
      </span>

      <span class="col-sm-2">
        <app-file-delete-modal
          [record]="file"
          (onSubmitClickedEmitter)="onDeleteClicked($event)"
        >
        </app-file-delete-modal>
      </span>

    </li>
  </ul>
</div>

