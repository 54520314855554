<h2>Books</h2>

<div class="panel">
  <div class="row">

    <table id="table-book-list" class="table table-hover">
        <thead>
          <tr>
            <th>Title</th>
            <th>Author</th>
            <th>Edition</th>
            <th>ISBN</th>
            <th>Publisher</th>
            <th>Year</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let result of results">
            <td>
              <a id="link-book-{{ result.id }}" [routerLink]="['/book/detail', result.id]">
                {{ result.title }}
              </a>
            </td>
            <td>{{ result.authors }}</td>
            <td>{{ result.edition }}</td>
            <td>{{ result.isbn }}</td>
            <td>{{ result.publisher_str }}</td>
            <td>{{ result.copyright_year }}</td>
            <td>
              <button class="delete" (click)="delete(result);
                      $event.stopPropagation()">x</button>
            </td>
          </tr>

        </tbody>
    </table>

      <nav aria-label="Pagination control">
        <ul class="pagination">
          <li *ngIf="previous">
            <a [routerLink]="['/book']" [queryParams]="{ page: previousPage }"
                    (click)="gotoPage(previousPage)" aria-label="Previous Page">
              <span aria-hidden="true">&laquo; Previous Page</span>
            </a>
          </li>
          <li *ngIf="next">
            <a [routerLink]="['/book']" [queryParams]="{ page: nextPage }"
                    (click)="gotoPage(nextPage)" aria-label="Next Page">
              <span aria-hidden="true">Next Page &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>

  </div>
</div>

