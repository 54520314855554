import { Component, OnInit } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';
import { Campus } from '../campus';
import { CampusService } from '../campus.service';


@Component({
  selector: 'app-campus-list',
  templateUrl: './campus-list.component.html',
  styleUrls: ['./campus-list.component.css']
})
export class CampusListComponent extends BaseListComponent<Campus> implements OnInit {
  baseUrl = 'campus/';
  viewTitle = 'Campuses - AIMHub';

  constructor(
    protected dataService: CampusService
  ) {
    super(dataService);
  }
}
