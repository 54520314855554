import { Component } from '@angular/core';

import { BaseFormComponent } from '../../base/base-form.component';

import { Campus } from '../campus';
import { CampusProfileService } from '../campus-profile.service';
import { initCampusFormGroup } from '../campus-fields/campus-fields.component';


/**
 * Campus form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-campus-profile-form',
  templateUrl: './campus-profile-form.component.html',
})
export class CampusProfileFormComponent extends BaseFormComponent<Campus> {

  constructor(
    protected dataService: CampusProfileService
  ) {
    super(Campus, initCampusFormGroup, dataService);
  }

  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
    // Get target form values.
    const {
      name = '',
      city = '',
      state = '',
    } = this.formComposite.value.record;

    // Format clipboard contents.
    this.clipboardText = [
      `Name: ${name}`,
      `City: ${city}`,
      `State: ${state}`,
    ].join('\n');
  }
}
