import { environment } from '../../environments/environment';

class AppLogger {

  protected apiHost = environment.apiHost;
  protected endpointUrl = `${this.apiHost}/api/healthcheck/`;

  constructor() { }

  async info(message: string, extraData = {}) {
    const body = extraData;
    body['message'] = message;
    body['loglevel'] = 'info';
    this.sendLog(body);
  }

  async warn(message: string, extraData = {}) {
    const body = extraData;
    body['message'] = message;
    body['loglevel'] = 'warn';
    this.sendLog(body);
  }

  async error(message: string, extraData = {}) {
    const body = extraData;
    body['message'] = message;
    body['loglevel'] = 'error';
    this.sendLog(body);
  }

  async sendLog(body: {}) {
    try {
      body['timestamp'] = new Date();

      const requestData = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
      }

      const response = await fetch(this.endpointUrl, requestData);
      const responseData = await response.json();

      console.log(responseData);
      return responseData;

    } catch (e) {
      console.error('Failed to reach healthcheck endpoint.');
    };
  }
}

export { AppLogger };
