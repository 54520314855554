import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  @Input() injectedRecordId: string;  // Explicitly redeclare this as an input

  routeParams = {};
  recordId: string;

  constructor(
    protected route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.parseParams();
  }

  /**
   * Parse the URL parameters from the route.
   */
  parseParams() {
    this.route.params.subscribe((params: Params) => {
      this.routeParams = params;

      // Get `id` param.
      if (this.routeParams.hasOwnProperty('id')) {
        this.recordId = this.routeParams['id'];
      }
    });
  }

}
