import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Book } from '../../book/book';
import { BookService } from '../../book/book.service';

declare var ISBN: any;


@Component({
  selector: 'app-batch-isbn-form',
  templateUrl: './batch-isbn-form.component.html',
  styleUrls: ['./batch-isbn-form.component.css']
})
export class BatchIsbnFormComponent implements OnInit {

  @Output() onBatchSubmitClickedEmitter = new EventEmitter<Book[]>();
  @Output() onSingleSubmitClickedEmitter = new EventEmitter<Book>();

  bookList: Book[] = [];
  isbnList: string[];

  recordForm: FormGroup;
  isbnErrorList = [];
  autofillCount = null;
  autofillLimit = null;

  submitting = false;
  active = true;
  success = false;
  failed = false;
  throttled = false;
  recordUrl: string;

  constructor(protected fb: FormBuilder,
              protected bookService: BookService) {
  }

  ngOnInit() {
    this.buildForm();

    this.bookService.getCampusAutofillCount()
      .then(response => {
        this.autofillCount = response['autofill_count'];
        this.autofillLimit = response['autofill_limit'];
      });
  }

  emitBatchBookList() {
    this.onBatchSubmitClickedEmitter.emit(this.bookList);
  }

  emitSingleBook(book: Book) {
    this.onSingleSubmitClickedEmitter.emit(book);
  }

  onSubmit() {
    // JS is ugly
    const _this_ = this;

    this.success = false;
    this.throttled = false;
    this.submitting = true;
    this.bookList = [];
    this.isbnErrorList = [];

    this.prepareIsbnList();
    this.bookService.createBatchIsbn(this.isbnList)
      .then(response => {
        this.autofillCount = response['autofill_count'];
        this.autofillLimit = response['autofill_limit'];

        for (let i = 0; i < response['book_list'].length; i++) {
          let data = response['book_list'][i];
          let book = new Book();
          Object.assign(book, data);
          _this_.bookList.push(book);
          this.clearIsbnInput(book.isbn);
        }
        this.emitBatchBookList();

        if (_this_.bookList.length > 0) {
          this.success = true;
        }

        this.parseIsbnErrors(response['error_list']);
      })
      .catch(err => this.handleServerErrors(err));

    this.submitting = false;
  }

  prepareIsbnList() {
    const { isbn } = this.recordForm.value;
    this.isbnList = isbn.split(/[\s,]+/);
    this.isbnList = this.isbnList.filter(n => n !== '');
  }

  /**
   * Accept the raw ISBN value of a valid book, added to the API.
   */
  clearIsbnInput(value: string) {
    let tmpIsbnList = [];

    try {

      for (let i = 0; i < this.isbnList.length; i++) {
        let item = ISBN.parse(this.isbnList[i]);

        // Can't trust the ISBN library to validate all ISBNs.
        // Example: 978-0486411217
        if (item) {
          // Get each ISBN representation.
          let isbn10 = item.asIsbn10();
          let isbn13 = item.asIsbn13();

          // Retain list value if no match.
          if (value !== isbn10 && value !== isbn13) {
            tmpIsbnList.push(this.isbnList[i]);
          }
        }
      }

    } catch (err) {
      console.log('Error clearing ISBN for values.');
    }

    this.isbnList = tmpIsbnList;
    this.buildForm();
  }

  handleServerErrors(err: any) {
    if (err.status === 400) {
      this.isbnErrorList = [];
      const data = err['isbn'];
      this.parseIsbnErrors(data);
    }
    if (err.status === 429) {
      this.throttled = true;
    }
  }

  parseIsbnErrors(data: any) {
    for (let errorItem of data) {
      for (const key in errorItem) {
        if (!Object.prototype.hasOwnProperty.call(errorItem, key)) { continue; };
        this.isbnErrorList.push(errorItem[key]);
      }
    }
  }

  buildForm(): void {
    if (this.isbnList === undefined) { this.initFormModel(); }
    this.recordForm = this.buildFormGroup();
  }

  buildFormGroup(): FormGroup {
    return this.fb.group({
      'isbn': [this.isbnList, [ Validators.required ]],
    });
  }

  initFormModel() {
    this.isbnList = [];
  }

}
