import { Component, OnInit } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';
import { UserService } from '../../user/user.service';
import { Publisher } from '../publisher';
import { PublisherService } from '../publisher.service';


@Component({
  selector: 'app-publisher-list',
  templateUrl: './publisher-list.component.html',
  styleUrls: ['./publisher-list.component.css']
})
export class PublisherListComponent extends BaseListComponent<Publisher> implements OnInit {
  baseUrl = 'publisher/';
  viewTitle = 'Publishers - AIMHub';

  userRole: string;

  constructor(
    protected dataService: PublisherService,
    protected userService: UserService
  ) {
    super(dataService);
  }

  ngOnInit(): void {
    this.initUserRole();
    super.ngOnInit();
  }

  initUserRole() {
    this.userService.getRole()
      .then(role => this.userRole = role)
      .catch(err => this.handleError(err));
  }
}
