<mat-card id="request-summary">

  <p>Showing {{ count }} exchange requests.</p>

  <h2>Filter</h2>
  <form id="request-list-filter">

    <mat-slide-toggle
      id="filter-status-open"
      value="Open"
      [checked]="filter.status.open"
      color="primary"
      (change)="updateStatusFilter('open')"
      >
      Open Requests
    </mat-slide-toggle>

    <mat-slide-toggle
      id="filter-status-complete"
      value="Complete"
      [checked]="filter.status.complete"
      color="primary"
      (change)="updateStatusFilter('complete')"
      >
    Completed Requests
    </mat-slide-toggle>

    <mat-slide-toggle
      id="filter-status-cancelled"
      value="Cancelled"
      [checked]="filter.status.cancelled"
      color="primary"
      (change)="updateStatusFilter('cancelled')"
      >
      Cancelled Requests
    </mat-slide-toggle>

  </form>

</mat-card>


<div id="request-list" class="panel">
  <div class="row">

    <table id="table-exchange-request-list" class="table table-hover">
      <thead>
          <tr>
            <th>Holding Title</th>
            <th>ISBN</th>
            <th>File Format</th>
            <th>Source</th>
            <th>{{ campus_table_heading }}</th>
            <th>Term</th>
            <th>Request Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of results">

          <td>{{ result.holding_title_str }}</td>
          <td>{{ result.holding_isbn_str }}</td>
          <td>{{ result.holding_format_str }}</td>
          <td>{{ result.holding_source_str }}</td>
          <td>{{ result[campus_table_field] }}</td>
          <td>{{ result.term_str }}</td>
          <td>{{ result.created_date | date: 'short' }}</td>
          <td>{{ result.status_str }}</td>
          <td>
            <a mat-raised-button
              id="link-exchange-request-{{ result.id }}"
              color="primary"
              [routerLink]="['/exchange/request', result.id]"
              >
              View Request
            </a>
          </td>

        </tr>
      </tbody>
    </table>

    <nav aria-label="Pagination control">
      <ul class="pagination">
        <li *ngIf="previous">
          <a mat-raised-button
            [routerLink]="[baseUrl]"
            [queryParams]="{ page: previousPage }"
            (click)="gotoPage(previousPage)" aria-label="Previous Page"
            >
            <mat-icon>navigate_before</mat-icon>
            Previous Page
          </a>
        </li>
        <li *ngIf="next">
          <a mat-raised-button
            [routerLink]="[baseUrl]"
            [queryParams]="{ page: nextPage }"
            (click)="gotoPage(nextPage)" aria-label="Next Page"
            >
            Next Page
            <mat-icon>navigate_next</mat-icon>
          </a>
        </li>
      </ul>
    </nav>

  </div>
</div>
