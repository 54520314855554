import { Component } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';
import { CampusSystem } from '../campus-system';
import { CampusSystemService } from '../campus-system.service';


@Component({
  selector: 'app-campus-system-list',
  templateUrl: './campus-system-list.component.html',
  styleUrls: ['./campus-system-list.component.css']
})
export class CampusSystemListComponent extends BaseListComponent<CampusSystem> {
  baseUrl = 'campus-system';
  viewTitle = 'Campus Systems - AIMHub';

  constructor(
    protected dataService: CampusSystemService
  ) {
    super(dataService);
  }
}
