import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UserService } from '../../user/user.service';
import { HoldingBookService } from '../../holding-book/holding-book.service';

import { Book } from '../../book/book';
import { FileFormat } from '../../file-format/file-format';
import { HoldingBookSource } from '../../holding-book-source/holding-book-source';
import { HoldingBook } from '../../holding-book/holding-book';


@Component({
  selector: 'app-batch-isbn-list-item',
  templateUrl: './batch-isbn-list-item.component.html',
  styleUrls: ['./batch-isbn-list-item.component.css']
})
export class BatchIsbnListItemComponent implements OnInit {

  @Input() book: Book;
  @Input() formatList: FileFormat[] = [];
  @Input() sourceList: HoldingBookSource[] = [];

  @Output() onDoneClickedEmitter = new EventEmitter<Book>();

  holdingList: HoldingBook[];
  holdingSavedList: HoldingBook[];

  userProfile = {};

  holdingDeleted = false;
  holdingCreated = false;
  holdingUpdated = false;

  constructor(
    protected userService: UserService,
    protected holdingBookService: HoldingBookService,
  ) { }

  ngOnInit() {
    this.userService.getProfile()
      .then(userProfile => this.userProfile = userProfile)
      .then(() => this.listHoldings());
  }

  // Received from child
  onAddFormatClicked(formats: FileFormat[]) {
    for (let i = 0; i < formats.length; i++) {
      this.appendHolding(formats[i].id, this.getDefaultSourceId());
    }
  }

  // Received from child
  onDeleteClicked(holding: HoldingBook) {
    let position = this.holdingList.indexOf(holding);
    this.holdingList.splice(position, 1);
  }

  onDeleteNotificationClose() {
    this.holdingDeleted = false;
  }

  onCreateNotificationClose() {
    this.holdingCreated = false;
  }

  onUpdateNotificationClose() {
    this.holdingUpdated = false;
  }

  applyClicked() {
    this.createSelectedHoldings()
      .then(() => this.updateSelectedHoldings())
      .then(() => this.deleteSelectedHoldings())
      .then(() => this.listHoldings());
  }

  createSelectedHoldings(): Promise<void> {
    // Add new holdings.
    let newHoldings = [];
    for (let i = 0; i < this.holdingList.length; i++) {
      let holding = this.holdingList[i];

      if (holding.id === '') {
        newHoldings.push(holding);
      }
    }

    if (newHoldings.length > 0) {
      return this.holdingBookService.createBulk(newHoldings)
        .then(() => { this.holdingCreated = true; });
    }

    return new Promise((resolve, reject) => resolve());
  }

  updateSelectedHoldings(): Promise<any> {
    let updateHoldings = [];

    const savedIdList = this.holdingSavedList.map(x => x.id);

    for (let i = 0; i < this.holdingList.length; i++) {
      let holding = this.holdingList[i];
      if (savedIdList.indexOf(holding.id) >= 0) {
        updateHoldings.push(holding);
        this.holdingUpdated = true;
      }
    }

    const promiseList = updateHoldings.map((x) => this.updateHolding(x));
    return Promise.all(promiseList);
  }

  deleteSelectedHoldings(): Promise<any> {
    // Holding in saved list, but not in current list. Delete it.
    const savedIdList = this.holdingSavedList.map(x => x.id);
    const currentIdList = this.holdingList.map(x => x.id);
    const deleteIdList = [...savedIdList].filter(
      x => currentIdList.indexOf(x) < 0
    );

    const promiseList = deleteIdList.map((id) => this.deleteHolding(id));
    return Promise.all(promiseList);
  }

  // Sent to parent
  doneClicked() {
    this.onDoneClickedEmitter.emit(this.book);
  }

  getCampusId() {
    if ( this.userProfile.hasOwnProperty('campus') ) {
      return this.userProfile['campus'];
    }
    return '';
  }

  getDefaultSourceId() {
    for (let i = 0; i < this.sourceList.length; i++) {
      if (this.sourceList[i].name === 'Unspecified') {
        return this.sourceList[i].id;
      }
    }
    return '';
  }

  appendHolding(formatId = '', sourceId = '') {
    let bookId = this.book.id;
    let campusId = this.getCampusId();

    let holding = new HoldingBook('',
      campusId,
      bookId,
      formatId,
      sourceId,
    );

    this.holdingList.push(holding);
  }

  updateHolding(holding: HoldingBook) {
    return this.holdingBookService.update(holding);
  }

  deleteHolding(holdingId: string) {
    return this.holdingBookService.delete(holdingId)
      .then(() => this.holdingDeleted = true);
  }

  listHoldings() {
    const params = {
      'campus': this.getCampusId(),
      'book': this.book.id,
    };

    this.holdingBookService.listMeta(params)
      .then(response => {
        this.holdingList = JSON.parse(JSON.stringify(response['results']));
        this.holdingSavedList = JSON.parse(JSON.stringify(response['results']));

      });
  }

}
