import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { UserListComponent } from './user-list/user-list.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserDetailComponent } from './user-detail/user-detail.component';


const routes: Routes = [
  {
    path: 'user/detail/:id',
    component: UserDetailComponent,
    title: 'User Detail' // Title for the user detail page
  },
  {
    path: 'user/account',
    component: UserProfileComponent,
    canActivate: [AuthGuardService],
    title: 'User Account' // Title for the user account page
  },
  {
    path: 'user',
    component: UserListComponent,
    canActivate: [AuthGuardService],
    title: 'User List' // Title for the user list page
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {}
