<div class="row">
  <form>
    <div class="col-sm-5">
      <strong>Format:</strong>
      <select id="holding-format" name="format" class="form-control">
        <option value="">Select a Format</option>
        <option *ngFor="let f of format_list" value="f.id">{{ f.name }}</option>
      </select>
    </div>

    <div class="col-sm-5">
      <strong>Source:</strong>
      <select id="holding-source" name="source" class="form-control">
        <option value="">Select a Source</option>
        <option *ngFor="let s of source_list" value="s.id">{{ s.name }}</option>
      </select>
    </div>

    <div class="col-sm-2">
      <button type="button" class="btn-primary" (click)="create()">Add</button>
    </div>
  </form>
</div>
<div *ngFor="let s of format_list">
  {{ s.id }} {{ s.name }}
</div>
<div *ngFor="let s of source_list">
  {{ s.id }} {{ s.name }}
</div>
