
export class CampusSystem {

  constructor(
    public id = '',
    public name = '',
    public created_date = '',
    public modified_date = '',
  ) { }

}
