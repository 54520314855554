import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';

import { ExchangeRequestFile } from '../exchange-request-file';
import { ExchangeRequestFileService } from '../exchange-request-file.service';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.css']
})
export class FileUploadModalComponent implements OnInit {

  @Input() requestId: string;
  @Output() onSubmitClickedEmitter = new EventEmitter<ExchangeRequestFile>();
  @Output() onCloseClickedEmitter = new EventEmitter<ExchangeRequestFile>();
  @ViewChild('fileUploadModal') public fileUploadModal: ModalDirective;
  @ViewChild('fileInput') fileInput;

  savedRecord: ExchangeRequestFile;

  uploading = false;
  uploadProgress = 0;
  fileExtensions: any;
  fileMaxSize: number;
  storageLimit: number;
  storageUsed: number;
  storageCapacity: number;

  success = false;
  successNotification = 'File successfully uploaded.';
  failed = false;
  failedNotification = 'File upload failed.';

  subscription: Subscription;

  constructor(
    // Made public, to be accessible from template in production.
    public dataService: ExchangeRequestFileService,
  ) { }

  ngOnInit() {
    this.getFileUploadParameters();
    this.getRequestCapacity(this.requestId);

    this.subscription = this.dataService.progressAsync$
          .subscribe(item => this.uploadProgress = item);
  }

  async uploadFile(fileToUpload: any): Promise<any> {
    try {
      this.validateFileUpload(fileToUpload);
      const result = this.dataService.upload(this.requestId, fileToUpload);
      await result;
      console.log(this.successNotification);
      return result;

    } catch (e) {
      console.log(fileToUpload);
      console.error(e);
      return e;
    }
  }

  async uploadAllFiles(fileElement: HTMLInputElement): Promise<any> {
    try {
      if (!fileElement.files || !fileElement.files.length) {
        throw 'No files to upload';
      }

      // Initiate async file uploads, storing the promises.
      let results = new Array();
      for (let i = 0; i < fileElement.files.length; i++) {
        let fileToUpload = fileElement.files[i];
        results.push(this.uploadFile(fileToUpload));
      }

      // Wait for async file uploads to complete.
      for (let i = 0; i < fileElement.files.length; i++) {
        console.log(`Awaiting result ${i}`);
        await results[i];
      }

      // Wait for AWS SNS to trigger file upload logs on the backend.
      await this.sleep(2000);

      this.onSubmitClickedEmitter.emit(this.savedRecord);
      this.success = true;
      this.uploading = false;
      console.log('Finished uploading all files.');

    } catch (e) {
      console.log('Error uploading file.');
      console.error(e);
      this.uploading = false;
      this.success = false;
      this.failed = true;
    }
  }

  onSubmitClicked() {
    this.uploading = true;
    this.dataService.updateProgress(0);

    const fileElement = this.fileInput.nativeElement;
    this.uploadAllFiles(fileElement);
  }

  onCloseClicked() {
    this.fileUploadModal.hide();
    if (this.savedRecord === undefined) {
      this.onCloseClickedEmitter.emit(undefined);
    } else {
      this.onCloseClickedEmitter.emit(this.savedRecord);
    }
    this.resetModal();
  }

  getFileUploadParameters() {
    return this.dataService.getFileUploadParameters()
      .then(response => {
        this.fileExtensions = response['extensions'];
        this.fileMaxSize = response['max_size'];
      })
      .catch(this.handleError);
  }

  getRequestCapacity(request_id: string) {
    return this.dataService.getRequestCapacity(request_id)
      .then(response => {
        this.storageCapacity = parseFloat(response['capacity']);
      })
      .catch(this.handleError);
  }

  getCampusStorageStats() {
    return this.dataService.getCampusStorageStats()
      .then(response => {
        this.storageLimit = parseFloat(response['storage_limit']);
        this.storageUsed = parseFloat(response['storage_used']);
      })
      .catch(this.handleError);
  }

  validateFileUpload(fileToUpload): any {
    const fileName = fileToUpload.name;

    // File extension exists?
    if (fileName.indexOf('.') < 0) {
      this.failedNotification = 'File name has no extension';
      this.failed = true;
      return Promise.reject(this.failedNotification);
    }

    // Valid file extension?
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(fileName)[1];
    if (this.fileExtensions.indexOf(ext) < 0) {
      this.failedNotification = `Unauthorized file extension: ${ext}`;
      this.failed = true;
      return Promise.reject(this.failedNotification);
    }

    // Valid file size?
    let size = parseFloat((fileToUpload.size / (1000000)).toFixed(2));  // Byte to MB
    let max = parseFloat(this.fileMaxSize.toFixed(2));
    if (size > max) {
      let msg = `File is too large (${size} MB). Maximum size is ${max} MB.`;
      this.failedNotification = msg;
      this.failed = true;
      return Promise.reject(this.failedNotification);
    }

    // Campus storage capacity reached?
    if (size > this.storageCapacity) {
      let msg = `Requesting campus has reached their storage limit.`;
      msg += ' They must clear existing files to receive more.';
      this.failedNotification = msg;
      this.failed = true;
      return Promise.reject(this.failedNotification);
    }

    this.failed = false;
    return Promise.resolve();
  }

  resetModal(): void {
    this.success = false;
    this.failed = false;
    this.uploading = false;
    this.fileInput.nativeElement.value = '';
    this.dataService.updateProgress(0);
  }

  handleError(error: any): void {
    // @TODO: Improve logging.
    console.log('FileUploadModal error: ', error);
  }

  // Use async/await for sleep method.
  async sleep(ms: number) {
    await new Promise((resolve) => setTimeout(resolve, ms));
  }
}
