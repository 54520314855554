import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-home',
  template: `
    <div class="nav-container">
      <div>
        <a mat-button id="home-link" aria-label="AIMHub Home" class="home-link" routerLink="/">
          <mat-icon>home</mat-icon> AIMHub
        </a>
      </div>
      <!-- Use *ngIf instead of ng-if -->
      <div *ngIf="isHomePage()" class="ati-logo-container">
        <img src="assets/images/ATI.png" alt="Accessible Technology Initiative Logo" class="ati-logo-image">
      </div>
    </div>
  `,
  styles: [`
    .nav-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 60px;
    }

    .home-link {
      margin-right: auto; /* Pushes the link to the left */
    }

    .ati-logo-image {
      max-height: 80%;
      height: 80%;
      width: auto;
      float: right;
    }

    .ati-logo-container {
      height: 100%;
      margin-left: auto;
      margin-right: 0;
    }
  `],
})
export class NavHomeComponent {
  constructor(private router: Router) { }

  // Method to check if the current route is home
  isHomePage(): boolean {
    return this.router.url === '/';
  }
}
