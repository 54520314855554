import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-nav-request-out',
  templateUrl: './nav-request-out.component.html',
  styleUrls: ['./nav-request-out.component.css']
})
export class NavRequestOutComponent {

  @Input() count = 0;

  constructor() {
  }

}
