import { Component, Input } from '@angular/core';

import { HoldingBook } from '../holding-book/holding-book';
import { ExchangeRequest } from './exchange-request';


@Component({
  selector: 'app-exchange-request-card',
  template: `
    <div id="exchange-request-{{ record.id }}">

      <app-card-field [uniqueId]="record.id" [labelText]="'ID'" [fieldName]="'request-id'">
        {{ record.id }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Requested By'" [fieldName]="'requested-by'">
        {{ record.requesting_campus_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Requested From'" [fieldName]="'requested-from'">
        {{ holding.campus_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Format'" [fieldName]="'format'">
        {{ holding.file_format_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Source'" [fieldName]="'source'">
        {{ holding.source_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Academic Term'" [fieldName]="'academic-term'">
        {{ record.term_str }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Request Date'" [fieldName]="'request-date'">
        {{ record.created_date | date: 'medium' }}
      </app-card-field>


      <div *ngIf="record.close_date">
        <app-card-field [uniqueId]="record.id" [labelText]="'Closing Date'" [fieldName]="'closing-date'">
          {{ record.close_date | date: 'medium' }}
        </app-card-field>
        <app-card-field [uniqueId]="record.id" [labelText]="'Elapsed Days'" [fieldName]="'elapsed-days'">
          {{ record.elapsed_days }}
        </app-card-field>
      </div>

      <app-card-field [uniqueId]="record.id" [labelText]="'Status'" [fieldName]="'status'">
        {{ record.status_str }}
      </app-card-field>

    </div>
    `,
})
export class ExchangeRequestCardComponent {

  @Input() record: ExchangeRequest;
  @Input() holding: HoldingBook;

  constructor() { }
}
