<div *ngIf="record">

<main>

  <div *ngIf="user.campus === record.holding_campus">
    <h1>Request from {{ record.requesting_campus_str }}</h1>
  </div>
  <div *ngIf="user.campus !== record.holding_campus">
    <h1>My Request to {{ record.holding_campus_str }}</h1>
  </div>

  <div class="col-sm-8">

    <mat-card id="request-information" *ngIf="holding" class="detail-card">
      <h2>Request Information</h2>
      <app-exchange-request-card [record]="record" [holding]="holding">
      </app-exchange-request-card>

      <div *ngIf="user.campus !== record.holding_campus">
        <div *ngIf="record.status_str === 'Open'">
          <button mat-raised-button
            id="complete-request"
            color="primary"
            (click)="updateStatus('Complete')"
            >
            Complete Request
          </button>
          <button mat-raised-button
            id="cancel-request"
            color="warn"
            (click)="updateStatus('Cancelled')"
            >
            Cancel Request
          </button>
        </div>
        <button mat-raised-button
            *ngIf="record.status_str !== 'Open'"
            id="reopen-request"
            color="primary"
            (click)="updateStatus('Open')"
            >
          Reopen Request
        </button>
      </div>
    </mat-card>

    <mat-card id="book-information" *ngIf="book" class="detail-card right-card">
      <h2>Book Information</h2>
      <app-book-card [record]="book"></app-book-card>
    </mat-card>

    <mat-card id="request-files" *ngIf="holding" class="file-upload-card">
      <h2>Uploaded Files</h2>
      <app-exchange-request-file-list
        [requestId]="record.id"
        [showUpload]="user.campus === record.holding_campus"
        (onUploadClickedEmitter)="fileSubmitClicked($event)"
        (onDeleteClickedEmitter)="fileDeleteClicked($event)"
        >
      </app-exchange-request-file-list>
    </mat-card>

  </div>

  <mat-card id="request-history" *ngIf="holding" class="col-sm-4">
    <h2>Request Log</h2>

    <app-exchange-request-log-list
      [requestId]="record.id"
      [logList]="logList"
      (onSubmitClickedEmitter)="logSubmitClicked($event)"
      (onScrollEmitter)="logScroll($event)"
      >
    </app-exchange-request-log-list>
  </mat-card>

</main>

</div>

