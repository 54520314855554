<mat-card class="password-change-card">

  <mat-card-title> Change Password </mat-card-title>

  <mat-card-content>

    <div id="success-notification" class="alert alert-success" *ngIf="success">
      Password has been changed.
    </div>
    <div id="failed-notification" class="alert alert-danger"
         *ngIf="failed && failedNotification">
      {{ failedNotification }}
    </div>

    <app-password-policy>
    </app-password-policy>

    <form *ngIf="active"
        class="top-buffer"
        [formGroup]="passwordChangeForm"
        (ngSubmit)="onSubmit()"
        >

      <mat-form-field class="password-change-field">
        <input matInput placeholder="Current Password"
            type="password"
            formControlName="old_password"
            required
            >
        <mat-error *ngIf="passwordChangeForm.controls.old_password.hasError('backend')">
          {{ passwordChangeForm.controls['old_password'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="passwordChangeForm.controls.old_password.hasError('required')">
          Current password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="password-change-field">
        <input matInput placeholder="New Password"
            type="password"
            formControlName="new_password1"
            required
            >
        <mat-error *ngIf="passwordChangeForm.controls.new_password1.hasError('backend')">
          {{ passwordChangeForm.controls['new_password1'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="passwordChangeForm.controls.new_password1.hasError('required')">
          New password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="password-change-field">
        <input matInput placeholder="Confirm New Password"
            type="password"
            formControlName="new_password2"
            required
            >
        <mat-error *ngIf="passwordChangeForm.controls.new_password2.hasError('backend')">
          {{ passwordChangeForm.controls['new_password2'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="passwordChangeForm.controls.new_password2.hasError('required')">
          Password confirmation is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit">
        Change Password
      </button>
    </form>

  </mat-card-content>

</mat-card>
