<div *ngIf="parentFormGroup" [formGroup]="parentFormGroup">

  <mat-form-field class="autocomplete-form-container">
    <input matInput type="text" placeholder="Campus"
          [matAutocomplete]="recordAuto"
          [readonly]="readOnly"
          [required]="required"
          formControlName="campus"
          >

    <mat-autocomplete #recordAuto="matAutocomplete" [displayWith]="displayValue">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option.name }}
      </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="parentFormGroup.controls.campus.hasError('backend')">
      <span *ngIf="myControl.errors['backend'].toString().indexOf('UUID') > -1">
        Campus does not exist.
      </span>
      <span *ngIf="myControl.errors['backend'].toString().indexOf('UUID') === -1">
        {{ parentFormGroup.controls['campus'].errors['backend'] }}
      </span>
    </mat-error>
    <mat-error *ngIf="parentFormGroup.controls.campus.hasError('required')">
        Campus is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <app-campus-dialog [iconOnly]="true"
    (onSubmitSuccessEmitter)="onNewRecord($event)"
    >
  </app-campus-dialog>

</div>

<div *ngIf="!parentFormGroup">
  <mat-form-field>

    <input matInput type="text" placeholder="Campus"
          [matAutocomplete]="recordAuto" [readonly]="readOnly"
          [formControl]="myControl"
          >

    <mat-autocomplete #recordAuto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
          {{ option.name }}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>
</div>
