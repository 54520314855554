import { Component, OnInit } from '@angular/core';
import {
  Router, Params, NavigationStart, DefaultUrlSerializer
} from '@angular/router';
import { filter } from 'rxjs/operators';

import { BaseListComponent } from '../../base/base-list.component';

import { Book } from '../../book/book';
import { SearchService, SEARCH_PARAM } from '../search.service';


@Component({
  selector: 'app-search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.css']
})
export class SearchResultListComponent extends BaseListComponent<Book> implements OnInit {

  viewTitle = 'Search - AIMHub';
  apiQueryParams = { 'q': '', 'page': this.defaultPage };

  searchQuery = '';
  searching = false;
  failed = false;
  failedMessage = '';

  constructor(
    protected dataService: SearchService,
  ) {
    super(dataService);
    this.watchRouterUpdate(this.router);
  }

  ngOnInit() {
    this.resetSearch();
    super.ngOnInit();
  }

  /* Override base class.
   */
  parseQueryParams() {
    this.route.queryParams.forEach((params: Params) => {
      this.initPageQueryParam(params);
      this.initSearchQueryParam(params);
    });
  }

  initSearchQueryParam(params: Params): void {
    if (params.hasOwnProperty(SEARCH_PARAM)) {
      this.searchQuery = params[SEARCH_PARAM];
      Object.assign(this.apiQueryParams, params);
    }
  }

  list(params = this.apiQueryParams, apiUrl?): Promise<any> {
    this.searching = true;
    return super.list(params, apiUrl)
      .then(() => this.searching = false);
  }

  watchRouterUpdate(router: Router) {
    router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => this.runSearch(event.url));
  }

  runSearch(newUrl) {
        const u = new DefaultUrlSerializer().parse(newUrl);

        if (this.isNewSearch(newUrl)) {
          // Refresh search results if the search box is triggered.
          this.resetSearch();
          Object.assign(this.apiQueryParams, u.queryParams);
          this.list(this.apiQueryParams);
        }
  }

  isNewSearch(url: string) {
    const re = /^\/search\/?\?/i;
    return re.test(url);
  }

  resetSearch() {
    this.searching = false;
    this.failed = false;
    this.failedMessage = '';
  }

  handleError(error: any) {
    this.searching = false;

    if (error.name === 'TimeoutError') {
      this.failed = true;
      this.failedMessage = 'Search query took too long to get results. Please try narrowing your search.';
    }
    console.error('SearchResultListComponent Error:', error);
  }
}
