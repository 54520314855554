import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IServiceModel } from '../base/service.interface';


@Component({
  selector: 'app-form-alert',
  template: `
    <span *ngIf="formComposite">
      <alert type="danger" [dismissible]="true"
            *ngIf="formComposite.hasError('backend')">
        {{ formComposite.errors['backend'] }}
      </alert>
    </span>

    <span *ngIf="record">
      <alert type="success" *ngIf="success && !record.id"
            [dismissible]="true" [dismissOnTimeout]="notifyTimeout">
        Created {{ objectName }}
      </alert>

      <alert type="success" *ngIf="success && record.id"
            [dismissible]="true" [dismissOnTimeout]="notifyTimeout">
        Updated {{ objectName }}
      </alert>
    </span>

    <span *ngIf="!record">
      <alert type="success" *ngIf="success"
            [dismissible]="true" [dismissOnTimeout]="notifyTimeout">
        Created {{ objectName }}
      </alert>
    </span>
             `,
})
export class FormAlertComponent {

  @Input() record: IServiceModel;
  @Input() objectName = '';
  @Input() formComposite: FormGroup;
  @Input() success = false;
  @Input() notifyTimeout = 10000;

  constructor() { }
}
