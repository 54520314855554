import { Component, OnInit, Input } from '@angular/core';

import { ExchangeRequestStatus } from '../exchange-request-status';


@Component({
  selector: 'app-exchange-request-status-form-update',
  templateUrl: './exchange-request-status-form-update.component.html',
  styleUrls: ['./exchange-request-status-form-update.component.css']
})
export class ExchangeRequestStatusFormUpdateComponent implements OnInit {

  @Input() record: ExchangeRequestStatus;

  constructor() { }

  ngOnInit() {
  }

}
