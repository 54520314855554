import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { PublisherService } from './publisher.service';
import { PublisherRoutingModule } from './publisher-routing.module';

import { PublisherCardComponent } from './publisher-card.component';
import { PublisherAutocompleteFieldComponent } from './publisher-autocomplete-field/publisher-autocomplete-field.component';
import { PublisherDetailComponent } from './publisher-detail/publisher-detail.component';
import {
  PublisherDialogComponent, PublisherDialogContentComponent
} from './publisher-dialog/publisher-dialog.component';
import { PublisherFieldsComponent } from './publisher-fields/publisher-fields.component';
import { PublisherFormComponent } from './publisher-form/publisher-form.component';
import { PublisherListComponent } from './publisher-list/publisher-list.component';
import {
  PublisherDeleteDialogComponent, PublisherDeleteDialogContentComponent
} from './publisher-delete-dialog/publisher-delete-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PublisherRoutingModule,
  ],
  exports: [
    PublisherAutocompleteFieldComponent,
  ],
  declarations: [
    PublisherCardComponent,
    PublisherAutocompleteFieldComponent,
    PublisherDetailComponent,
    PublisherDialogComponent,
    PublisherDialogContentComponent,
    PublisherFieldsComponent,
    PublisherFormComponent,
    PublisherListComponent,
    PublisherDeleteDialogComponent,
    PublisherDeleteDialogContentComponent,
  ],
  providers: [
    PublisherService,
  ],
})
export class PublisherModule { }
