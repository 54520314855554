import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';

import { AuthToken } from '../auth/auth-token';
import { BaseCrudService } from '../base/base-crud.service';
import { Book } from '../book/book';


export const SEARCH_PARAM = 'q';


@Injectable()
export class SearchService extends BaseCrudService<Book> {

  endpointUrl = `${this.apiHost}/api/search/`;
  searchQuery = '';
  timeoutSeconds = 25; // Slightly shorter timeout than Heroku.

  protected httpConfig = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  constructor(protected http: HttpClient) {
    super(http);
  }

  search(params = {}, apiUrl = this.endpointUrl): Promise<any> {
    let searchParams = new HttpParams();
    for (let p in params) {
      searchParams = searchParams.set(p, params[p].toString());
    }

    this.httpConfig['params'] = searchParams;

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig)
      .pipe(timeout(this.timeoutSeconds * 1000))
      .toPromise()
      .then(response => {
        const { results, previous, next, count } = response as any;
        return { results, previous, next, count: parseInt(count, 10), };
      })
      .catch(this.handleError);
  }
}
