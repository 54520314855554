import { IServiceModel } from '../base/service.interface';


export class FileFormat implements IServiceModel {

  constructor(
    public id = '',
    public name = '',
    public extension = '',
    public for_holdings = false,
    public created_date = '',
    public modified_date = '',
  ) { }

}
