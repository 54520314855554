let environment = {
  apiHost: 'http://localhost:8000',
  production: false,
};

function applyEnvironment(env: any) {
  environment = env;
}

export { environment, applyEnvironment };
