<span [formGroup]="formGroup">

  <div class="form-column">

    <mat-form-field>
      <input matInput placeholder="Publisher Name"
                      formControlName="name" required
                      [readonly]="isReadOnly('name')">

      <mat-error *ngIf="formGroup.controls.name.hasError('backend')">
        {{ formGroup.controls['name'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.name.hasError('required')">
        Publisher name is <strong>required</strong>
      </mat-error>

    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="URL" formControlName="url"
            type="url" [readonly]="isReadOnly('url')">

      <mat-error *ngIf="formGroup.controls.url.hasError('backend')">
        {{ formGroup.controls['url'].errors['backend'] }}
      </mat-error>

    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Permissions URL" formControlName="permissions_url"
            type="url" [readonly]="isReadOnly('permissions_url')">

      <mat-error *ngIf="formGroup.controls.permissions_url.hasError('backend')">
        {{ formGroup.controls['permissions_url'].errors['backend'] }}
      </mat-error>

    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Parent Company" formControlName="parent_company"
            [readonly]="isReadOnly('parent_company')">

      <mat-error *ngIf="formGroup.controls.parent_company.hasError('backend')">
        {{ formGroup.controls['parent_company'].errors['backend'] }}
      </mat-error>

    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Contact Name" formControlName="contact_name"
            [readonly]="isReadOnly('contact_name')">

      <mat-error *ngIf="formGroup.controls.contact_name.hasError('backend')">
        {{ formGroup.controls['contact_name'].errors['backend'] }}
      </mat-error>

    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Email" formControlName="email"
            type="email" [readonly]="isReadOnly('email')">

      <mat-error *ngIf="formGroup.controls.email.hasError('backend')">
        {{ formGroup.controls['email'].errors['backend'] }}
      </mat-error>

    </mat-form-field>

  </div>
  <div class="form-column">

    <mat-form-field>
      <input matInput placeholder="Phone" formControlName="phone"
            type="tel" [readonly]="isReadOnly('phone')">

      <mat-error *ngIf="formGroup.controls.phone.hasError('backend')">
        {{ formGroup.controls['phone'].errors['backend'] }}
      </mat-error>

    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Fax" formControlName="fax"
            type="tel" [readonly]="isReadOnly('fax')">

      <mat-error *ngIf="formGroup.controls.fax.hasError('backend')">
        {{ formGroup.controls['fax'].errors['backend'] }}
      </mat-error>

    </mat-form-field>

  </div>

</span>

