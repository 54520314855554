import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-nav-request-in',
  templateUrl: './nav-request-in.component.html',
  styleUrls: ['./nav-request-in.component.css']
})
export class NavRequestInComponent {

  @Input() count = 0;

  constructor() {
  }

}
