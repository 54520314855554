import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthToken } from '../auth/auth-token';
import { BaseCrudService } from '../base/base-crud.service';
import { HoldingBook } from './holding-book';


@Injectable()
export class HoldingBookService extends BaseCrudService<HoldingBook> {

  endpointUrl = `${this.apiHost}/api/holdings/books`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  makePublic(record: HoldingBook) {
    const apiUrl = `${this.endpointUrl}/${record.id}/public/`;
    const data = JSON.stringify({'is_public': true});

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.put(apiUrl, data, httpConfig)
      .toPromise()
      // .then(response => response)
      .catch(this.handleError);
  }

  makeAllPublic() {
    const apiUrl = `${this.endpointUrl}/all-public/`;
    const data = JSON.stringify({});

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.post(apiUrl, data, httpConfig)
      .toPromise()
      // .then(response => response)
      .catch(this.handleError);
  }

  getSummary(): Promise<Object> {
    try {
      const apiUrl = this.endpointUrl + '/summary/';
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      return this.http.get(apiUrl, httpConfig).toPromise();
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }
}
