import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { CampusSystemDetailComponent } from './campus-system-detail/campus-system-detail.component';
import { CampusSystemListComponent } from './campus-system-list/campus-system-list.component';


const routes: Routes = [
  {
    path: 'campus-system/detail/:id',
    component: CampusSystemDetailComponent,
    title: 'Campus System Detail' // Add a title for the campus system detail page
  },
  {
    path: 'campus-system',
    component: CampusSystemListComponent,
    canActivate: [AuthGuardService],
    title: 'Campus System List' // Add a title for the campus system list page
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampusSystemRoutingModule {}
