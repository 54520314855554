import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { UserProfileService } from '../user-profile.service';
import { User } from '../user';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  viewTitle = 'My Profile - AIMHub';

  record: User;
  success = false;

  readOnlyForm = false;
  recordReadOnly = [
    'email',
    'position',
    'role',
    'campus',
  ]

  constructor(
    protected titleService: Title,
    protected dataService: UserProfileService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle( this.viewTitle );

    this.dataService.read()
      .then(response => this.record = response);
  }
}
