<form id="search-bar" class="search-form">

  <mat-form-field class="search-field">
    <input matInput
      id="search-box"
      name="q"
      type="text"
      placeholder="Search AIMHub for holdings"
      autofocus="autofocus"
      [(ngModel)]="searchQuery"
      (keyup.enter)="onSubmit()"
      >
  </mat-form-field>

  <button mat-raised-button
      id="search-button" type="submit"
      (click)="onSubmit()"
      >
    <mat-icon>search</mat-icon> Search
  </button>

</form>
