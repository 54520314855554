import { Component, Input } from '@angular/core';

import { AcademicTerm } from './academic-term';


@Component({
  selector: 'app-academic-term-card',
  template: `
    <div id="academic-term-{{ record.id }}">
      <app-card-field [uniqueId]="record.id" [labelText]="'Term'" [fieldName]="'Term'">
        {{ record.season }} {{ record.year }} {{ record.season ? record.season : '' }}
      </app-card-field>
    </div>
    `,
})
export class AcademicTermCardComponent {

  @Input() record: AcademicTerm;

  constructor() { }
}
