<main>

<h1>Users</h1>

<app-list-alert [objectName]="'user'"
  [itemCreated]="itemCreated"
  [itemUpdated]="itemUpdated"
  [itemDeleted]="itemDeleted"
  >
</app-list-alert>


<form id="list-controls">
  <app-list-filter (onFilterEmitter)="onFilter($event)">
  </app-list-filter>

  <app-user-dialog buttonColor="primary"
    (onSubmitSuccessEmitter)="onItemCreate($event)"
    >
  </app-user-dialog>
</form>


<div class="panel">
  <div class="row">

    <table id="table-user-list" class="table table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Campus</th>
          <th *ngIf="userRole != 'campus user'">Last Login</th>
          <th *ngIf="userRole != 'campus user'">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of results">
          <td> {{ result.full_name }} </td>
          <td> <a href="mailto:{{ result.email }}">{{ result.email }}</a> </td>
          <td> {{ result.role }} </td>
          <td> {{ result.campus_str }} </td>
          <td *ngIf="userRole != 'campus user'">
            <span *ngIf='result.last_login'>
              {{ result.last_login | date: 'short' }}
            </span>
            <span *ngIf='!result.last_login'>
              Never
            </span>
          </td>

          <td *ngIf="userRole != 'campus user'">

            <app-user-dialog buttonColor="primary"
              (onSubmitSuccessEmitter)="onItemUpdate($event)"
              [recordId]="result.id"
              >
            </app-user-dialog>

            <app-user-deactivate-dialog
              [record]="result"
              (onActivateSuccessEmitter)="onItemActivated($event)"
              (onDeactivateSuccessEmitter)="onItemDeactivated($event)"
              >
            </app-user-deactivate-dialog>
          </td>

        </tr>
      </tbody>
    </table>

    <app-list-paginator [apiUrl]="'/user'"
      [nextUrl]="next" [previousUrl]="previous"
      [currentPage]="currentPage"
      (onPagingEmitter)="gotoPage($event)"
      >
    </app-list-paginator>

  </div>
</div>

</main>
