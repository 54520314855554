import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { CampusSystemService } from '../../campus-system/campus-system.service';
import { CampusSystem } from '../../campus-system/campus-system';

import { ReportsService } from '../reports.service';


@Component({
  selector: 'app-report-campus-system',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportCampusSystemComponent implements OnInit {

  reportData: object;
  campusSystemList: CampusSystem[];
  selectedCampusSystem: CampusSystem;
  startDate: Date;
  endDate: Date;

  constructor(
    private reportsService: ReportsService,
    private campusSystemService: CampusSystemService,
  ) { }

  ngOnInit() {
    this.initStartDate();
    this.initEndDate();
    this.getCampusSystems();
  }

  initStartDate() {
    // Go back 30 days from today.
    const date = new Date();
    date.setDate(date.getDate() - 30);
    this.startDate = date;
  }

  initEndDate() {
    const date = new Date();
    this.endDate = date;
  }

  async getCampusSystems() {
    try {
      const response = await this.campusSystemService.list();
      this.campusSystemList = response['results'] as CampusSystem[];
    } catch (e) {
      this.handleError(e);
    }
  }

  async runReportClicked() {
    this.reportData = await this.runReport();
  }

  async runReport(
    campusSystem = this.selectedCampusSystem,
    startDate = this.startDate,
    endDate = this.endDate
  ) {
    try {
      const response = await this.reportsService.reportCampusSystem(
        campusSystem, startDate, endDate
      );
      return Promise.resolve(response);
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  handleError(error: HttpErrorResponse) {
    console.error('ReportCampusSystemComponent Error', error);
  }
}
