import { Output, EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, interval} from 'rxjs';
import { startWith } from 'rxjs/operators';

import { AuthToken } from '../auth/auth-token';
import { BaseCrudService } from '../base/base-crud.service';
import { ExchangeRequestFile } from './exchange-request-file';


@Injectable()
export class ExchangeRequestFileService extends BaseCrudService<ExchangeRequestFile> {

  @Output() uploadProgressEmitter = new EventEmitter<number>();

  endpointUrl = `${this.apiHost}/api/exchange/requests`;

  private progressSource = new BehaviorSubject<number>(0);
  public progressAsync$ = this.progressSource.asObservable();
  timerAsync$ = interval(100).pipe(startWith(0));

  constructor(protected http: HttpClient) {
    super(http);
  }

  // service command
  updateProgress(x: number) {
    this.progressSource.next(x);
  }

  list(requestId: string) {
    const endpoint = `${this.endpointUrl}/${requestId}/files`;
    return super.list(endpoint);
  }

  listRaw(requestId: string) {
    const endpoint = `${this.endpointUrl}/${requestId}/files`;
    return super.listRaw(endpoint);
  }

  delete_file(request_id: string, file_id: string) {
    const endpoint = `${this.endpointUrl}/${request_id}/files/${file_id}`;

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.delete(endpoint, httpConfig)
      .toPromise()
      .then(() => null)
      .catch(this.handleError);
  }

  upload(requestId, fileToUpload: any): Promise<any> {
    return this.getPresignedUrl(requestId, fileToUpload)
      .then(response => this.sendFile(response['url'], fileToUpload));
  }

  sendFile(signedUrl: string, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('PUT', signedUrl, true);

      const signedUrlContentType = 'application/octet-stream';
      xhr.setRequestHeader('Content-Type', signedUrlContentType);

      // Success
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          return resolve();
        } else {
          return reject('XHR file upload error');
        }
      };

      // Error
      xhr.onerror = () => {
        return reject('XHR file upload error');
      };

      // Progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          let percent = Math.round((event.loaded / event.total) * 100);
          console.log(`Progress: ${percent}%`);
          this.updateProgress(percent);
        }
      };

      xhr.send(file);
    });
  }

  getPresignedUrl(requestId: string, file: File): Promise<any> {
    const apiUrl = `${this.apiHost}/api/exchange/upload-presigned-url/`;
    const data = {
      request_id: requestId,
      file_name: file.name,
      file_size: file.size
    };
    const payload = JSON.stringify(data);

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.post(apiUrl, payload, httpConfig)
      .toPromise()
      // .then(response => response)
      .catch(this.handleError);
  }

  getFileUploadParameters() {
    const apiUrl = `${this.apiHost}/api/exchange/upload-parameters/`;

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig)
      .toPromise()
      // .then(response => response)
      .catch(this.handleError);
  }

  getCampusStorageStats() {
    const apiUrl = `${this.apiHost}/api/exchange/campus-storage-stats/`;

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig)
      .toPromise()
      // .then(response => response)
      .catch(this.handleError);
  }

  getRequestCapacity(request_id: string) {
    const apiUrl = `${this.apiHost}/api/exchange/campus-storage-capacity/${request_id}`;

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig)
      .toPromise()
      // .then(response => response)
      .catch(this.handleError);
  }
}
