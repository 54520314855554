<div [formGroup]="formGroup" class="column-form-container">

  <mat-form-field>
    <input matInput placeholder="Full Name"
                    formControlName="full_name" required
                    [readonly]="isReadOnly('full_name')">

    <mat-error *ngIf="formGroup.controls.full_name.hasError('backend')">
      {{ formGroup.controls['full_name'].errors['backend'] }}
    </mat-error>
    <mat-error *ngIf="formGroup.controls.full_name.hasError('required')">
      Full name is <strong>required</strong>
    </mat-error>
  </mat-form-field>


  <mat-form-field>
    <input matInput placeholder="Email"
                    formControlName="email" required
                    [readonly]="isReadOnly('email') || !isBlankRecord()">

    <mat-error *ngIf="formGroup.controls.email.hasError('backend')">
      {{ formGroup.controls['email'].errors['backend'] }}
    </mat-error>
    <mat-error *ngIf="formGroup.controls.email.hasError('required')">
      Email address is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="formGroup.controls.email.hasError('email')
                   && !formGroup.controls.email.hasError('required')">
      Email address is <strong>invalid</strong>
    </mat-error>
  </mat-form-field>


  <mat-form-field>
    <input matInput placeholder="Phone"
                    formControlName="phone"
                    [readonly]="isReadOnly('phone')">

    <mat-error *ngIf="formGroup.controls.phone.hasError('backend')">
      {{ formGroup.controls['phone'].errors['backend'] }}
    </mat-error>
  </mat-form-field>


  <mat-form-field>
    <input matInput placeholder="Position"
                    formControlName="position" required
                    [readonly]="isReadOnly('position')">

    <mat-error *ngIf="formGroup.controls.position.hasError('backend')">
      {{ formGroup.controls['position'].errors['backend'] }}
    </mat-error>
    <mat-error *ngIf="formGroup.controls.position.hasError('required')">
      Position is <strong>required</strong>
    </mat-error>
  </mat-form-field>


  <mat-form-field *ngIf="!isSystemAdmin()">
    <input matInput placeholder="Role"
                    formControlName="role"
                    readonly>
  </mat-form-field>
  <mat-form-field *ngIf="isSystemAdmin()">
    <mat-select matInput placeholder="Role"
                         formControlName="role">
        <mat-option value="Campus User">Campus User</mat-option>
        <mat-option value="Campus Administrator">Campus Administrator</mat-option>
        <mat-option value="System Administrator">System Administrator</mat-option>
    </mat-select>
  </mat-form-field>


  <span *ngIf="isSystemAdmin()">
    <app-campus-autocomplete-field
      [parentFormGroup]="formGroup"
      [parentFormControlName]="'campus'"
      [readOnly]="isReadOnly('campus')"
      [required]="true"
      >
    </app-campus-autocomplete-field>
  </span>

</div>

