<mat-card class="password-reset-card">

  <mat-card-title> Password Reset Confirmation </mat-card-title>

  <mat-card-content>

    <div id="success-notification" class="alert alert-success" *ngIf="success">
      Password has been reset.
      You may now <a id="login-link" routerLink="/">sign in</a>
    </div>
    <div id="failed-notification" class="alert alert-danger"
         *ngIf="failed && failedNotification">
      {{ failedNotification }}
    </div>

    <form [formGroup]="resetConfirmForm" *ngIf="active" (ngSubmit)="onSubmit()">

      <mat-form-field class="password-reset-field">
        <input matInput placeholder="New Password"
            type="password"
            formControlName="new_password1"
            required
            >
        <mat-error *ngIf="resetConfirmForm.controls.new_password1.hasError('backend')">
          {{ resetConfirmForm.controls['new_password1'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="resetConfirmForm.controls.new_password1.hasError('required')">
          New password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="password-reset-field">
        <input matInput placeholder="Confirm New Password"
            type="password"
            formControlName="new_password2"
            required
            >
        <mat-error *ngIf="resetConfirmForm.controls.new_password2.hasError('backend')">
          {{ resetConfirmForm.controls['new_password2'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="resetConfirmForm.controls.new_password2.hasError('required')">
          Password confirmation is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit">
        Reset Password
      </button>
    </form>

  </mat-card-content>

</mat-card>
