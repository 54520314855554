import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthToken } from '../auth/auth-token';
import { BaseCrudService } from '../base/base-crud.service';
import { User } from './user';


export const PROFILE_PERSIST_KEY = 'userProfile';
export const ROLE_PERSIST_KEY = 'userRole';

@Injectable()
export class UserService extends BaseCrudService<User> {

  endpointUrl = `${this.apiHost}/api/users`;

  persistedProfile = this._getProfileInLocalStorage();
  persistedRole = this._getRoleState();

  constructor(protected http: HttpClient) {
    super(http);
  }

  public updateActive(id: string, active = true) {
    const apiUrl = this.endpointUrl + `/${id}/active/`;
    const data = JSON.stringify({'active': active});

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.put(apiUrl, data, httpConfig)
      .toPromise()
      // .then(response => response)
  }

  public update(record: User, apiUrl = ''): Promise<User> {
    // Don't update read only or active fields.
    delete record.last_login;
    delete record.is_active;

    return super.update(record, apiUrl)
      .then(serverRecord => {
        if (this._isCurrentUser(serverRecord)) {
          this._storeProfileInLocalStorage(serverRecord);
        }
        return serverRecord;
      });
  }

  public getProfile(): Promise<User> {
    const userProfile = this._getProfileInLocalStorage();
    if (userProfile) {
      return Promise.resolve(userProfile);
    }
    return this._getProfileFromRemote();
  }

  protected _isCurrentUser(record: User): boolean {
    const userProfile = this._getProfileInLocalStorage();
    if (userProfile === undefined) {
      return false;
    }
    return userProfile.id === record.id;
  }

  protected async _getProfileFromRemote(): Promise<User> {
    try {
      const apiUrl = this.endpointUrl + '/profile/';
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      const response = await this.http.get(apiUrl, httpConfig).toPromise();
      this._storeProfileInLocalStorage(response as User);
      return response as User;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  protected _getProfileInLocalStorage(): User | undefined {
    if (PROFILE_PERSIST_KEY in localStorage) {
      let profileString = localStorage[PROFILE_PERSIST_KEY];
      let profile = JSON.parse(profileString);
      return profile;
    }
    return undefined;
  }

  protected _storeProfileInLocalStorage(profile: User): Promise<any> {
    this.persistedProfile = profile;

    let profileString = JSON.stringify(profile);
    localStorage[PROFILE_PERSIST_KEY] = profileString;

    return Promise.resolve();
  }

  protected _clearProfileInLocalStorage(): void {
    if (PROFILE_PERSIST_KEY in localStorage) {
      localStorage.removeItem(PROFILE_PERSIST_KEY);
    }
  }

  /* Get the role of the current user if no userId specified.
   * Otherwise, get the role of the specified user.
   * @param {string} UUID of the user who's role to get.
   * @return {string} The role of the current or specified user.
   */
  public getRole(userId = ''): Promise<string> {
    let apiUrl = this.endpointUrl + '/roles/';

    // Append UUID to get specific role.
    if (userId !== '') {
      apiUrl += userId + '/';
    }

    // Try getting stored role.
    if (userId === '') {
      const storedRole = this._getRoleState();
      if (storedRole !== '') {
        return Promise.resolve(storedRole);
      }
    }

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig)
      .toPromise()
      .then(response => {
        let data = response['role'];
        this._storeRoleState(data);
        return Promise.resolve(data);
      })
      .catch(this.handleError);
  }

  /* Persist the current user's role in local storage.
   * @param {string} String representation of current user's role.
   */
  protected _storeRoleState(role = ''): void {
    localStorage[ROLE_PERSIST_KEY] = role;
  }

  /* Retreive the current user's role from local storage.
   */
  protected _getRoleState(): string {
    if (ROLE_PERSIST_KEY in localStorage) {
      return localStorage[ROLE_PERSIST_KEY];
    }
    return '';
  }

  /* Remove the current user's role from local storage.
   */
  protected _clearRoleState(): void {
    if (ROLE_PERSIST_KEY in localStorage) {
      localStorage.removeItem(ROLE_PERSIST_KEY);
    }
  }

}
