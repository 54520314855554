import { environment } from '../../../environments/environment';
import { Component, Input } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';

import { ExchangeRequest } from '../exchange-request';
import { ExchangeRequestService } from '../exchange-request.service';


@Component({
  selector: 'app-exchange-request-base-list',
  templateUrl: './exchange-request-base-list.component.html',
  styleUrls: ['./exchange-request-base-list.component.css']
})
export class ExchangeRequestBaseListComponent extends BaseListComponent<ExchangeRequest> {

  @Input() baseUrl = '';
  @Input() declare apiUrl: string;
  @Input() declare viewTitle: string;

  @Input() campus_table_heading: string;
  @Input() campus_table_field: string;

  protected apiHost = environment.apiHost;

  filter = {
    status: {
      open: true,
      complete: false,
      cancelled: false,
    },
  };

  constructor(
    protected dataService: ExchangeRequestService
  ) {
    super(dataService);
  }

  updateStatusFilter(filter: string, value = null): void {
    const statusList = Object.keys(this.filter.status);
    if (statusList.indexOf(filter) >= 0) {
      if (value == null) {
        this.filter.status[filter] = !this.filter.status[filter];
      } else {
        this.filter.status[filter] = value;
      }
    }

    this.list();
  }

  refreshApiParams(): any {
    let enabled_status = [];
    for (let key in this.filter.status) {
      if (this.filter.status[key]) {
        enabled_status.push(key);
      }
    }
    this.apiQueryParams['status'] = enabled_status.join('+');
  }

  list(params = this.apiQueryParams, apiUrl?): Promise<any> {

    if (!apiUrl) {
      apiUrl = this.apiUrl;
    }
    apiUrl = `${this.apiHost}/${apiUrl}`;

    this.refreshApiParams();
    return super.list(params, apiUrl);
  }
}
