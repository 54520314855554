import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { AuthGuardService } from './route-guard/auth-guard.service';
import { HomeRedirectGuardService } from './route-guard/home-redirect-guard.service';
import { AuthRoutingModule } from './auth-routing.module';

import { SessionService } from './session.service';
import { PasswordService } from './password.service';
import { RegistrationService } from './registration.service';
import { PasswordPolicyComponent } from './password-policy.component';
import { LoginComponent } from './login/login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LogoutComponent } from './logout.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordResetFormComponent } from './password-reset-form/password-reset-form.component';
import { PasswordResetConfirmFormComponent } from './password-reset-confirm-form/password-reset-confirm-form.component';
import { PasswordResetConfirmComponent } from './password-reset-confirm/password-reset-confirm.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PasswordChangeFormComponent } from './password-change-form/password-change-form.component';
import { RegisterComponent } from './register/register.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { RegisterConfirmComponent } from './register-confirm/register-confirm.component';
import { RegisterConfirmFormComponent } from './register-confirm-form/register-confirm-form.component';
import { AuthListComponent } from './auth-list/auth-list.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule,
  ],
  exports: [
    PasswordPolicyComponent,
    LoginComponent,
    LoginFormComponent,
    LogoutComponent,
    RegisterComponent,
    RegisterFormComponent,
  ],
  declarations: [
    PasswordPolicyComponent,
    LoginComponent,
    LoginFormComponent,
    LogoutComponent,
    PasswordResetComponent,
    PasswordResetFormComponent,
    PasswordResetConfirmFormComponent,
    PasswordResetConfirmComponent,
    PasswordChangeComponent,
    PasswordChangeFormComponent,
    RegisterComponent,
    RegisterFormComponent,
    RegisterConfirmComponent,
    RegisterConfirmFormComponent,
    AuthListComponent,
  ],
  providers: [
    SessionService,
    PasswordService,
    RegistrationService,
    AuthGuardService,
    HomeRedirectGuardService,
  ],
})
export class AuthModule { }
