import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';

import { Book } from '../../book/book';
import { FileFormat } from '../../file-format/file-format'
import { FileFormatService } from '../../file-format/file-format.service';
import { HoldingBookSource } from '../../holding-book-source/holding-book-source'
import { HoldingBookSourceService } from '../../holding-book-source/holding-book-source.service';


@Component({
  selector: 'app-batch-isbn-list',
  templateUrl: './batch-isbn-list.component.html',
  styleUrls: ['./batch-isbn-list.component.css']
})
export class BatchIsbnListComponent implements OnInit {

  @Input() bookList: Book[] = [];
  formatList: FileFormat[];
  sourceList: HoldingBookSource[];

  constructor(
    protected holdingBookSourceService: HoldingBookSourceService,
    protected fileFormatService: FileFormatService,
  ) { }

  ngOnInit() {
    try {
      this.getFileFormats();
      this.getHoldingSources();
    } catch (e) {
      this.handleError(e);
    }
  }

  async getFileFormats() {
    try {
      const response = await this.fileFormatService.list();
      this.formatList = response['results'] as FileFormat[];
    } catch (e) {
      this.handleError(e);
    }
  }

  async getHoldingSources() {
    try {
      const response = await this.holdingBookSourceService.list();
      this.sourceList = response['results'] as HoldingBookSource[];
    } catch (e) {
      this.handleError(e);
    }
  }

  onDoneClicked(book: Book) {
    for (let i = 0; i < this.bookList.length; i++) {
      if (this.bookList[i].id === book.id) {
        this.bookList.splice(i, 1);
      }
    }
  }

  handleError(error: HttpErrorResponse) {
    console.error('ISBN List Component Error', error);
  }
}
