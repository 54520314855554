import { Component, OnInit } from '@angular/core';

import { interval } from 'rxjs';

import { SessionService } from '../../auth/session.service';
import { UserService } from '../../user/user.service';
import { ExchangeRequestService } from '../../exchange-request/exchange-request.service';


@Component({
  selector: 'app-auth-navbar',
  templateUrl: './auth-navbar.component.html',
  styleUrls: ['./auth-navbar.component.css']
})
export class AuthNavbarComponent implements OnInit {

  badgeStat = {
    'request_in': 0,
    'request_out': 0,
  };
  showAdmin = false;
  userRole: any;

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private requestService: ExchangeRequestService,
  ) { }

  ngOnInit() {
    this.resetBadgeStat();

    // Initial call to get counts on reloads.
    this.getBadgeStats();
    this.getUserProfile();
    this.checkUserRole();
    // Polling call to get updates.
    this.pollBadgeStats();
  }

  resetBadgeStat() {
    this.badgeStat = {
      'request_in': 0,
      'request_out': 0,
    };
  }

  pollBadgeStats(): any {
    const seconds = 60;
    const duration = 1000 * seconds;
    return interval(duration)
      .subscribe(() => {
        if (this.sessionService.isValidSession()) {
          this.getBadgeStats()
            .catch(err => {
              console.log('Error polling badge statistics');
              console.error(err);
            });
        }
      });
  }

  getBadgeStats(): Promise<any> {
    if (!this.sessionService.isValidSession()) {
      return Promise.reject('Unauthenicated client cannot get badge stats');
    }

    return this.requestService.count()
      .then(response => {
        if (!response) { throw 'Response is invalid'; }
        this.badgeStat = response;
      })
      .catch(err => {
        console.log('Error requesting badge statistics');
        console.error(err);
      });
  }

  getUserProfile(): any {
    this.userService.getProfile()
  }

  checkUserRole(): any {
    if (!this.userRole) {
      this.userService.getRole()
        .then(userRole => this.userRole = userRole)
        .then(() => this.setAdminView());
    } else {
      this.setAdminView();
    }
  }

  setAdminView(): void {
    if (this.userRole.indexOf('Campus Administrator') >= 0) {
      this.showAdmin = true;
    }
    if (this.userRole.indexOf('System Administrator') >= 0) {
      this.showAdmin = true;
    }
  }
}
