import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.css']
})
export class FrontPageComponent implements OnInit {

  section = {
    'introduction': true,
    'features': false,
    'projectStatus': false,
    'costSavings': false,
    'liability': false,
    'existingSystems': false,
  }

  activeSection = 'introduction';

  constructor(private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle( 'Welcome to AIMHub' );
  }

  updateSection(section: string) {
    this.activeSection = section;
  }

}
