import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseCrudService } from '../base/base-crud.service';
import { UserPreference } from './user';


@Injectable()
export class UserPreferenceService extends BaseCrudService<UserPreference> {

  endpointUrl = `${this.apiHost}/api/users/preferences`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  read(id = ''): Promise<UserPreference> {
    return super.read(id, this.endpointUrl);
  }

  update(record: UserPreference): Promise<UserPreference> {
    return super.update(record, this.endpointUrl);
  }

}
