import { Component, OnInit } from '@angular/core';

import { BaseFormComponent } from '../../base/base-form.component';
import { User } from '../../user/user';
import { UserService } from '../../user/user.service';

import { AcademicTerm } from '../academic-term';
import { AcademicTermService } from '../academic-term.service';
import { initAcademicTermFormGroup } from '../academic-term-fields/academic-term-fields.component';
import { Input } from '@angular/core';

/**
 * AcademicTerm form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-academic-term-form',
  templateUrl: './academic-term-form.component.html',
  styleUrls: ['./academic-term-form.component.css']
})
export class AcademicTermFormComponent extends BaseFormComponent<AcademicTerm> implements OnInit {

  @Input() injectedRecordId: string = '';  // Explicitly redeclare this as an input
  currentUser: User;

  constructor(
    protected dataService: AcademicTermService,
    protected userService: UserService,
  ) {
    super(AcademicTerm, initAcademicTermFormGroup, dataService);
  }

  ngOnInit() {
    this.initCurrentUser()
      .then(() => this.setDefaultValues())
      .then(() => super.ngOnInit())
    ;
  }

  initCurrentUser(): Promise<any> {
    return this.userService.getProfile()
      .then(currentUser => {
        this.currentUser = currentUser;
      })
      .catch(err => this.handleServerErrors(err));
  }

  /* Initialize the default record with the current user's campus.
   * If campus admin, leave it blank.
   */
  setDefaultValues(): void {
    if (!this.isSystemAdmin()) {
      this.defaultRecord = new AcademicTerm();
      this.defaultRecord.campus = this.currentUser.campus;
    }
  }
  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
    // Get target form values.
    const {
      year = '', season = '', session = ''
    } = this.formComposite.value.record;

    // Format clipboard contents.
    this.clipboardText = `Term: ${season} ${year}`;
    if (session && session !== '') {
      this.clipboardText += `, ${session}`;
    }
  }

  isSystemAdmin(): boolean {
    if (this.currentUser !== undefined) {
      if (this.currentUser.role === 'System Administrator') {
        return true;
      }
    }
    return false;
  }
}
