
export class AcademicTerm {

  constructor(
    public id = '',
    public year: number = undefined,
    public season = '',
    public session = '',
    public campus = '',
    public campus_str = '',
    public created_date = '',
    public modified_date = '',
  ) { }

}
