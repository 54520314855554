<button type="button" class="btn {{ buttonStyle }}"
        (click)="requestCreateModal.show()">
  {{ buttonText }}
</button>


<div bsModal #requestCreateModal="bs-modal" class="modal fade"
     [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog"
     aria-labelledby="requestCreateModalLabel" aria-hidden="true">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title pull-left" id="requestCreateModalLabel">
          Confirm Your Request
        </h3>
        <button type="button" class="close pull-right"
                (click)="onCloseClicked()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body row">

        <div id="requested-item" class="col-sm-6">
          <p *ngIf="holding"> <strong>Campus:</strong> {{ holding.campus_str }} </p>
          <p *ngIf="holding"> <strong>Format:</strong> {{ holding.file_format_str }} </p>

          <app-book-card [record]="record"> </app-book-card>
        </div>

        <form  id="confirm-request" class="form-group col-sm-6">
          <div>
            <label for="academic-term">Academic Term</label>
            <select id="academic-term" class="form-control"
              [(ngModel)]="selectedTermId"
              [ngModelOptions]="{standalone: true}"
              >
              <option #selectedTerm *ngFor="let term of academicTerms" [value]="term.id">
              {{ term.season }} {{ term.year }} {{ term.session }}
              </option>
            </select>
          </div>

          <div class="checkbox">
            <label>
              <input #certifyRequest id="certify-request"
                type="checkbox" class="checkbox pull-right"
                [checked]="certified" (change)="certified = !certified"/>
              I certify that this book is being requested for a student with a
              verified print disability, in accordance with local and federal laws.
            </label>
          </div>

          <button type="submit" class="btn btn-primary"
            [disabled]="!certified"
            (click)="onSubmitClicked()"
            >
            Submit Request
          </button>
          <button type="button" class="btn btn-default"
            (click)="onCloseClicked()"
            >
            Cancel
          </button>
        </form>

      </div>
    </div>
  </div>
</div>

