import { Component } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';
import { ExchangeRequestStatus } from '../exchange-request-status';
import { ExchangeRequestStatusService } from '../exchange-request-status.service';


@Component({
  selector: 'app-exchange-request-status-list',
  templateUrl: './exchange-request-status-list.component.html',
  styleUrls: ['./exchange-request-status-list.component.css']
})
export class ExchangeRequestStatusListComponent extends BaseListComponent<ExchangeRequestStatus> {
  baseUrl = 'exchange/request/status/';
  viewTitle = 'Exchange Request Statuss - AIMHub';

  constructor(
    protected dataService: ExchangeRequestStatusService
  ) {
    super(dataService);
  }
}
