import {Component, Input, OnInit} from '@angular/core';

import { BaseFormComponent } from '../../base/base-form.component';

import { User } from '../user';
import { UserService } from '../user.service';
import { initUserFormGroup } from '../user-fields/user-fields.component';


/**
 * User form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
})
export class UserFormComponent extends BaseFormComponent<User> implements OnInit {
  @Input() declare injectedRecordId: string;
  currentUser: User;

  constructor(
    protected dataService: UserService,
  ) {
    super(User, initUserFormGroup, dataService);
  }

  ngOnInit() {
    this.initCurrentUser()
      .then(() => this.setDefaultValues())
      .then(() => super.ngOnInit())
    ;
  }

  initCurrentUser(): Promise<any> {
    return this.dataService.getProfile()
      .then(currentUser => {
        this.currentUser = currentUser;
      })
      .catch(err => this.handleServerErrors(err));
  }

  /* Initialize the default record with the current user's campus.
   */
  setDefaultValues(): void {
    this.defaultRecord = new User();
    this.defaultRecord.campus = this.currentUser.campus;
  }

  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
    // Get target form values.
    const {
      full_name = '',
      email = '',
      position = '',
      campus_str = '',
    } = this.formComposite.value.record;

    // Format clipboard contents.
    this.clipboardText = [
      `Name: ${full_name}`,
      `Email: ${email}`,
      `Position: ${position}`,
      `Campus: ${campus_str}`,
    ].join('\n');
  }
}
