<div class="row">
  <h2 class="list-group-item-heading col-xs-11 pull-left">
    {{ book.title | slice:0:70 }}
    <span *ngIf="book.title.length > 70">...</span>
  </h2>

  <button class="dialog-close-button" (click)="doneClicked()" aria-label="Close holding">
    <mat-icon>cancel</mat-icon>
  </button>
</div>


<div class="row">
  <div class="col-sm-4">
    <h3>Book Information</h3>
    <app-book-card [record]="book"> </app-book-card>
  </div>


  <div class="col-sm-8">
    <h3>Formats</h3>

    <app-list-alert [objectName]="'holding'"
      [itemCreated]="holdingCreated"
      [itemUpdated]="holdingUpdated"
      [itemDeleted]="holdingDeleted"
      >
    </app-list-alert>

    <div class="col-sm-5">
      <app-format-control-group
        [formatList]="formatList"
        (onAddFormatClickedEmitter)="onAddFormatClicked($event)"
        >
      </app-format-control-group>
    </div>

    <div class="col-sm-7">
      <app-format-list
        [holdingList]="holdingList"
        [formatList]="formatList"
        [sourceList]="sourceList"
        (onDeleteClickedEmitter)="onDeleteClicked($event)"
        >
      </app-format-list>
    </div>
  </div>

</div>

<div class="row list-item-footer">
  <span class="pull-right">
    <button mat-raised-button
      id="apply-button"
      type="button"
      color="primary"
      (click)="applyClicked()"
      >
      Apply
    </button>

    <button mat-raised-button
      id="close-button"
      type="button"
      (click)="doneClicked()"
      >
      Close
    </button>
  </span>
</div>
