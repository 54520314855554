<main>

<h1>Academic Terms</h1>

<app-list-alert [objectName]="'academic term'"
  [itemCreated]="itemCreated"
  [itemUpdated]="itemUpdated"
  [itemDeleted]="itemDeleted"
  >
</app-list-alert>


<form id="list-controls">
  <app-list-filter (onFilterEmitter)="onFilter($event)">
  </app-list-filter>

  <app-academic-term-dialog buttonColor="primary"
    (onSubmitSuccessEmitter)="onItemCreate($event)"
    >
  </app-academic-term-dialog>
</form>


<div class="panel">
  <div class="row">

    <table id="table-academic-term-list" class="table table-hover">
      <thead>
        <tr>
          <th>Year</th>
          <th>Season</th>
          <th>Session</th>
          <th *ngIf="isSystemAdmin()">Campus</th>
          <th> Actions </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of results">
          <td>{{ result.year }}</td>
          <td>{{ result.season }}</td>
          <td>{{ result.session }}</td>
          <td *ngIf="isSystemAdmin()"> {{ result.campus_str }} </td>

          <td>
            <span *ngIf="!canModifiy(result)">
              No available actions
            </span>

            <app-academic-term-dialog buttonColor="primary"
              *ngIf="canModifiy(result)"
              (onSubmitSuccessEmitter)="onItemUpdate($event)"
              [recordId]="result.id"
              >
            </app-academic-term-dialog>

            <app-academic-term-delete-dialog
              *ngIf="canModifiy(result)"
              (onDeleteSuccessEmitter)="onItemDelete($event)"
              [record]="result"
              >
            </app-academic-term-delete-dialog>
          </td>

        </tr>
      </tbody>
    </table>

    <app-list-paginator [apiUrl]="'/academic-term'"
      [nextUrl]="next" [previousUrl]="previous"
      [currentPage]="currentPage"
      (onPagingEmitter)="gotoPage($event)"
      >
    </app-list-paginator>

  </div>
</div>

</main>
