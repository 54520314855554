import { IServiceModel } from '../base/service.interface';
import { Publisher } from '../publisher/publisher';


export class Book implements IServiceModel {

  constructor(
    // Required fields
    public title = '',
    public isbn = '',
    public authors = '',

    // Optional fields
    public copyright_year?: number,
    public publisher?: string | Publisher,
    public publisher_str?: string,
    public edition?: string,

    // Auto-populated fields
    public id?: string,
    public created_date?: string,
    public modified_date?: string,
    public holding_list = []
  ) { }

}
