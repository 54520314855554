import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../../user/user';

/*
 * Return a list of users with campus or system administrator roles.
 * Takes a list of users.
 * Usage:
 *   userList | adminUserFilter
*/
@Pipe({name: 'adminUserFilter'})
export class AdminUserFilterPipe implements PipeTransform {
  transform(value: User[]): User[] {
    return value.filter(u => this.isAdmin(u));
  }

  isAdmin(user: User): boolean {
    if (user.role === 'Campus Administrator') {
      return true;
    } else if (user.role === 'System Administrator') {
      return true;
    } else {
      return false;
    }
  }
}
