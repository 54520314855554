import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-dialog-header',
  template: `
    <h2 mat-dialog-title>
      <span>
        <ng-content></ng-content>
      </span>

      <button mat-dialog-close class="dialog-close-button" aria-label="Close dialog">
        <mat-icon>cancel</mat-icon>
      </button>
    </h2>
    `,
})
export class DialogHeaderComponent {

  @Input() heading = '';

  constructor() { }
}
