import { Component, Input, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';


@Component({
  selector: 'app-state-autocomplete-field',
  template: `
      <div *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
        <mat-form-field>

          <input matInput placeholder="State" aria-label="State"
                [matAutocomplete]="stateAuto"
                [required]="required" [readonly]="readOnly"
                formControlName="state"
                >

          <mat-autocomplete #stateAuto="matAutocomplete">
            <mat-option *ngFor="let state of filteredStates | async" [value]="state.abbreviation">
              {{ state.name }} ({{ state.abbreviation }})
            </mat-option>
          </mat-autocomplete>

          <mat-error *ngIf="parentFormGroup.controls.state.hasError('backend')">
            {{ parentFormGroup.controls.state.errors['backend'] }}
          </mat-error>
          <mat-error *ngIf="parentFormGroup.controls.state.hasError('required')">
            State is <strong>required</strong>
          </mat-error>

        </mat-form-field>
      </div>

      <div *ngIf="!parentFormGroup">
        <mat-form-field>
           State autocomplete must be associated with a parent FormGroup object.
        </mat-form-field>
      </div>
    `,
})
export class StateAutocompleteFieldComponent implements OnInit {

  // Inputs for binding to parent form's control.
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormControlName: string;

  // Inputs for self control.
  @Input() myControl: FormControl;

  // Other inputs.
  @Input() readOnly = false;
  @Input() required = false;

  filteredStates: Observable<any[]>;
  states = [
    {abbreviation: 'AL', name: 'Alabama'},
    {abbreviation: 'AK', name: 'Alaska'},
    {abbreviation: 'AZ', name: 'Arizona'},
    {abbreviation: 'AR', name: 'Arkansas'},
    {abbreviation: 'CA', name: 'California'},
    {abbreviation: 'CO', name: 'Colorado'},
    {abbreviation: 'CT', name: 'Connecticut'},
    {abbreviation: 'DE', name: 'Delaware'},
    {abbreviation: 'DC', name: 'District of Columbia'},
    {abbreviation: 'FL', name: 'Florida'},
    {abbreviation: 'GA', name: 'Georgia'},
    {abbreviation: 'HI', name: 'Hawaii'},
    {abbreviation: 'ID', name: 'Idaho'},
    {abbreviation: 'IL', name: 'Illinois'},
    {abbreviation: 'IN', name: 'Indiana'},
    {abbreviation: 'IA', name: 'Iowa'},
    {abbreviation: 'KS', name: 'Kansas'},
    {abbreviation: 'KY', name: 'Kentucky'},
    {abbreviation: 'LA', name: 'Louisiana'},
    {abbreviation: 'ME', name: 'Maine'},
    {abbreviation: 'MD', name: 'Maryland'},
    {abbreviation: 'MA', name: 'Massachusetts'},
    {abbreviation: 'MI', name: 'Michigan'},
    {abbreviation: 'MN', name: 'Minnesota'},
    {abbreviation: 'MS', name: 'Mississippi'},
    {abbreviation: 'MO', name: 'Missouri'},
    {abbreviation: 'MT', name: 'Montana'},
    {abbreviation: 'NE', name: 'Nebraska'},
    {abbreviation: 'NV', name: 'Nevada'},
    {abbreviation: 'NH', name: 'New Hampshire'},
    {abbreviation: 'NJ', name: 'New Jersey'},
    {abbreviation: 'NM', name: 'New Mexico'},
    {abbreviation: 'NY', name: 'New York'},
    {abbreviation: 'NC', name: 'North Carolina'},
    {abbreviation: 'ND', name: 'North Dakota'},
    {abbreviation: 'OH', name: 'Ohio'},
    {abbreviation: 'OK', name: 'Oklahoma'},
    {abbreviation: 'OR', name: 'Oregon'},
    {abbreviation: 'PA', name: 'Pennsylvania'},
    {abbreviation: 'RI', name: 'Rhode Island'},
    {abbreviation: 'SC', name: 'South Carolina'},
    {abbreviation: 'SD', name: 'South Dakota'},
    {abbreviation: 'TN', name: 'Tennessee'},
    {abbreviation: 'TX', name: 'Texas'},
    {abbreviation: 'UT', name: 'Utah'},
    {abbreviation: 'VT', name: 'Vermont'},
    {abbreviation: 'VA', name: 'Virginia'},
    {abbreviation: 'WA', name: 'Washington'},
    {abbreviation: 'WV', name: 'West Virginia'},
    {abbreviation: 'WI', name: 'Wisconsin'},
    {abbreviation: 'WY', name: 'Wyoming'}
  ]

  constructor() { }

  ngOnInit() {
    this._bindFormControl();
    this._applyOptionFilter();
  }

  /* Initialize the FormControl object with a passed instance, or a new instance.
   */
  private _bindFormControl(): void {

    // Init with FormControl in parent FormGroup.
    if (this.parentFormControlName && this.parentFormGroup) {
      const name = this.parentFormControlName;
      this.myControl = <FormControl>this.parentFormGroup.controls[name];

    // Init with new FormControl.
    } else if (!this.myControl) {
      this.myControl = new FormControl();
    }
  }

  /* Apply the autocomplete filter to the FormControl.
   * The filter will run each time the control's value changes.
   */
  private _applyOptionFilter(): void {
    this.filteredStates = this.parentFormGroup.controls[this.parentFormControlName].valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this.filterStates(state) : this.states.slice())
      );
  }

  filterStates(name: string) {
    return this.states.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
}
