import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { HoldingBookSourceService } from './holding-book-source.service';
import { HoldingBookSourceRoutingModule } from './holding-book-source-routing.module';

import { HoldingBookSourceListComponent } from './holding-book-source-list/holding-book-source-list.component';
import { HoldingBookSourceCardComponent } from './holding-book-source-card.component';
import { HoldingBookSourceFieldsComponent } from './holding-book-source-fields/holding-book-source-fields.component';
import { HoldingBookSourceFormComponent } from './holding-book-source-form/holding-book-source-form.component';
import {
  HoldingBookSourceDialogComponent, HoldingBookSourceDialogContentComponent
} from './holding-book-source-dialog/holding-book-source-dialog.component';
import {
  HoldingBookSourceDeleteDialogComponent, HoldingBookSourceDeleteDialogContentComponent
} from './holding-book-source-delete-dialog/holding-book-source-delete-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HoldingBookSourceRoutingModule,
  ],
  exports: [
    HoldingBookSourceListComponent,
    HoldingBookSourceCardComponent,
    HoldingBookSourceFieldsComponent,
    HoldingBookSourceDialogComponent,
    HoldingBookSourceDialogContentComponent,
  ],
  declarations: [
    HoldingBookSourceListComponent,
    HoldingBookSourceCardComponent,
    HoldingBookSourceFieldsComponent,
    HoldingBookSourceFormComponent,
    HoldingBookSourceDialogComponent,
    HoldingBookSourceDialogContentComponent,
    HoldingBookSourceDeleteDialogComponent,
    HoldingBookSourceDeleteDialogContentComponent,
  ],
  providers: [
    HoldingBookSourceService,
  ],
})
export class HoldingBookSourceModule { }
