<div [formGroup]="formGroup" class="column-form-container">

  <mat-form-field>
    <input matInput placeholder="Campus Name"
                    formControlName="name" required
                    [readonly]="isReadOnly('name')">

    <mat-error *ngIf="formGroup.controls.name.hasError('backend')">
      {{ formGroup.controls['name'].errors['backend'] }}
    </mat-error>
    <mat-error *ngIf="formGroup.controls.name.hasError('required')">
      Campus name is <strong>required</strong>
    </mat-error>

  </mat-form-field>


  <mat-form-field>
    <input matInput placeholder="Address 1" formControlName="address1" required
           [readonly]="isReadOnly('address1')">

    <mat-error *ngIf="formGroup.controls.address1.hasError('backend')">
      {{ formGroup.controls['address1'].errors['backend'] }}
    </mat-error>
    <mat-error *ngIf="formGroup.controls.address1.hasError('required')">
      Address is <strong>required</strong>
    </mat-error>

  </mat-form-field>


  <mat-form-field>
    <input matInput placeholder="Address 2" formControlName="address2"
           [readonly]="isReadOnly('address2')">

    <mat-error *ngIf="formGroup.controls.address2.hasError('backend')">
      {{ formGroup.controls['address2'].errors['backend'] }}
    </mat-error>

  </mat-form-field>


  <mat-form-field>
    <input matInput placeholder="City" formControlName="city"
           required [readonly]="isReadOnly('city')">

    <mat-error *ngIf="formGroup.controls.city.hasError('backend')">
      {{ formGroup.controls['city'].errors['backend'] }}
    </mat-error>
    <mat-error *ngIf="formGroup.controls.city.hasError('required')">
      City is <strong>required</strong>
    </mat-error>

  </mat-form-field>


  <app-state-autocomplete-field
    [parentFormGroup]="formGroup"
    [parentFormControlName]="'state'"
    [readOnly]="isReadOnly('state')"
    [required]="true"
    >
  </app-state-autocomplete-field>


  <mat-form-field>
    <input matInput placeholder="Postal Code"
           formControlName="postal_code" required
           [readonly]="isReadOnly('postal_code')">

    <mat-error *ngIf="formGroup.controls.postal_code.hasError('backend')">
      {{ formGroup.controls['postal_code'].errors['backend'] }}
    </mat-error>
    <mat-error *ngIf="formGroup.controls.postal_code.hasError('required')">
      Postal code is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="formGroup.controls.postal_code.hasError('pattern')">
      Valid postal codes are in the form
      <strong>12345</strong> or <strong>12345-6789</strong>
    </mat-error>
  </mat-form-field>


  <mat-form-field *ngIf="campusUserList && !isReadOnly('primary_contact')">
    <mat-select matInput placeholder="Primary Contact"
                formControlName="primary_contact"
                [required]="!isBlankRecord()"
                >
      <mat-option *ngFor="let campusUser of campusUserList | adminUserFilter" [value]="campusUser.id">
        {{ campusUser.full_name }} <{{ campusUser.email }}>
      </mat-option>
    </mat-select>

    <mat-error *ngIf="formGroup.controls.primary_contact.hasError('backend')">
      {{ formGroup.controls['primary_contact'].errors['backend'] }}
    </mat-error>
    <mat-error *ngIf="formGroup.controls.primary_contact.hasError('required')">
      Primary contact is <strong>required</strong>
    </mat-error>

  </mat-form-field>

  <mat-form-field *ngIf="!campusUserList || isReadOnly('primary_contact')">
    <input matInput placeholder="Primary Contact"
          [required]="!isBlankRecord()"
          formControlName="primary_contact_str" disabled readonly>
  </mat-form-field>

  <app-campus-system-autocomplete-field
    [readOnly]="isReadOnly('campus_system')"
    [parentFormGroup]="formGroup"
    [parentFormControlName]="'campus_system'"
    >
  </app-campus-system-autocomplete-field>

</div>

