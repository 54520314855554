import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { HoldingBookSource } from '../holding-book-source';


@Component({
  selector: 'app-holding-book-source-dialog',
  template: `
            <button *ngIf="!iconOnly" type="button" mat-raised-button
                    [color]="buttonColor" (click)="openDialog()">
              <span *ngIf="!recordId">
                  Add <mat-icon>add_box</mat-icon>
              </span>
              <span *ngIf="recordId">
                  Edit <mat-icon>edit</mat-icon>
              </span>
            </button>

            <button *ngIf="iconOnly" type="button" mat-mini-fab
                    [color]="buttonColor" (click)="openDialog()">
              <span *ngIf="!recordId">
                <mat-icon>add</mat-icon>
              </span>
              <span *ngIf="recordId">
                <mat-icon>edit</mat-icon>
              </span>
            </button>
             `,
})
export class HoldingBookSourceDialogComponent {

  @Input() recordId: string;
  @Input() iconOnly = false;
  @Input() buttonColor = '';

  @Output() onSubmitSuccessEmitter = new EventEmitter<HoldingBookSource>();

  constructor(
    public dialog: MatDialog
  ) { }

  protected openDialog(): void {
    // Open the dialog with record ID.
    const ref = this.dialog.open(HoldingBookSourceDialogContentComponent, {
      data: { recordId: this.recordId }
    });

    // Subscribe to the child emitter in code.
    // On success, forward the emitted record up the chain.
    const sub = ref.componentInstance.onSubmitSuccessEmitter.subscribe(
      (record) => this.onSubmitSuccess(record)
    );

    // Unsubscribe after close.
    ref.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  public onSubmitSuccess(record: HoldingBookSource): void {
    this.onSubmitSuccessEmitter.emit(record);
  }
}


@Component({
  selector: 'app-holding-book-source-dialog-content',
  template: `
            <app-dialog-header>
              {{ (data.recordId ? 'Edit' : 'Create') }} Holding Source
            </app-dialog-header>

            <mat-dialog-content>
              <app-holding-book-source-form
                 [injectedRecordId]="data.recordId"
                 (onCloseClickedEmitter)="dialogRef.close()"
                 (onSubmitSuccessEmitter)="onSubmitSuccess($event)"
              >
              </app-holding-book-source-form>
            </mat-dialog-content>
            `,
})
export class HoldingBookSourceDialogContentComponent {

  @Output() onSubmitSuccessEmitter = new EventEmitter<HoldingBookSource>();

  constructor(
    public dialogRef: MatDialogRef<HoldingBookSourceDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public onSubmitSuccess(record: HoldingBookSource): void {
    this.onSubmitSuccessEmitter.emit(record);
    this.onCloseClicked();
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }
}
