import { Pipe, PipeTransform } from '@angular/core';

/*
 * Extract a filename from a full path.
 * Usage:
 *   value | fileName
 * Example:
 *   {{ /path/to/file.jpg | fileName }}
 *   formats to: file.jpg
*/
@Pipe({name: 'fileName'})
export class FileNamePipe implements PipeTransform {
  transform(value: string): string {
    // Replace preceeding directories and query strings from URLs.
    return value.replace(/^.*[\\\/]/, '').replace(/\?.*$/, '');
  }
}
