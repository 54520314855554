import { Component, OnInit } from '@angular/core';

import { SessionService } from '../auth/session.service';


@Component({
  selector: 'app-logout',
  template: `
  Logging out...
  `
})
export class LogoutComponent implements OnInit {

  constructor(
    private sessionService: SessionService,
  ) { }

  ngOnInit() {
    this.sessionService.logout();
  }
}

