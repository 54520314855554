import { Component } from '@angular/core';


@Component({
  selector: 'app-nav-help',
  templateUrl: './nav-help.component.html',
  styleUrls: ['./nav-help.component.css']
})
export class NavHelpComponent {

  constructor() { }
}
