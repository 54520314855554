import { Component, OnInit } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';
import { UserService } from '../../user/user.service';
import { AcademicTerm } from '../academic-term';
import { AcademicTermService } from '../academic-term.service';


@Component({
  selector: 'app-academic-term-list',
  templateUrl: './academic-term-list.component.html',
  styleUrls: ['./academic-term-list.component.css']
})
export class AcademicTermListComponent extends BaseListComponent<AcademicTerm> implements OnInit {
  baseUrl = 'academic-term/';
  viewTitle = 'AcademicTerms - AIMHub';

  userRole: string;

  constructor(
    protected dataService: AcademicTermService,
    protected userService: UserService
  ) {
    super(dataService);
  }

  ngOnInit(): void {
    this.initUserRole();
    super.ngOnInit();
  }

  initUserRole() {
    this.userService.getRole()
      .then(role => this.userRole = role)
      .catch(err => this.handleError(err));
  }

  isSystemAdmin(): boolean {
    if (this.userRole === 'System Administrator') {
      return true;
    }
    return false;
  }

  isCampusAdmin(): boolean {
    if (this.userRole === 'Campus Administrator') {
      return true;
    }
    return false;
  }

  canModifiy(term: AcademicTerm): boolean {
    if (this.isSystemAdmin()) {
      return true;
    }
    // Campus terms must have sessions.
    if (this.isCampusAdmin() && term.session !== '') {
      return true;
    }
    return false;
  }
}
