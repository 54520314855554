
export class ExchangeRequestLog {

  constructor(
    public id = '',
    public request = '',
    public message = '',
    public user = '',
    public user_str = '',
    public from_system = false,
    public created_date = '',
    public modified_date = '',
  ) { }

}
