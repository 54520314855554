import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { ExchangeRequestDetailComponent } from './exchange-request-detail/exchange-request-detail.component';
import { ExchangeRequestInListComponent } from './exchange-request-in-list/exchange-request-in-list.component';
import { ExchangeRequestOutListComponent } from './exchange-request-out-list/exchange-request-out-list.component';


const routes: Routes = [
  {
    path: 'exchange/request/in',
    component: ExchangeRequestInListComponent,
    canActivate: [AuthGuardService],
    title: 'Incoming Exchange Requests' // Title for the incoming exchange request list
  },
  {
    path: 'exchange/request/out',
    component: ExchangeRequestOutListComponent,
    canActivate: [AuthGuardService],
    title: 'Outgoing Exchange Requests' // Title for the outgoing exchange request list
  },
  {
    path: 'exchange/request/:id',
    component: ExchangeRequestDetailComponent,
    canActivate: [AuthGuardService],
    title: 'Exchange Request Detail' // Title for the exchange request detail page
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExchangeRequestRoutingModule {}
