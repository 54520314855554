import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthToken } from '../auth/auth-token';

import { CampusSystem } from '../campus-system/campus-system';


@Injectable()
export class ReportsService {

  apiHost = environment.apiHost;
  apiUrlReportCampusSystem = `${this.apiHost}/api/reports/campus-system/`;

  protected httpConfig = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': '*/*',
    }),
  };

  constructor(protected http: HttpClient) { }

  async reportCampusSystem(
    campusSystem: CampusSystem,
    startDate: Date,
    endDate: Date
  ) {
    const apiUrl = this.apiUrlReportCampusSystem;

    try {
      const params = new HttpParams()
        .set('campus_system', campusSystem.id)
        .set('start_date', startDate.toJSON())
        .set('end_date', endDate.toJSON());

      let httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
      httpConfig['params'] = params;

      const response = await this.http.get(apiUrl, httpConfig).toPromise()
      return response;
    } catch (e) {
      this.handleError(e);
      return Promise.reject();
    }
  }

  handleError(error: any): Promise<any> {
    // @TODO: Improve logging.
    console.log('ReportsService error: ', error);
    return Promise.reject(error);
  }
}
