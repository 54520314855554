import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { PublisherModule } from '../publisher/publisher.module';

import { BookService } from './book.service';
import { BookRoutingModule } from './book-routing.module';
import { BookListComponent } from './book-list/book-list.component';
import { BookDetailComponent } from './book-detail/book-detail.component';
import {
  BookDialogComponent, BookDialogContentComponent
} from './book-dialog/book-dialog.component';
import { BookFieldsComponent } from './book-fields/book-fields.component';
import { BookFormComponent } from './book-form/book-form.component';
import { BookCardComponent } from './book-card.component';
import { AsIsbn10Pipe, AsIsbn13Pipe,
  IsbnHyphensPipe, IsbnNoHyphensPipe } from './pipe/isbn.pipe';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BookRoutingModule,
    PublisherModule,
  ],
  exports: [
    BookListComponent,
    BookDetailComponent,
    BookDialogComponent,
    BookDialogContentComponent,
    BookFieldsComponent,
    BookFormComponent,
    BookCardComponent,
    AsIsbn10Pipe,
    AsIsbn13Pipe,
    IsbnHyphensPipe,
    IsbnNoHyphensPipe,
  ],
  declarations: [
    BookListComponent,
    BookDetailComponent,
    BookDialogComponent,
    BookDialogContentComponent,
    BookFieldsComponent,
    BookFormComponent,
    BookCardComponent,
    AsIsbn10Pipe,
    AsIsbn13Pipe,
    IsbnHyphensPipe,
    IsbnNoHyphensPipe,
  ],
  providers: [
    BookService,
  ],
})
export class BookModule { }
