// Idea for dynamic environment configuration found here:
// https://medium.com/voobans-tech-stories/multiple-environments-with-angular-and-docker-2512e342ab5a
import { environment as defaultEnvironment } from './environment';

export const environmentLoader = new Promise<any>((resolve, reject) => {
  const xmlhttp = new XMLHttpRequest(),
    method = 'GET',
    url = './assets/environment.json';
    xmlhttp.open(method, url, true);
    xmlhttp.onload = function() {
      if (xmlhttp.status === 200) {
        resolve(JSON.parse(xmlhttp.responseText));
      } else {
        resolve(defaultEnvironment);
        console.log('Using default environment');
      }
    };
  xmlhttp.send();
});
