import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export class RedirectService {

  constructor(private router: Router) { }

  public redirectHome() {
    this.redirect('/home');
  }

  public redirectUnauthenticated() {
    // If token is expired, modified, or missing, will end up here.
    // Content area will be blank. Also blank on cache clear.
    this.redirect('/');
    console.log('redirectUnauthenticated() to /');
  }

  public redirectNotFound() {
    // Can't directly access history via API (likely for security).
    // The malformed URL will be on top of the history stack.
    // When the user hits "back" from error page, they will return to
    // malformed page, which directs them back to the error page.
    //
    // This replaces the top (malformed) history entry with the error page URL.
    // It then reloads the page, loading the error page content.
    // This way, it will skip over the bad link when navigating backwards.
    //
    // https://angular.io/api/router/NavigationExtras#replaceUrl
    console.log('Service redirect: redirecting not-found');
    this.router.navigate(['/not-found'], { replaceUrl: true });
  }

  public redirect(path: string) {
    if (location.pathname !== path) {
      console.log(`Service redirect: redirecting ${path}`);
      this.router.navigate([path]);
    }
  }
}
