import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseFieldsComponent } from '../../base/base-fields.component';
import { HoldingBookSource } from '../holding-book-source';


@Component({
  selector: 'app-holding-book-source-fields',
  templateUrl: './holding-book-source-fields.component.html',
  styleUrls: ['./holding-book-source-fields.component.css']
})
export class HoldingBookSourceFieldsComponent extends BaseFieldsComponent {
}


/**
 * Static function that returns a HoldingBookSource field formgroup.
 *
 * @param {HoldingBookSource} holdingSource - HoldingBookSource object to initialize fields with.
 * @returns {FormGroup} intialized set of holding source form fields.
 */
export function initHoldingBookSourceFormGroup(holdingSource: HoldingBookSource): FormGroup {
  // Build the form.
  const fb = new FormBuilder();
  const form = fb.group({
    'id': [holdingSource.id, [] ],
    'name': [holdingSource.name, [ Validators.required ] ],
  });

  return form;
}
