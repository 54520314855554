<div class="panel">
  <h2>Add a Campus Book Holding</h2>

  <div class="row">
    <div class="col-xs-4">
      <h3>New Book</h3>
      <app-book-form> </app-book-form>
    </div>

    <div class="col-xs-4">
      <app-holding-book-form-create></app-holding-book-form-create>
    </div>
  </div>
</div>
