<mat-toolbar class="nav-auth-toolbar">
  <mat-toolbar-row>
    <app-nav-home></app-nav-home>

    <app-search-bar></app-search-bar>

    <span class="toolbar-space-filler"></span>

    <app-nav-help></app-nav-help>

    <span class="toolbar-space-filler"></span>

    <app-nav-user></app-nav-user>
  </mat-toolbar-row>


  <mat-toolbar-row>

    <app-nav-holding></app-nav-holding>

    <span class="toolbar-space-filler"></span>

    <a mat-button id="link-publisher-list" routerLink="/publisher">
      Publishers
    </a>

    <span class="toolbar-space-filler"></span>

    <!-- Folding two list items into a sub-component isn't
          preserving the list styling... -->
    <app-nav-request-out [count]="badgeStat.request_out"></app-nav-request-out>

    <span class="toolbar-space-filler"></span>

    <app-nav-request-in [count]="badgeStat.request_in"></app-nav-request-in>

    <span class="toolbar-space-filler"></span>

    <span *ngIf="showAdmin">
      <app-nav-admin [userRole]="userRole"></app-nav-admin>
    </span>
  </mat-toolbar-row>

</mat-toolbar>
