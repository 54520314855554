import { Component } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';
import { Book } from '../book';
import { BookService } from '../book.service';


@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.css']
})
export class BookListComponent extends BaseListComponent<Book> {
  baseUrl = 'book/';
  viewTitle = 'Books - AIMHub';

  constructor(
    protected dataService: BookService
  ) {
    super(dataService);
  }
}
