import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthToken } from './auth-token';
import { RedirectService } from '../core/redirect.service';


@Injectable()
export class SessionService {

  apiHost = environment.apiHost;
  redirectUrl: string;

  protected httpConfig = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': '*/*',
    }),
  };

  constructor(
    protected http: HttpClient,
    protected redirectService: RedirectService,
  ) { }

  async login(email: string, password: string): Promise<any> {
    try {
      const apiUrl = `${this.apiHost}/api/auth/login/`;
      const payload = { email, password };

      const response = await this.http.post(apiUrl, payload, this.httpConfig).toPromise();
      const token = response['key'];
      AuthToken.setToken(token);
      return token;

    } catch (e) {
      return this.handleError(e);
    }
  }

  logout(): void {
    try {
      const apiUrl = `${this.apiHost}/api/auth/logout/`;
      const data = {}
      const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);

      console.log('SessionService: Logging out');
      this.http.post(apiUrl, data, httpConfig).toPromise();
      this.clearSession();

    } catch (e) {
      this.handleError(e);
    }
  }

  isValidSession(): boolean {
    return AuthToken.getToken() ? true : false;
  }

  clearSession() {
    console.log('SessionService: Clearing session.');
    localStorage.clear();
    this.redirectService.redirectUnauthenticated();
  }

  /** Clear localStorage and redirect to index if unauthenticated.
   * Similar to isValidSession, but also takes action if session
   * is invalid. Simplifies calling code.
   **/
  enforceSession(): boolean {
    if (this.isValidSession()) {
      return true;
    }
    this.clearSession();
    return false;
  }

  /** Just pass the login/logout error message up to the calling component.
   * @param {Error} error - The exception that needs to be handled.
   **/
  handleError(error: Error): Promise<Error> {
    // @TODO: Improve logging.
    console.log('SessionService error: ', error);
    // For HTTP 401, don't need to redirect login form to front page,
    // while login form is on the front page.

    return Promise.reject(error);
  }
}
