import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exchange-request-status-form-create',
  templateUrl: './exchange-request-status-form-create.component.html',
  styleUrls: ['./exchange-request-status-form-create.component.css']
})
export class ExchangeRequestStatusFormCreateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
