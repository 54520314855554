import './polyfills';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment, applyEnvironment } from './environments/environment';
import { environmentLoader } from './environments/environmentLoader';

import { AppModule } from './app/';

environmentLoader.then(env => {
  applyEnvironment(env);

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic().bootstrapModule(AppModule);
});
