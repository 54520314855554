import { ValidatorFn } from '@angular/forms';

import { invalidRegex } from './invalid-regex';


/* Determine if a string is a valid UUID.
  * @param {string} value - The value to test for UUID matching.
  * @return {boolean} - True if string is UUID, otherwise false.
  */
export function isValidUUID(value: string): boolean {
  const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return re.test(value);
}


export function invalidUUID(): ValidatorFn {
  const errorKey = 'invalidUUID';
  const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return invalidRegex(re, errorKey);
}

