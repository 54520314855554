import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-error-not-found',
  templateUrl: './error-not-found.component.html',
  styleUrls: ['./error-not-found.component.css']
})
export class ErrorNotFoundComponent implements OnInit {

  viewTitle = 'Not Found - AIMHub';

  constructor(
    private titleService: Title,
    private location: Location,
  ) { }

  ngOnInit() {
    this.titleService.setTitle( this.viewTitle );
  }

  goBack(): void {
    this.location.back();
  }
}
