<button mat-raised-button
    type="button"
    color="primary"
    (click)="fileUploadModal.show()"
    >
  Upload File
  <mat-icon>file_upload</mat-icon>
</button>

<div
  bsModal #fileUploadModal="bs-modal"
  class="modal fade"
  [config]="{backdrop: 'static'}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="fileUploadModalLabel"
  aria-hidden="true"
  >

  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h3 class="modal-title pull-left" id="fileUploadModalLabel">
          Upload a Requested File
        </h3>

        <button class="dialog-close-button" (click)="onCloseClicked()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>

      <div class="modal-body">

        <div id="success-notification" class="alert alert-success" *ngIf="success">
          {{ successNotification }}
        </div>
        <div id="failed-notification" class="alert alert-danger" *ngIf="failed">
          {{ failedNotification }}
        </div>

        <p>
          Select one or more files to upload. Hold shift to select multiple items.
        </p>
        <p>
          Files must be less than 500MB. Compress files into a zip archive to
          make them smaller.
        </p>

          <button mat-button
            mat-raised-button
            (click)="fileInput.click()"
            >
            Select File
            <mat-icon>attachment</mat-icon>
          </button>

          <input type="file" multiple #fileInput style="display:none;" />

        <div *ngFor="let file of fileInput.files">
          <div>{{ file.name }}</div>
        </div>


        <progressbar [max]="100" [value]="uploadProgress">
          <span style="color:white; white-space:nowrap;">
            {{ uploadProgress }}%
          </span>
          <span hidden>{{ dataService.timerAsync$ | async }}</span>
        </progressbar>

        <button mat-raised-button
            id="upload-file-btn"
            color="primary"
            [disabled]="uploading"
            (click)="onSubmitClicked()"
            >
          <mat-icon>file_upload</mat-icon>
          Upload
        </button>

        <button mat-raised-button
          id="close-btn"
          (click)="onCloseClicked()"
          >
          Close
        </button>

      </div>
    </div>
  </div>
</div>
