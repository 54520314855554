import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-announcement',
  template: `
    <alert type="info" [dismissible]="true">
      <mat-icon class="announcement-alert">new_releases</mat-icon>
      <span class="announcement-alert">
        <strong class="lead-text">{{ leadText }}:</strong>
        {{ descriptionText }}
      </span>
    </alert>
  `,
  styles: [`
    .announcement-alert {
      vertical-align: middle;
      padding-right: 40px;
    }
    `
  ],
})
export class AnnouncementComponent {

  @Input() leadText = '';
  @Input() descriptionText = '';

  constructor() { }
}
