import { AbstractControl, ValidatorFn } from '@angular/forms';


export function invalidRegex(re: RegExp, errorKey: string,
                             present = true): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value;
    if (!value) { return null; }

    const match = re.test(value);
    if ((present && !match) || (!present && match)) {
      // Required syntax for setting JSON key via variable.
      const error = {};
      error[errorKey] = {value};
      return error;
    }
    return null;
  };
}
