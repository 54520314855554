<mat-card class="login-card">

  <mat-card-title> Sign In </mat-card-title>

  <mat-card-content>

    <div id="failed-notification" class="alert alert-danger" *ngIf="failed">
      {{ failedNotification }}
    </div>


    <form [formGroup]="loginForm"  *ngIf="active"  (ngSubmit)="onSubmit()">

      <mat-form-field class="login-field">
        <input matInput placeholder="Email"
            type="email"
            formControlName="email"
            required
            >
        <mat-error *ngIf="loginForm.controls.email.hasError('backend')">
          {{ loginForm.controls['email'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="loginForm.controls.email.hasError('required')">
          Email address is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="loginForm.controls.email.hasError('email')
                      && !loginForm.controls.email.hasError('required')">
          Email address is <strong>invalid</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-field">
        <input matInput placeholder="Password"
            type="password"
            formControlName="password"
            required
            >
        <mat-error *ngIf="loginForm.controls.password.hasError('backend')">
          {{ loginForm.controls['password'].errors['backend'] }}
        </mat-error>
        <mat-error *ngIf="loginForm.controls.password.hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <div class="button-row">
        <button mat-raised-button
            class="signin-button"
            type="submit"
            color="primary"
            [disabled]="!loginForm.valid || submitting"
            >
          Sign In
        </button>
      </div>

      <p class="reset-password">
        <a id='link-forgot-password' routerLink="/password/reset">
          Reset a forgotten password.
        </a>
      </p>

    </form>

  </mat-card-content>

</mat-card>
