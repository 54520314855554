<span [formGroup]="formGroup">

  <div class="form-column">

    <mat-form-field>
      <input matInput placeholder="Title"
                      formControlName="title" required
                      [readonly]="isReadOnly('title')">

      <mat-error *ngIf="formGroup.controls.title.hasError('backend')">
        {{ formGroup.controls['title'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.title.hasError('required')">
        Book title is <strong>required</strong>
      </mat-error>
    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="ISBN"
                      formControlName="isbn" required
                      [readonly]="isReadOnly('isbn')">

      <mat-error *ngIf="formGroup.controls.isbn.hasError('backend')">
        {{ formGroup.controls['isbn'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.isbn.hasError('required')">
        Book ISBN is <strong>required</strong>
      </mat-error>
    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Author(s)"
                      formControlName="authors" required
                      [readonly]="isReadOnly('authors')">

      <mat-error *ngIf="formGroup.controls.authors.hasError('backend')">
        {{ formGroup.controls['authors'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.authors.hasError('required')">
        Book author(s) are <strong>required</strong>
      </mat-error>
    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Edition"
                      formControlName="edition"
                      [readonly]="isReadOnly('edition')">

      <mat-error *ngIf="formGroup.controls.edition.hasError('backend')">
        {{ formGroup.controls['edition'].errors['backend'] }}
      </mat-error>
    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Copyright Year"
                      formControlName="copyright_year"
                      [readonly]="isReadOnly('copyright_year')">

      <mat-error *ngIf="formGroup.controls.copyright_year.hasError('backend')">
        {{ formGroup.controls['copyright_year'].errors['backend'] }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.copyright_year.hasError('pattern')">
        Enter a valid year (YYYY)
      </mat-error>
    </mat-form-field>


    <app-publisher-autocomplete-field
      [parentFormGroup]="formGroup"
      [parentFormControlName]="'publisher'"
      [readOnly]="isReadOnly('publisher')"
      [required]="true"
      >
    </app-publisher-autocomplete-field>

  </div>

</span>
