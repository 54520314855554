<div [formGroup]="formGroup" class="column-form-container">

  <div class="preference-section">
    <mat-card-subtitle>
      Notify about your requests when...
    </mat-card-subtitle>

    <mat-slide-toggle class="preference-toggle"
        formControlName="notify_request_out_opened"
        (change)="onTogglePreference('notify_request_out_opened')"
        >
      Your campus makes a new request.
    </mat-slide-toggle>

    <mat-slide-toggle class="preference-toggle"
        formControlName="notify_request_out_new_log"
        (change)="onTogglePreference('notify_request_out_new_log')"
        >
      New log activity in your requests.
    </mat-slide-toggle>

    <mat-slide-toggle class="preference-toggle"
        formControlName="notify_request_out_status_change"
        (change)="onTogglePreference('notify_request_out_status_change')"
        >
      Status of your requests changes.
    </mat-slide-toggle>
  </div>

  <div class="preference-section">
    <mat-card-subtitle>
      Notify about requests to you when...
    </mat-card-subtitle>

    <mat-slide-toggle class="preference-toggle"
        formControlName="notify_request_in_opened"
        (change)="onTogglePreference('notify_request_in_opened')"
        >
      New requests to you.
    </mat-slide-toggle>

    <mat-slide-toggle class="preference-toggle"
        formControlName="notify_request_in_new_log"
        (change)="onTogglePreference('notify_request_in_new_log')"
        >
      Log activity in requests to you.
    </mat-slide-toggle>

    <mat-slide-toggle class="preference-toggle"
        formControlName="notify_request_in_status_change"
        (change)="onTogglePreference('notify_request_in_status_change')"
        >
      Status of requests to you changes.
    </mat-slide-toggle>
  </div>

</div>
