
// Type definition for Publisher
export class Publisher {
  constructor(
    public id = '',
    public name = '',
    public url?: string,
    public permissions_url?: string,
    public parent_company?: string,
    public contact_name?: string,
    public email?: string,
    public phone?: string,
    public fax?: string,
    public address1?: string,
    public address2?: string,
    public city?: string,
    public state?: string,
    public postal_code?: string,
    public created_date = '',
    public modified_date = '',
  ) { }
}
