import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { BaseCrudFormComponent } from '../../base/base-crud-form.component';

import { FileFormat } from '../../file-format/file-format'
import { HoldingBookSource } from '../../holding-book-source/holding-book-source'
import { HoldingBook } from '../../holding-book/holding-book';

import { HoldingBookService } from '../../holding-book/holding-book.service';
import { FileFormatService } from '../../file-format/file-format.service';
import { HoldingBookSourceService } from '../../holding-book-source/holding-book-source.service';


@Component({
  selector: 'app-batch-isbn-holding-form',
  templateUrl: './batch-isbn-holding-form.component.html',
  styleUrls: ['./batch-isbn-holding-form.component.css']
})
export class BatchIsbnHoldingFormComponent extends BaseCrudFormComponent<HoldingBook> {

  @Input() campus: string;
  @Input() book: string;

  format_list: FileFormat[];
  source_list: HoldingBookSource[];

  @Input() declare record: HoldingBook;

  @Input() successNotification: string;
  @Input() failedNotification: string;
  @Input() submitText: string;

  formErrors = {
    'format': '',
    'source': '',
  };

  constructor(protected fb: FormBuilder,
              protected holdingBookService: HoldingBookService,
              protected holdingBookSourceService: HoldingBookSourceService,
              protected fileFormatService: FileFormatService,
             ) {
    super(holdingBookService);
  }

  buildFormGroup(): FormGroup {
    return this.fb.group({
      'format': [this.record.file_format, [
        ],
      ],
      'source': [this.record.source, [
        ],
      ],
    });
  }

  initFormModel() {
    this.record = new HoldingBook('', '', '', '', '');
  }

  getFormModel() {
    const { format, source } = this.recordForm.value;

    let campus = 'f3d2492b-0d30-4045-99d2-a58ccd4d02ec';
    let book = 'f6720024-7eef-47dc-ad55-dc038ad7f882';
    const submitHolding = new HoldingBook(
      '', campus, book, format, source,
    );
    return submitHolding;
  }
}
