import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-list-alert',
  template: `
    <alert type="success" *ngIf="itemCreated"
        [dismissible]="true" [dismissOnTimeout]="notifyTimeout"
        >
      Created {{ objectName }}
    </alert>
    <alert type="success" *ngIf="itemUpdated"
        [dismissible]="true" [dismissOnTimeout]="notifyTimeout"
        >
      Updated {{ objectName }}
    </alert>
    <alert type="success" *ngIf="itemDeleted"
        [dismissible]="true" [dismissOnTimeout]="notifyTimeout"
        >
      Deleted {{ objectName }}
    </alert>
             `,
})
export class ListAlertComponent {

  @Input() objectName = '';
  @Input() itemCreated = false;
  @Input() itemUpdated = false;
  @Input() itemDeleted = false;
  @Input() notifyTimeout = 10000;

  constructor() { }
}
