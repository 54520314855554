import { Component, Input } from '@angular/core';

import { FileFormat } from './file-format';


@Component({
  selector: 'app-file-format-card',
  template: `
    <div id="file-format-{{ record.id }}">

      <app-card-field [uniqueId]="record.id" [labelText]="'Name'" [fieldName]="'Name'">
        {{ record.name }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'Extension'" [fieldName]="'Extension'">
        {{ record.extension }}
      </app-card-field>

    </div>
    `,
})
export class FileFormatCardComponent {

  @Input() record: FileFormat;

  constructor() { }
}
