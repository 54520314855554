import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Book } from '../book/book';


@Component({
  selector: 'app-batch-isbn',
  templateUrl: './batch-isbn.component.html',
  styleUrls: ['./batch-isbn.component.css']
})
export class BatchIsbnComponent implements OnInit {

  viewTitle = 'Add Book Holdings by ISBN - AIMHub';
  bookList: Book[] = [];

  // Action status
  public notifyTimeout = 10000;
  public itemCreated = false;

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle( this.viewTitle );
  }

  onSingleSubmitClicked(book: Book) {
    this.appendUniqueBook(book);
    this.onItemCreate(book);
  }

  onBatchSubmitClicked(books: Book[]) {
    for (let i = 0; i < books.length; i++) {
      if (!this.isBookInList(books[i])) {
        this.bookList.push(books[i]);
        this.onItemCreate(books[i]);
      }
    }
  }

  /* Show success notification when item is created.
   */
  onItemCreate(record: Book): void {
    this.itemCreated = true;
    setTimeout(() => this.itemCreated = false, this.notifyTimeout);
  }

  appendUniqueBook(book: Book) {
    if (!this.isBookInList(book)) {
      this.bookList.push(book);
    }
  }

  isBookInList(book: Book): boolean {
    let listed = false;
    for (let j = 0; j < this.bookList.length; j++) {
      if (book.id === this.bookList[j].id) {
        listed = true;
      }
    }
    return listed;
  }

}
