export class User {

  constructor(
    public id = '',
    public full_name = '',
    public email = '',
    public phone = '',
    public position = '',
    public campus = '',

    public is_active = '',

    public role = '',
    public campus_str = '',
    public last_login = '',
    public created_date = '',
    public modified_date = '',
  ) { }

}

export class UserPreference {
  constructor(
    public id = '',

    public notify_request_in_opened = '',
    public notify_request_out_opened = '',
    public notify_request_in_new_log = '',
    public notify_request_out_new_log = '',
    public notify_request_in_status_change = '',
    public notify_request_out_status_change = '',
    public notify_request_reminder = '',
  ) { }
}
