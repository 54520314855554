<div>

  <div id="success-notification" class="alert alert-success" *ngIf="success">
    {{ successNotification }}
    <span id="success-url">
      <a [routerLink]="recordUrl">
        {{ record.book_str }} {{ record.format_str }}
      </a>
    </span>
  </div>

  <div id="failed-notification" class="alert alert-danger"
       *ngIf="failed && formErrors.non_field_errors">
    {{ formErrors.non_field_errors }}
  </div>
  <div id="failed-notification" class="alert alert-danger"
       *ngIf="failed && formErrors.detail">
    {{ formErrors.detail }}
  </div>


  <form [formGroup]="recordForm"  *ngIf="active"  (ngSubmit)="onSubmit()">

    <div class="form-group">
      <label for="campus">Campus</label>
      <select id="campus" class="form-control" formControlName="campus" >
        <option
                *ngFor="let campus of campusList"
                value="{{ campus.id }}"
                [selected]="record.campus == campus.id"
                >
          {{ campus.name }}
        </option>
      </select>

      <div *ngIf="formErrors.campus" class="alert alert-danger">
        {{ formErrors.campus }}
      </div>

      <label for="book">Book</label>
      <select id="book" class="form-control" formControlName="book" >
        <option
                *ngFor="let book of bookList"
                value="{{ book.id }}"
                [selected]="record.book == book.id"
                >
          {{ book.title }} ({{ book.isbn }})
        </option>
      </select>

      <div *ngIf="formErrors.book" class="alert alert-danger">
        {{ formErrors.book }}
      </div>

      <label for="file_format">File Format</label>
      <select id="file_format" class="form-control" formControlName="file_format" >
        <option
                *ngFor="let fileFormat of fileFormatList"
                value="{{ fileFormat.id }}"
                [selected]="record.file_format == fileFormat.id"
                >
          {{ fileFormat.name }}
        </option>
      </select>

      <div *ngIf="formErrors.file_format" class="alert alert-danger">
        {{ formErrors.file_format }}
      </div>

    </div>

    <button type="submit" class="btn btn-primary"
           [disabled]="!recordForm.valid || submitting">{{ submitText }}</button>
    <button type="button" class="btn btn-default" (click)="resetForm()"
           [disabled]="submitting">Reset</button>
  </form>

</div>
