<div>

  <div id="success-notification" class="alert alert-success" *ngIf="success">
    {{ successNotification }}
    <span id="success-url">
      <a [routerLink]="recordUrl">
        {{ record.name }}
      </a>
    </span>
  </div>

  <div id="failed-notification" class="alert alert-danger"
       *ngIf="failed && formErrors.non_field_errors">
    {{ formErrors.non_field_errors }}
  </div>
  <div id="failed-notification" class="alert alert-danger"
       *ngIf="failed && formErrors.detail">
    {{ formErrors.detail }}
  </div>


  <form [formGroup]="recordForm"  *ngIf="active"  (ngSubmit)="onSubmit()">

    <div class="form-group">
      <label for="name">Name</label>
      <input type="text" id="name" class="form-control"
             formControlName="name" required />

      <div *ngIf="formErrors.name" class="alert alert-danger">
        {{ formErrors.name }}
      </div>

    </div>

    <div *ngIf='record.created_date'>
      <label for="created-date">Date Created</label>
      <p>{{ record.created_date | date: 'medium' }} </p>
    </div>

    <div *ngIf='record.modified_date'>
      <label for="modified-date">Date Modified</label>
      <p>{{ record.modified_date | date: 'medium' }} </p>
    </div>

    <button type="submit" class="btn btn-primary"
           [disabled]="!recordForm.valid || submitting">{{ submitText }}</button>
    <button type="button" class="btn btn-default" (click)="resetForm()"
           [disabled]="submitting">Reset</button>
    <button type="button" class="btn btn-default" (click)="goBack()"
           [disabled]="submitting">Back</button>
  </form>

</div>
