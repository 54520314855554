<ul id="format-list-{{ holding.id }}" class="list-group" *ngFor="let holding of holdingList">

  <li class="list-group-item row no-gutters">
    <div class="col-xs-10">

      <div *ngFor="let format of formatList">

        <div *ngIf="format.id === holding.file_format">

          <div class="row">
            <label for="format-{{ holding.id }}"> Format: </label>
            <span id="format-{{ holding.id }}">
              {{ format.name }} ({{ format.extension }})
            </span>
          </div>

          <div class="row">
            <label for="source-{{ holding.id }}"> Source: </label>
            <mat-form-field>
              <mat-select
                id="source-{{ holding.id }}"
                [value]="holding.source"
                (selectionChange)="sourceChanged(holding, $event)"
                >
                <mat-option *ngFor="let source of sourceList"
                    [value]="source.id"
                    >
                  {{ source.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

      </div>
    </div>

    <div class="col-xs-2">
      <button mat-mini-fab
          id="delete-{{ holding.id }}"
          type="button"
          color="warn"
          (click)="deleteClicked(holding)"
          aria-label="Delete holding"
          >
        <mat-icon>delete</mat-icon>
      </button>

    </div>

  </li>

</ul>
