import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthToken } from '../auth/auth-token';
import { BaseCrudService } from '../base/base-crud.service';
import { FileFormat } from './file-format';


@Injectable()
export class FileFormatService extends BaseCrudService<FileFormat> {

  endpointUrl = `${this.apiHost}/api/file-formats`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  getFileExtensions() {
    const apiUrl = this.endpointUrl + '/extensions/';

    const httpConfig = AuthToken.setHttpConfigAuthHeader(this.httpConfig);
    return this.http.get(apiUrl, httpConfig)
      .toPromise()
      // .then(response => response)
      .catch(this.handleError);
  }
}
