import { Component, OnInit } from '@angular/core';

import { BaseFormComponent } from '../../base/base-form.component';

import { User } from '../user';
import { UserProfileService } from '../user-profile.service';
import { initUserFormGroup } from '../user-fields/user-fields.component';


/**
 * User profile form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
})
export class UserProfileFormComponent extends BaseFormComponent<User> implements OnInit {

  constructor(
    protected dataService: UserProfileService,
  ) {
    super(User, initUserFormGroup, dataService);
  }

  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
    // Get target form values.
    const {
      full_name = '',
      email = '',
      position = '',
      campus_str = '',
    } = this.formComposite.value.record;

    // Format clipboard contents.
    this.clipboardText = [
      `Name: ${full_name}`,
      `Email: ${email}`,
      `Position: ${position}`,
      `Campus: ${campus_str}`,
    ].join('\n');
  }
}
