import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PasswordService } from '../password.service';

@Component({
  selector: 'app-password-change-form',
  templateUrl: './password-change-form.component.html',
  styleUrls: ['./password-change-form.component.css']
})
export class PasswordChangeFormComponent implements OnInit {
  old_password = '';
  new_password1 = '';
  new_password2 = '';

  passwordChangeForm: FormGroup;
  submitting = false;
  active = true;

  success = false;
  failed = false;
  successUrl: string;
  failedNotification: string;

  formErrors = {
    'old_password' : '',
    'new_password1': '',
    'new_password2': '',
  };

  validationMessages = {
    'old_password': {
      'required':      'Old password is required.',
    },
    'new_password1': {
      'required':      'New password is required.',
    },
    'new_password2': {
      'required':      'New password confirmation is required.',
    },
  };

  constructor(private fb: FormBuilder,
              private passwordService: PasswordService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.passwordChangeForm = this.fb.group({
      'old_password': [this.old_password, [
          Validators.required,
        ],
      ],
      'new_password1': [this.new_password1, [
          Validators.required,
        ],
      ],
      'new_password2': [this.new_password2, [
          Validators.required,
        ],
      ],
    });

    this.passwordChangeForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  onSubmit() {
    this.submitting = true;

    if (this.passwordChangeForm.valid) {

      // Server side
      const {
        old_password,
        new_password1,
        new_password2
      } = this.passwordChangeForm.value;

      this.passwordService.change(old_password, new_password1, new_password2)
        .then(() => this.onSuccess())
        .catch(err => this.handleServerErrors(err));

    // Client side validation error
    } else {
      const recordForm = this.getRecordFields();
      this.markAllAsTouched(recordForm);
    }

    this.submitting = false;
  }

  onSuccess() {
    this.success = true;
    this.failed = false;
    setTimeout(() => this.success = false, 10000);

    this.resetForm();
  }

  submitFailed(serverError?) {
    if (serverError) {
      this.handleServerErrors(serverError);
    }
    this.success = false;
    this.failed = true;
  }

  onValueChanged(data?: any) {
    if (!this.passwordChangeForm) { return; }
    this.refreshErrors();
  }

  resetForm() {
    this.buildForm();

    this.active = false;
    setTimeout(() => this.active = true, 0);
  }

  getRecordFields(): FormGroup {
    return <FormGroup>this.passwordChangeForm;
  }

  /**
   * Mark all form field controls as 'touched.'
   * This is a workaround to display <mat-error> messages on submit.
   */
  markAllAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsTouched();
    });
  }

  resetFormModel(): void {
    this.old_password = '';
    this.new_password1 = '';
    this.new_password2 = '';
  }

  refreshErrors() {
    this.refreshGeneralErrors();
    this.refreshFieldErrors();
  }

  refreshGeneralErrors() {
    this.failedNotification = null;
  }

  refreshFieldErrors() {
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.resetFieldErrors(field);
        this.collectFieldErrors(field);
      }
    }
  }

  resetFieldErrors(field: string) {
    this.formErrors[field] = '';
  }

  collectFieldErrors(field: string) {
    const form = this.passwordChangeForm;
    const control = form.get(field);

    if (control && control.dirty && !control.valid) {
      const messages = this.validationMessages[field];

      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  handleServerErrors(httpResponse: HttpErrorResponse) {
    if (httpResponse.status === 400) {
      this.applyServerFormErrors(httpResponse.error);
    }
  }

  applyServerFormErrors(err: any) {
    for (const key in err) {
      // General form errors.
      if (key === 'detail' || key === 'non_field_errors') {
        const errMsg = {backend: err[key]};
        this.passwordChangeForm.setErrors(errMsg);
      // Form field errors.
      } else if (key in this.passwordChangeForm.controls) {
        const errMsg = {backend: err[key][0]};
        this.passwordChangeForm.controls[key].setErrors(errMsg);
      }
    }
  }
}
