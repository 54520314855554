import { Component, OnInit, Input } from '@angular/core';

import { HoldingBook } from '../holding-book';


@Component({
  selector: 'app-holding-book-form-update',
  templateUrl: './holding-book-form-update.component.html',
  styleUrls: ['./holding-book-form-update.component.css']
})
export class HoldingBookFormUpdateComponent implements OnInit {

  @Input() record: HoldingBook;

  constructor() { }

  ngOnInit() {
  }

}
