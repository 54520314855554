import { Component, OnInit } from '@angular/core';

import { UserService } from '../user/user.service';


@Component({
  selector: 'app-nav-user',
  template: `
    <button mat-button [matMenuTriggerFor]="menuAccount">
      <mat-icon>account_circle</mat-icon>
      {{ userProfile.email }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #menuAccount="matMenu">
      <a mat-menu-item id="link-my-account" routerLink="/user/account">
        My Account
      </a>

      <a mat-menu-item id="link-my-campus" routerLink="/campus/profile">
        My Campus
      </a>

      <a mat-menu-item id="link-change-password" routerLink="/password/change">
        Change Password
      </a>

      <a mat-menu-item id="btn-link-signout" routerLink="/logout">
        <mat-icon>exit_to_app</mat-icon> Sign Out
      </a>

    </mat-menu>
  `
})
export class NavUserComponent implements OnInit {

  userProfile = {email: 'loading...'};
  userRole = '';

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.userService.getProfile()
      .then(userProfile => this.userProfile = userProfile);

    this.userService.getRole()
      .then(userRole => this.userRole = userRole);
  }

}
