import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { BookDetailComponent } from './book-detail/book-detail.component';


const routes: Routes = [
  {
    path: 'book/detail/:id',
    component: BookDetailComponent,
    canActivate: [AuthGuardService],
    title: 'Book Detail' // Add a title for the book detail page
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookRoutingModule {}
