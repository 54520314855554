import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Campus } from './campus';
import { CampusService } from './campus.service';


@Injectable()
export class CampusProfileService extends CampusService {

  endpointUrl = `${this.apiHost}/api/campuses/profile/`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  read(): Promise<Campus> {
    return super.read('', this.endpointUrl);
  }

  update(record: Campus, apiUrl: string): Promise<Campus> {
    return super.update(record, this.endpointUrl);
  }

}
