import { Component, Input } from '@angular/core';

import { Campus } from './campus';


@Component({
  selector: 'app-campus-card',
  template: `
    <div id="campus-{{ record.id }}">
      <app-card-field [uniqueId]="record.id" [labelText]="'Name'" [fieldName]="'Name'">
        {{ record.name }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'City'" [fieldName]="'City'">
        {{ record.city }}
      </app-card-field>

      <app-card-field [uniqueId]="record.id" [labelText]="'State'" [fieldName]="'State'">
        {{ record.state }}
      </app-card-field>
    </div>
    `,
})
export class CampusCardComponent {

  @Input() record: Campus;

  constructor() { }
}
