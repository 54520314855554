<button mat-button [matMenuTriggerFor]="menuHelp">
  <mat-icon>help</mat-icon>
  Help
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #menuHelp="matMenu">

  <a mat-menu-item
      id="getting-started"
      href="https://docs.google.com/document/d/1tLd0j0ksBoHvomfuz9YdGvKbWwr-q_QR9kkBDAzHnW8/edit?usp=sharing"
      >
    Getting Started
  </a>
  <a mat-menu-item
    id="training-video"
    href="https://smartplayer.captionsync.com/play.php?vid=1534884639csuati_33f59b3d8b87&hidetitle"
    target="_blank"
    title="Open recorded training video in new tab."
    >
    Recorded Training Video
    <mat-icon>
      open_in_new
    </mat-icon>
  </a>
  <a mat-menu-item
    id="contact-us"
    href="mailto:support@aimhub.org"
    >
    Contact Support
  </a>

</mat-menu>
