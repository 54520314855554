import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { RegistrationService } from '../registration.service';


@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent implements OnInit {
  email: string;

  registerForm: FormGroup;
  submitting = false;
  active = true;

  success = false;
  failed = false;
  successUrl: string;
  failedNotification: string;

  formErrors = {
    'email': '',
  };

  validationMessages = {
    'email': {
      'required':      'Email is required.',
      'invalidEmail':  'Email address is invalid',
    },
  };

  constructor(private fb: FormBuilder,
              private registrationService: RegistrationService) { }

  ngOnInit() {
    this.resetFormModel();
    this.buildForm();
  }

  buildForm(): void {
    this.registerForm = this.fb.group({
      'email': [this.email, [ Validators.required, Validators.email ] ],
    });

    this.registerForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  onSubmit() {
    this.submitting = true;

    if (this.registerForm.valid) {
      // Server side
      const { email } = this.registerForm.value;

      this.registrationService.register(email)
        .then(() => this.onSuccess())
        .catch( serverError => this.submitFailed(serverError));

    // Client side validation error
    } else {
      const recordForm = this.getRecordFields();
      this.markAllAsTouched(recordForm);
      this.submitFailed();
    }

    this.submitting = false;
  }

  onSuccess() {
    this.success = true;
    this.failed = false;
    setTimeout(() => this.success = false, 10000);

    this.resetForm();
  }

  submitFailed(serverError?) {
    if (serverError) {
      this.handleServerErrors(serverError);
    }
    this.success = false;
    this.failed = true;
  }

  onValueChanged(data?: any) {
    if (!this.registerForm) { return; }
    this.refreshErrors();
  }

  resetForm() {
    this.buildForm();

    this.active = false;
    setTimeout(() => this.active = true, 0);
  }

  getRecordFields(): FormGroup {
    return <FormGroup>this.registerForm;
  }

  /**
   * Mark all form field controls as 'touched.'
   * This is a workaround to display <mat-error> messages on submit.
   */
  markAllAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsTouched();
    });
  }

  resetFormModel(): void {
    this.email = '';
  }

  refreshErrors() {
    this.refreshGeneralErrors();
    this.refreshFieldErrors();
  }

  refreshGeneralErrors() {
    this.failedNotification = null;
  }

  refreshFieldErrors() {
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.resetFieldErrors(field);
        this.collectFieldErrors(field);
      }
    }
  }

  resetFieldErrors(field: string) {
    this.formErrors[field] = '';
  }

  collectFieldErrors(field: string) {
    const form = this.registerForm;
    const control = form.get(field);

    if (control && control.dirty && !control.valid) {
      const messages = this.validationMessages[field];

      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  handleServerErrors(httpResponse: HttpErrorResponse) {
    if (httpResponse.status === 400) {
      this.applyServerFormErrors(httpResponse.error);
    }
  }

  applyServerFormErrors(err: string[]) {
    for (const key in err) {
      // General form errors.
      if (key === 'detail' || key === 'non_field_errors') {
        const errMsg = {backend: err[key]};
        this.registerForm.setErrors(errMsg);
      // Form field errors.
      } else if (key in this.registerForm.controls) {
        const errMsg = {backend: err[key][0]};
        this.registerForm.controls[key].setErrors(errMsg);
      }
    }
  }
}
