import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { SearchResultListComponent } from './search-result-list/search-result-list.component';


const routes: Routes = [
  {
    path: 'search',
    component: SearchResultListComponent,
    canActivate: [AuthGuardService],
    title: 'Search Results' // Title for the search results page
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule {}
