import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { CampusSystemModule } from '../campus-system/campus-system.module';

import { CampusService } from './campus.service';
import { CampusProfileService } from './campus-profile.service';
import { CampusRoutingModule } from './campus-routing.module';

import { CampusCardComponent } from './campus-card.component';
import { CampusAutocompleteFieldComponent } from './campus-autocomplete-field/campus-autocomplete-field.component';
import { CampusDetailComponent } from './campus-detail/campus-detail.component';
import {
  CampusDialogComponent, CampusDialogContentComponent
} from './campus-dialog/campus-dialog.component';
import { CampusFieldsComponent } from './campus-fields/campus-fields.component';
import { CampusFormComponent } from './campus-form/campus-form.component';
import { CampusProfileFormComponent } from './campus-profile-form/campus-profile-form.component';
import { CampusListComponent } from './campus-list/campus-list.component';
import {
  CampusDeleteDialogComponent, CampusDeleteDialogContentComponent
} from './campus-delete-dialog/campus-delete-dialog.component';
import { CampusProfileComponent } from './campus-profile/campus-profile.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CampusSystemModule,
    CampusRoutingModule,
  ],
  exports: [
    CampusAutocompleteFieldComponent,
  ],
  declarations: [
    CampusCardComponent,
    CampusAutocompleteFieldComponent,
    CampusDetailComponent,
    CampusDialogComponent,
    CampusDialogContentComponent,
    CampusFieldsComponent,
    CampusFormComponent,
    CampusProfileFormComponent,
    CampusProfileComponent,
    CampusListComponent,
    CampusDeleteDialogComponent,
    CampusDeleteDialogContentComponent,
  ],
  providers: [
    CampusService,
    CampusProfileService,
  ],
})
export class CampusModule { }
