import { HttpErrorResponse } from '@angular/common/http';
import {OnInit, Input, Output, EventEmitter, Injectable} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IServiceModel } from './service.interface';
import { BaseCrudService } from './base-crud.service';
import { TInitFormGroup } from './base-fields.component';


/**
 * Base form for create/update.
 * Makes the service calls to the back end API.
 */
@Injectable()  // <-- Add this decorator
export class BaseFormComponent<T extends IServiceModel> implements OnInit {

  @Input() injectedRecordId: string;
  @Input() showClose = false;
  @Input() showBack = false;
  @Input() readOnlyForm = false;
  @Input() recordReadOnly = [
  ]


  // Inherited Outputs workaround.
  // https://github.com/angular/angular/issues/5415#issuecomment-253509453
  @Output() onSubmitSuccessEmitter = new EventEmitter<T>();
  @Output() onCloseClickedEmitter = new EventEmitter<T>();
  @Output() onBackClickedEmitter = new EventEmitter<T>();

  formComposite: FormGroup;

  record: T;
  createdRecord: T;
  defaultRecord: T;
  submitting = false;
  success = false;
  failure = false;
  notifyTimeout = 10000;
  clipboardText = '';

  constructor(
    protected TCreator: { new(): T; },  // Creates new instances of T
    protected initFormGroup: TInitFormGroup,
    protected dataService: BaseCrudService<T>,
  ) {
    this.defaultRecord = new TCreator();
  }

  ngOnInit() {
    this.initFormData();
  }

  /**
   * Obtain data to populate the form.
   * If record is specified, pull it (updates).
   * Otherwise, use blank record (create).
   */
  initFormData() {
    this._getRecordOrBlank()
      .then(response => this.record = response)
      .then(() => this.initForm())
      .then(() => this.composeForm());
  }

  /**
   * Initialize the form with an empty object.
   * Bind the fields object to 'record' a sub-group.
   */
  initForm() {
    this.formComposite = new FormGroup({
      'record': this.initFormGroup(this.record)
    });

    this.formComposite.valueChanges.subscribe(val => {
      this._updateClipboardText();
    });
  }

  /**
   * Return the specified record data in a promise,
   * or return an empty record in a promise.
   */
  _getRecordOrBlank(): Promise<T> {
    if (this.injectedRecordId) {
      return this.dataService.read(this.injectedRecordId);
    }
    return Promise.resolve(this._newRecord());
  }

  /**
   * Configure and assemble the reuable form groups.
   */
  composeForm() {
    /* Using `patchValue` instead of `setValue`, because it handles undefined values.
     * https://toddmotto.com/angular-2-form-controls-patch-value-set-value
     */
    this.getRecordFields().patchValue(this.record);
    this._updateClipboardText();
  }

  create(formGroup: FormGroup): Promise<any> {
    return this.dataService.create(formGroup.value)
      .then(response => this.createdRecord = response)
  }

  update(formGroup: FormGroup): Promise<any> {
    return this.dataService.update(formGroup.value)
      .then(response => this.record = response)
  }

  createOrUpdate(): Promise<any> {
    const recordForm = this.getRecordFields();
    if (!this.record.id) {
      return this.create(recordForm);
    }
    return this.update(recordForm);
  }

  /**
   * Decoupled submit actions.
   * Catch create and update errors.
   */
  onSubmit() {
    this.submitting = true;

    // Client side error.
    if (!this.formComposite.valid) {
      const recordForm = this.getRecordFields();
      this.markAllAsTouched(recordForm);

    } else {
      this.createOrUpdate()
        // Server submit success.
        .then(() => this.onSuccess())
        // Server side error.
        .catch(httpResponse => {
          this.handleServerErrors(httpResponse);
          const recordForm = this.getRecordFields();
          this.markAllAsTouched(recordForm);
        });
    }

    this.submitting = false;
  }

  /* When submit success, emit an event with the record.
   */
  onSuccess(): void {
    // Emit the created or updated record.
    if (this.createdRecord !== undefined) {
      this.onSubmitSuccessEmitter.emit(this.createdRecord);
    } else {
      this.onSubmitSuccessEmitter.emit(this.record);
    }

    // Show success notification for a duration, then reset.
    this.success = true;
    setTimeout(() => this.success = false, this.notifyTimeout);
  }

  /* When "Reset" button is clicked, set fields to original values.
   */
  onReset(): void {
    this.composeForm();
  }

  /* When "Close" button is clicked, emit an event with the record.
   */
  onClose() {
    this.onCloseClickedEmitter.emit(this.record);
  }

  /* When "Back" button is clicked, emit an event with the record.
   */
  onBack() {
    this.onBackClickedEmitter.emit(this.record);
  }

  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
  }

  protected _newRecord(): T {
    return Object.assign({}, this.defaultRecord);
  }

  getRecordFields(): FormGroup {
    return <FormGroup>this.formComposite.controls['record'];
  }

  handleServerErrors(httpResponse: HttpErrorResponse) {
    const errorCodes = Array(400, 403, 404);
    if (errorCodes.includes(httpResponse.status)) {
      this.applyServerFormErrors(httpResponse.error);
    }
  }

  applyServerFormErrors(err: string[]) {
    const recordFields = this.getRecordFields();

    for (const key in err) {
      // General form errors.
      if (key === 'detail' || key === 'non_field_errors') {
        const errMsg = {backend: err[key]};
        this.formComposite.setErrors(errMsg);
      // Form field errors.
      } else if (key in recordFields.controls) {
        const errMsg = {backend: err[key][0]};
        recordFields.controls[key].setErrors(errMsg);
      }
    }
  }

  /**
   * Mark all form field controls as 'touched.'
   * This is a workaround to display <mat-error> messages on submit.
   */
  markAllAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsTouched();
    });
  }
}
