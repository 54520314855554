<main>

<h1 *ngIf="selectedCampusSystem"> Campus System Report for {{ selectedCampusSystem.name }} </h1>
<h1 *ngIf="!selectedCampusSystem"> Campus System Report </h1>

<mat-card>
    <p> Select the campus system and date range view. </p>

    <mat-form-field>
      <mat-select
          #formatSelect
          placeholder="Campus system"
          size="14"
          [(ngModel)]="selectedCampusSystem"
          required
          >
        <mat-option *ngFor="let campusSystem of campusSystemList" [value]="campusSystem">
          {{ campusSystem.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Start date</mat-label>
      <input matInput [matDatepicker]="startDatepicker"
        id="report-start-date"
        name="start_date"
        type="text"
        placeholder="MM/DD/YYYY"
        [(ngModel)]="startDate"
        >
      <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatepicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>End date</mat-label>
      <input matInput [matDatepicker]="endDatepicker"
        id="report-end-date"
        name="end_date"
        type="text"
        placeholder="MM/DD/YYYY"
        [(ngModel)]="endDate"
        >
      <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatepicker></mat-datepicker>
    </mat-form-field>

    <button mat-raised-button
        id="btn-run-report"
        color="primary"
        (click)="runReportClicked()"
        [disabled]="!(selectedCampusSystem && campusSystemList.length > 0)"
        >
      Run Report
      <span class="glyphicon glyphicon-list-alt"></span>
    </button>
</mat-card>

<mat-tab-group *ngIf="reportData">
  <mat-tab label="System Summary">

    <mat-card class="col-sm-6">

      <mat-card-title>Registration</mat-card-title>
      <mat-card-content *ngIf="reportData !== undefined">

        <table>
          <thead>
            <tr>
              <th> Item </th>
              <th> Registered </th>
              <th> Unregistered </th>
              <th> Total </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Campuses</td>
              <td>{{ reportData['campuses']['registered']['sum_count'] }}</td>
              <td>{{ reportData['campuses']['unregistered']['sum_count'] }}</td>
              <td>{{ reportData['campuses']['total'] }}</td>
            </tr>
            <tr>
              <td>Users</td>
              <td>{{ reportData['users']['registered']['sum_count'] }}</td>
              <td>{{ reportData['users']['unregistered']['sum_count'] }}</td>
              <td>{{ reportData['users']['total'] }}</td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for registration...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>

    <mat-card class="col-sm-6">

      <mat-card-title>Holdings</mat-card-title>
      <mat-card-content *ngIf="reportData !== undefined">

        <table>
          <thead>
            <tr>
              <th> Item </th>
              <th> Public </th>
              <th> Private </th>
              <th> Total </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> New Holdings </td>
              <td>{{ reportData['holdings']['public']['newly_added']['new_count'] }}</td>
              <td> N/A </td>
              <td>{{ reportData['holdings']['public']['newly_added']['new_count'] }}</td>
            </tr>
            <tr>
              <td> All Holdings </td>
              <td>{{ reportData['holdings']['public']['sum_total']['sum_count'] }}</td>
              <td>{{ reportData['holdings']['private']['sum_total']['sum_count'] }}</td>
              <td>{{ reportData['holdings']['total'] }}</td>
            </tr>
            <tr>
            </tr>
          </tbody>
        </table>


      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for holdings...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>


    <mat-card class="col-sm-6">

      <mat-card-title>Incoming Requests From Other Campuses</mat-card-title>
      <mat-card-content *ngIf="reportData !== undefined">

        <table>
          <thead>
            <tr>
              <th> Item </th>
              <th> Open </th>
              <th> Complete </th>
              <th> Cancelled </th>
              <th> Total </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> New requests </td>
              <td>{{ reportData['requests']['incoming']['newly_added']['open_count'] }}</td>
              <td>{{ reportData['requests']['incoming']['newly_added']['complete_count'] }}</td>
              <td>{{ reportData['requests']['incoming']['newly_added']['cancelled_count'] }}</td>
              <td>{{ reportData['requests']['incoming']['newly_added']['new_count'] }}</td>
            </tr>
            <tr>
              <td> All requests </td>
              <td>{{ reportData['requests']['incoming']['sum_total']['open_count'] }}</td>
              <td>{{ reportData['requests']['incoming']['sum_total']['complete_count'] }}</td>
              <td>{{ reportData['requests']['incoming']['sum_total']['cancelled_count'] }}</td>
              <td>{{ reportData['requests']['incoming']['sum_total']['sum_count'] }}</td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for incoming requests...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>

    <mat-card class="col-sm-6">

      <mat-card-title>Outgoing Requests To Other Campuses</mat-card-title>
      <mat-card-content *ngIf="reportData !== undefined">

        <table>
          <thead>
            <tr>
              <th> Item </th>
              <th> Open </th>
              <th> Complete </th>
              <th> Cancelled </th>
              <th> Total </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> New requests </td>
              <td>{{ reportData['requests']['outgoing']['newly_added']['open_count'] }}</td>
              <td>{{ reportData['requests']['outgoing']['newly_added']['complete_count'] }}</td>
              <td>{{ reportData['requests']['outgoing']['newly_added']['cancelled_count'] }}</td>
              <td>{{ reportData['requests']['outgoing']['newly_added']['new_count'] }}</td>
            </tr>
            <tr>
              <td> All requests </td>
              <td>{{ reportData['requests']['outgoing']['sum_total']['open_count'] }}</td>
              <td>{{ reportData['requests']['outgoing']['sum_total']['complete_count'] }}</td>
              <td>{{ reportData['requests']['outgoing']['sum_total']['cancelled_count'] }}</td>
              <td>{{ reportData['requests']['outgoing']['sum_total']['sum_count'] }}</td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for outgoing requests...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>

  </mat-tab>

  <mat-tab label="Top Lists" layout="row" flex>

    <mat-card class="col-sm-6"  flex="100">

      <mat-card-title> Campuses With The Most Public Holdings </mat-card-title>

      <mat-card-content *ngIf="!reportData['holdings']['public']['sum_total']['top_campuses'].length">
        There are no campuses in the system with holdings during this time.
      </mat-card-content>

      <mat-card-content *ngIf="reportData['holdings']['public']['sum_total']['top_campuses'].length">
        <table>
          <thead>
            <tr>
              <th>Place</th>
              <th>Campus</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let campus of reportData['holdings']['public']['sum_total']['top_campuses']; let i=index">
              <td> {{ i + 1 }} </td>
              <td> {{ campus['campus__name'] }} </td>
              <td> {{ campus['the_count'] }} </td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for top campus holdings...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>


    <mat-card class="col-sm-6"  flex="100">

      <mat-card-title> Campuses With The Most New Holdings </mat-card-title>

      <mat-card-content *ngIf="!reportData['holdings']['public']['newly_added']['top_campuses'].length">
        There are no campuses in this system with holdings added during this time.
      </mat-card-content>

      <mat-card-content *ngIf="reportData['holdings']['public']['newly_added']['top_campuses'].length">
        <table>
          <thead>
            <tr>
              <th>Place</th>
              <th>Campus</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let campus of reportData['holdings']['public']['newly_added']['top_campuses']; let i=index">
              <td> {{ i + 1 }} </td>
              <td> {{ campus['campus__name'] }} </td>
              <td> {{ campus['the_count'] }} </td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for top campus holdings...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>

    <mat-card class="col-sm-6"  flex="100">

      <mat-card-title> Campuses With The Most Private Holdings </mat-card-title>

      <mat-card-content *ngIf="!reportData['holdings']['private']['sum_total']['top_campuses'].length">
        There are no campuses in the system with private holdings.
      </mat-card-content>

      <mat-card-content *ngIf="reportData['holdings']['private']['sum_total']['top_campuses'].length">

        <table>
          <thead>
            <tr>
              <th>Place</th>
              <th>Campus</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let campus of reportData['holdings']['private']['sum_total']['top_campuses']; let i=index">
              <td> {{ i + 1 }} </td>
              <td> {{ campus['campus__name'] }} </td>
              <td> {{ campus['the_count'] }} </td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for top private holdings...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>

    <mat-card class="col-sm-6"  flex="100">

      <mat-card-title> Most Requested File Formats </mat-card-title>

      <mat-card-content *ngIf="!reportData['requests']['outgoing']['newly_added']['top_formats'].length">
        There were no requests during this time.
      </mat-card-content>

      <mat-card-content *ngIf="reportData['requests']['outgoing']['newly_added']['top_formats'].length">

        <table>
          <thead>
            <tr>
              <th>Place</th>
              <th>Format</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let format of reportData['requests']['outgoing']['newly_added']['top_formats']; let i=index">
              <td> {{ i + 1 }} </td>
              <td> {{ format['holding__file_format__name'] }} </td>
              <td> {{ format['the_count'] }} </td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for top file formats...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>

    <mat-card class="col-sm-6"  flex="100">

      <mat-card-title> Most Added File Formats </mat-card-title>

      <mat-card-content *ngIf="!reportData['holdings']['public']['newly_added']['top_formats'].length">
        There were no book holdings added during this time.
      </mat-card-content>

      <mat-card-content *ngIf="reportData['holdings']['public']['newly_added']['top_formats'].length">

        <table>
          <thead>
            <tr>
              <th>Place</th>
              <th>Format</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let format of reportData['holdings']['public']['newly_added']['top_formats']; let i=index">
              <td> {{ i + 1 }} </td>
              <td> {{ format['file_format__name'] }} </td>
              <td> {{ format['the_count'] }} </td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for top file formats...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>

    <mat-card class="col-sm-6"  flex="100">

      <mat-card-title> Most Added Book Holdings </mat-card-title>

      <mat-card-content *ngIf="!reportData['holdings']['public']['newly_added']['top_books'].length">
        There were no book holdings added during this time.
      </mat-card-content>

      <mat-card-content *ngIf="reportData['holdings']['public']['newly_added']['top_books'].length">

        <table>
          <thead>
            <tr>
              <th>Place</th>
              <th>Book</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let book of reportData['holdings']['public']['newly_added']['top_books']; let i=index">
              <td> {{ i + 1 }} </td>
              <td> {{ book['book__title'] }} </td>
              <td> {{ book['the_count'] }} </td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>

      <mat-card-content *ngIf="reportData === undefined">
        Loading report data for top books...
        <mat-spinner></mat-spinner>
      </mat-card-content>

    </mat-card>
  </mat-tab>

  <mat-tab label="Campus List">

    <table>
      <thead>
        <tr>
          <th> Campus </th>
          <th> Last Login </th>
          <th> Registered Users </th>
          <th> New Holdings </th>
          <th> Public Holdings </th>
          <th> Private Holdings </th>
          <th> New Incoming Requests </th>
          <th> New Outgoing Requests </th>
          <th> All Incoming Requests </th>
          <th> All Outgoing Requests </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of reportData['campus_list']">
          <td>{{row['campus_name']}}</td>
          <td>{{row['last_login'] | date: 'short'}}</td>
          <td>{{row['registered_users']}}</td>
          <td>{{row['new_public_holdings']}}</td>
          <td>{{row['sum_public_holdings']}}</td>
          <td>{{row['sum_private_holdings']}}</td>
          <td>{{row['new_incoming_requests']}}</td>
          <td>{{row['new_outgoing_requests']}}</td>
          <td>{{row['sum_incoming_requests']}}</td>
          <td>{{row['sum_outgoing_requests']}}</td>
        </tr>
      </tbody>
    </table>

  </mat-tab>

</mat-tab-group>

</main>
