import { Component, OnInit } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';
import { User } from '../user';
import { UserService } from '../user.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent extends BaseListComponent<User> implements OnInit {
  viewTitle = 'Users - AIMHub';

  baseUrl = 'user/';
  userRole: string;

  // Action status
  public itemActivated = false;
  public itemDeactivated = false;

  constructor(
    protected dataService: UserService
  ) {
    super(dataService);
  }

  ngOnInit(): void {
    this.initUserRole();
    super.ngOnInit();
  }

  initUserRole() {
    this.dataService.getRole()
      .then(userRole => this.userRole = userRole)
      .catch(err => this.handleError(err));
  }

  /* Show success notification when user is activated.
   */
  onItemActivated(record: User): void {
    this.itemActivated = true;
    setTimeout(() => this.itemActivated = false, this.notifyTimeout);
    this.list();
  }

  /* Show success notification when user is deactivated.
   */
  onItemDeactivated(record: User): void {
    this.itemDeactivated = true;
    setTimeout(() => this.itemDeactivated = false, this.notifyTimeout);
    this.list();
  }
}
