import { FormGroup, FormBuilder } from '@angular/forms';
import {Injectable, Input} from '@angular/core';

import { AppInjector } from '../app-injector.service';
import { IServiceModel } from './service.interface';
import { isValidUUID } from '../shared/validator/invalid-uuid';


/**
 * Extend a set of form fields to manipulate a publisher object.
 * @example
 * <form [formGroup]="formComposite" (ngSubmit)="onSubmit()">
 *   <div formGroupName="publisher">
 *     <app-publisher-formgroup [group]="formComposite.controls.publisher">
 *     </app-publisher-formgroup>
 *   </div>
 *   <button type="submit">Submit</button>
 * </form>
 */

@Injectable()  // <-- Add this decorator
export class BaseFieldsComponent {

  @Input() formGroup: FormGroup;
  @Input() readOnlyForm = false;
  @Input() readOnlyList: string[] = [];

  protected fb: FormBuilder;

  constructor() {
    const injector = AppInjector.getInjector();
    this.fb = injector.get(FormBuilder);
  }

  /**
   * Returns true if the record is blank (no ID), for creation.
   */
  public isBlankRecord(): boolean {
    if (this.formGroup.controls.hasOwnProperty('id')) {
      const id = this.formGroup.value['id'];
      if (isValidUUID(id)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns true if the field name is in the readOnlyList list.
   * If the readOnlyList includes 'all_fields', will always return true.
   * @param {string} field - the formControlName of the field.
   */
  public isReadOnly(field: string): boolean {

    // If entire form is read only, then true.
    if (this.readOnlyForm) {
      return true;
    }

    // If 'all_fields' is set, then always read only.
    if (this.readOnlyList.indexOf('all_fields') !== -1) {
      return true;
    }

    // If any field is explicitly specified, set to read only.
    if (this.readOnlyList.indexOf(field) !== -1)
      return true;
    return false;
  }
}


// Generic signature of an `initXxxFormGroup` function.
export type TInitFormGroup = (record: IServiceModel) => FormGroup;
