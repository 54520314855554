import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../auth/auth.module';
import { SearchModule } from '../search/search.module';
import { ExchangeRequestModule } from '../exchange-request/exchange-request.module';

import { CoreRoutingModule } from './core-routing.module';
import { RedirectService } from './redirect.service';
import { MainNavbarComponent } from './main-navbar/main-navbar.component';
import { AuthNavbarComponent } from './auth-navbar/auth-navbar.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorNotFoundComponent } from './error-not-found/error-not-found.component';
import { NavBookComponent } from './nav-book/nav-book.component';
import { NavUserComponent } from './nav-user.component';
import { NavHomeComponent } from './nav-home.component';
import { NavAdminComponent } from './nav-admin/nav-admin.component';
import { NavHoldingComponent } from './nav-holding/nav-holding.component';
import { NavHelpComponent } from './nav-help/nav-help.component';
import { NavRequestInComponent } from './nav-request-in/nav-request-in.component';
import { NavRequestOutComponent } from './nav-request-out/nav-request-out.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AuthModule,
    SearchModule,
    ExchangeRequestModule,
    CoreRoutingModule,
  ],
  exports: [
    MainNavbarComponent,
    AuthNavbarComponent,
    FrontPageComponent,
    HomePageComponent,
    DashboardComponent,
    ErrorNotFoundComponent,
  ],
  declarations: [
    MainNavbarComponent,
    AuthNavbarComponent,
    FrontPageComponent,
    HomePageComponent,
    DashboardComponent,
    ErrorNotFoundComponent,
    NavBookComponent,
    NavUserComponent,
    NavHomeComponent,
    NavAdminComponent,
    NavHoldingComponent,
    NavHelpComponent,
    NavRequestInComponent,
    NavRequestOutComponent,
  ],
  providers: [
    RedirectService,
  ],
})
export class CoreModule { }
