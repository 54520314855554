import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseCrudService } from '../base/base-crud.service';
import { User } from './user';


export const PROFILE_PERSIST_KEY = 'userProfile';
export const ROLE_PERSIST_KEY = 'userRole';

@Injectable()
export class UserProfileService extends BaseCrudService<User> {

  endpointUrl = `${this.apiHost}/api/users/profile`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  read(): Promise<User> {
    return super.read('', this.endpointUrl);
  }

  update(record: User, apiUrl: string): Promise<User> {
    return super.update(record, this.endpointUrl);
  }

}
