import { Component } from '@angular/core';

@Component({
  selector: 'app-password-policy',
  template: `
    The password must meet the following criteria:
    <ol>
      <li>At least <strong>9 characters</strong>.</li>
      <li>At least one number.</li>
      <li>Not similar to your user information.</li>
      <li>Not in the 10,000 most common passwords.</li>
    </ol>
  `,
})
export class PasswordPolicyComponent {

  constructor() { }

}

