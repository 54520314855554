import {
  Component, Input, Output, OnChanges, SimpleChanges, EventEmitter
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-list-paginator',
  template: `
    <nav aria-label="Pagination control">
      <ul class="pagination">

        <li *ngIf="previousUrl && currentPage > 0">
          <a mat-raised-button
              [routerLink]="[apiUrl]"
              [queryParams]="previousQueryParams"
              (click)="onPagingEmitter.emit(currentPage - 1)"
              aria-label="Previous Page"
              >
            <mat-icon>navigate_before</mat-icon>
            Previous Page
          </a>
        </li>

        <li *ngIf="nextUrl">
          <a mat-raised-button
              [routerLink]="[apiUrl]"
              [queryParams]="nextQueryParams"
              (click)="onPagingEmitter.emit(currentPage + 1)"
              aria-label="Next Page"
              >
            Next Page
            <mat-icon>navigate_next</mat-icon>
          </a>
        </li>

      </ul>
    </nav>
             `,
})
export class ListPaginatorComponent implements OnChanges {
  @Input() apiUrl: string;
  @Input() queryParams = {};

  @Input() nextUrl: string;
  @Input() previousUrl: string;

  @Input() currentPage: number;

  @Output() onPagingEmitter = new EventEmitter<number>();

  previousQueryParams = {};
  nextQueryParams = {};

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.previousQueryParams = Object.assign(
      {}, this.queryParams, { page: this.currentPage - 1 }
    );
    this.nextQueryParams = Object.assign(
      {}, this.queryParams, { page: this.currentPage + 1 }
    );
  }
}
