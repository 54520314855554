import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-card-field',
  template: `
    <p>
      <label id="lbl{{ fieldId }}" for="val{{ fieldId }}">
        {{ labelText }}:
      </label>
      <span id="val{{ fieldId }}">
        <ng-content>
        </ng-content>
      </span>
    </p>
    `,
})
export class CardFieldComponent implements OnInit {
  @Input() labelText: string;
  @Input() fieldName: string;
  @Input() uniqueId: string;

  fieldId: string;

  constructor() { }

  ngOnInit() {
    this.fieldId = `${this.fieldName}-${this.uniqueId}`;
  }
}
