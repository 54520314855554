import { Component, Input } from '@angular/core';

import { HoldingBookSource } from './holding-book-source';


@Component({
  selector: 'app-holding-book-source-card',
  template: `
    <div id="holding-book-source-{{ record.id }}">

      <app-card-field [uniqueId]="record.id" [labelText]="'Name'" [fieldName]="'Name'">
        {{ record.name }}
      </app-card-field>

    </div>
    `,
})
export class HoldingBookSourceCardComponent {

  @Input() record: HoldingBookSource;

  constructor() { }
}
