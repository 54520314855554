<main>

<h1>Add Holdings</h1>

<div class="row">
  <div class="panel-body">
    <app-batch-isbn-form
      (onBatchSubmitClickedEmitter)="onBatchSubmitClicked($event)"
      (onSingleSubmitClickedEmitter)="onSingleSubmitClicked($event)"
      >
    </app-batch-isbn-form>
  </div>
</div>

<div class="row">
  <div id="add-holdings">
    <app-batch-isbn-list [bookList]="bookList">
    </app-batch-isbn-list>
  </div>
</div>

</main>
