<div class="panel">
  <div class="row">

    <div class="col-lg-6 col-md-6 col-sm-6">
      <h2>Add Request Status</h2>
      <app-exchange-request-status-form-create></app-exchange-request-status-form-create>
    </div>

  </div>
</div>
