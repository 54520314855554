<main>

<h1>My Requests to Other Campuses</h1>

<app-exchange-request-base-list
  [baseUrl]="baseUrl"
  [apiUrl]="apiUrl"
  [viewTitle]="viewTitle"
  [campus_table_heading]="'Owner'"
  [campus_table_field]="'holding_campus_str'">
</app-exchange-request-base-list>

</main>
