import { Component, OnInit } from '@angular/core';

import { BaseListComponent } from '../../base/base-list.component';
import { UserService } from '../../user/user.service';
import { FileFormat } from '../file-format';
import { FileFormatService } from '../file-format.service';


@Component({
  selector: 'app-file-format-list',
  templateUrl: './file-format-list.component.html',
  styleUrls: ['./file-format-list.component.css']
})
export class FileFormatListComponent extends BaseListComponent<FileFormat> implements OnInit {
  baseUrl = 'file-format/';
  viewTitle = 'File Formats - AIMHub';

  userRole: string;

  constructor(
    protected dataService: FileFormatService,
    protected userService: UserService
  ) {
    super(dataService);
  }

  ngOnInit(): void {
    this.initUserRole();
    super.ngOnInit();
  }

  initUserRole() {
    this.userService.getRole()
      .then(role => this.userRole = role)
      .catch(err => this.handleError(err));
  }
}
